import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import axios from 'axios';

import LoadSpinner from '../loadSpinner';

/* Images */
const imgList = [
        "/imgs/kiddz_site/mini/default0.jpg", "/imgs/kiddz_site/mini/default1.jpg",
        "/imgs/kiddz_site/mini/default2.jpg", "/imgs/kiddz_site/mini/default3.jpg", 
        "/imgs/kiddz_site/mini/default5.jpg", "/imgs/kiddz_site/mini/default10.jpg", 
        "/imgs/kiddz_site/mini/default8.jpg", "/imgs/kiddz_site/mini/default9.jpg",
];

class LeagueInfoTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, infoData:[], expanded: null
        }

        this.togglePanel = this.togglePanel.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.contentBuilder = this.contentBuilder.bind(this);
        this.getLeagueEvents = this.getLeagueEvents.bind(this);
    }
    
    togglePanel(id){
        this.setState({ expanded: (this.state.expanded === id ? null : id) });
    }
    
    formatDate(date, type){
        var ret = "";
        try {
            var d = new Date(date);
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            switch(type){
                case "day":
                    ret = days[d.getDay()] + " " + months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
                    break;
                case "time":
                    ret = (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0":"") + d.getMinutes() + (d.getHours() >= 12 ? "PM" : "AM");
                    break;
                default:
                    break;
            }
        }
        catch(ex){
            console.log("[Error] Formatting Date:",ex);
        }
        return ret;
    }

    contentBuilder(item, key){
        try {
            if(item){
                const rndInt = Math.floor(Math.random() * imgList.length);
                var tmpImg = imgList[rndInt];
                return(
                    <div className="item-container league" key={key}>
                        <div className="item-row">
                            <div className="img-icon"><img src="/imgs/logo/leeleekiddz_logo.jpg"/></div>
                            <div className="date-txt">{this.formatDate(item.startTime, "day")} {item.scheduleTimes}</div>
                            {item.registerUrlHtml ?
                                <a href={item.registerUrlHtml} target="_blank" className="link-btn c3">Register</a>
                                : <div className="item link-btn c1">Closed</div>
                            }
                        </div>
                        <div className="item-row"><div className="img-main sub"><img src={tmpImg} /></div></div>
                        <div className="item-row"><h1>{item.name}</h1></div>
                        <div className="item-row"><span className="location-txt">{item.location}</span></div>
                        <div className="item-row">
                            <Accordion className="desc-toggle" expanded={this.state.expanded === key} onChange={()=> this.togglePanel(key)}>
                                <AccordionSummary className="desc-btn" expandIcon={<i className="fas fa-chevron-down"/>} aria-controls={"panel" + key +"-content"} id={"panel" + key + "-header"}>
                                    <span className="desc-title">More Info</span>                              
                                </AccordionSummary>
                                <AccordionDetails className="desc-content">
                                    <p className="sub-txt" dangerouslySetInnerHTML={{__html: item.description}} />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>);
            }
        }
        catch(ex){
            console.log("[Error] Building content item: ",ex);
        }
    }
    
    getLeagueEvents(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                axios.post(self.props.rootPath + "/api/getLeagueEvents", { count: -1, type: "" }, {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(!response.data.error){
                        self.setState({ infoData: response.data.results });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });
        }
        catch(ex){
            console.log("[Error] Loading Draft List:",ex);
        }
    }

    componentDidMount(){ this.getLeagueEvents(); }

    render(){  
        return(
            <div className="tab-container league-info">
                <h1 className="tab-title">League Info</h1>
                <div className="league-info-container">
                    {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                    {this.state.infoData.map((info,key) => this.contentBuilder(info, key) )}
                    {!this.state.loading && this.state.infoData.length === 0 && <div className="lrgTitle ctr cw" data-text="Coming Soon">More League Events Coming Soon!</div>}
                </div>
            </div>
        );
    }
}
export default LeagueInfoTab;