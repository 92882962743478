import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

import LoadSpinner from '../loadSpinner';
import CustomTable from '../customTable';

/* Images */
const imgList = ["/imgs/site/default5.jpg", "/imgs/site/default7.jpg", "/imgs/site/default12.jpg", "/imgs/site/default8.jpg", "/imgs/site/default1.jpg", "/imgs/site/default15.jpg"];

class DraftEditorPreview extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, draftPreview: null,
            img1: imgList[0], img2: imgList[1]
        }

        this.playersCol = [
            { label: 'Photo', id: 'photo' }, { label: 'Name', id: 'name' }, 
            { label: 'Email', id: 'email' }, { label: 'Gender', id: 'gender' }, 
            { label: 'Positions', id: 'positions' }
        ];
        this.teamsCol = [
            { label: 'Draft Position', id: 'draftPos' }, { label: 'Team Color', id: 'color' }, 
            { label: 'Name', id: 'name' }, { label: 'Captains Name', id: 'captainName' }
        ];
        
        this.formatDate = this.formatDate.bind(this);
        this.setRandomPhoto = this.setRandomPhoto.bind(this);
        this.getDraftPreview = this.getDraftPreview.bind(this);
        this.generateLiveDraft = this.generateLiveDraft.bind(this);
    }

    formatDate(date, type){
        var ret = "";
        try {
            var d = new Date(date);
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            switch(type){
                case "day":
                    ret = days[d.getDay()] + " " + months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
                    break;
                case "time":
                    ret = (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0":"") + d.getMinutes() + (d.getHours() >= 12 ? "PM" : "AM");
                    break;
                default:
                    break;
            }
        }
        catch(ex){
            console.log("[Error] Formatting Date:",ex);
        }
        return ret;
    }

    setRandomPhoto(){
        try {
            const rndInt = Math.floor(Math.random() * imgList.length);
            const rndInt2 = Math.floor(Math.random() * imgList.length);

            this.setState({ img1: imgList[rndInt], img2: imgList[rndInt2] });
        }
        catch(ex){
            console.log("[Error] Setting Random Photo: ",ex);
        }
    }

    getDraftPreview(){
        var self = this;
        try {
            var draftId = this.props.selectedDraftId;
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                axios.get(self.props.rootPath + "/api/draft/preview/"+draftId, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){
                        console.log("[Error] Getting Draft Preview: ",response.data.error);
                    }
                    else {
                        self.setState({ draftPreview: response.data.results });
                    }
                })
                .catch(function (error) {
                    console.log("[Error] Getting Draft Preview (E2)",error);
                })
                .then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] Getting Draft Preview", ex);
        }
    }

    generateLiveDraft(){
        var self = this;
        try {
            if(confirm("Are you ready to generate this draft, You will not be able to make changes once generated?")) {            
                var draftId = this.props.selectedDraftId;
                this.setState({ loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    var postData = { draftId: draftId };
                    axios.post(self.props.rootPath + "/api/livedraft/build", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log("[Error] Generating Live Draft: ",response.data.error);
                        }
                        else {
                            var tmpPreview = self.state.draftPreview;
                            tmpPreview.liveId = response.data.results;
                            self.setState({ draftPreview: tmpPreview });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Generating Live Draft (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
                });  
            }
        }
        catch(ex){
            console.log("[Error] Generating Live Draft: ", ex);
        }
    }

    componentDidMount(){ 
        this.getDraftPreview();
        this.setRandomPhoto();
    }

    render(){  
        return(
            <div className="tab-content">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="editor-body">
                    <div className="title-btn-container">
                        <h1>Draft Preview</h1>

                        <div className="btn-container">
                            {this.state.draftPreview != null && this.state.draftPreview.liveId ? 
                            <Link className="link-btn cg2" to="/livedraft"><i className="fas fa-door-open" />Enter Live Draft Room</Link>
                            : <div className="link-btn cgadd" onClick={this.generateLiveDraft}><i className="fas fa-table" />Generate Draft</div>
                            }
                        </div>
                    </div>

                    {this.state.draftPreview != null &&
                        <div className="preview-body">
                            <div className="preview-section sz-4">
                                <div className="preview-content img">
                                    <div className="img-container"><img src={this.state.img1} /></div>
                                    <div className="img-text">
                                        <h2>{this.state.draftPreview.title}</h2>
                                        <div className="txt-block">
                                            <div className="txt-title">Date & Time</div>
                                            <div className="txt-content">
                                                <span>{this.formatDate(this.state.draftPreview.dateTime, "day")}</span>
                                                <span>{this.formatDate(this.state.draftPreview.dateTime, "time")}</span>
                                            </div>
                                        </div>

                                        <div className="txt-block">
                                            <div className="txt-title">Pick Time</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.pickTime} minutes</span>
                                            </div>
                                        </div>

                                        <div className="txt-block">
                                            <div className="txt-title">Draft Rounds</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.draftRounds}</span>
                                            </div>
                                        </div>

                                        <div className="txt-block">
                                            <div className="txt-title">Available Players</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.players.length}</span>
                                            </div>
                                        </div>

                                        <div className="txt-block">
                                            <div className="txt-title">Draft Type</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.draftType}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="preview-content img">
                                    <div className="img-container"><img src={this.state.img2} /></div>
                                    <div className="img-text">
                                        <h2><span>League Information</span> <i className="far fa-edit" onClick={()=> this.props.setTabSection("draftinfo")}/></h2>

                                        <div className="txt-block">
                                            <div className="txt-title">League Type</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.leagueType}</span>
                                            </div>
                                        </div>

                                        <div className="txt-block">
                                            <div className="txt-title">Teams</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.teams ? this.state.draftPreview.teams.length : ""}</span>
                                            </div>
                                        </div>

                                        <div className="txt-block">
                                            <div className="txt-title">Game Location</div>
                                            <div className="txt-content">
                                                <span>{this.state.draftPreview.location}</span>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>

                                <div className="preview-content table">
                                    <h2><span>Draft Order</span> <i className="far fa-edit" onClick={()=> this.props.setTabSection("draftteams")}/></h2>

                                    {this.state.draftPreview.draftPicks &&
                                        <div className="order-list">
                                            {this.state.draftPreview.draftPicks.map((pick,i) =>
                                                <div className="pick-container" key={i}>
                                                    {(i == 0 || this.state.draftPreview.draftPicks[i-1].round != pick.round) && <h3>Round {pick.round}</h3>}
                                                    <div className="pick-row">
                                                        <span>{pick.pick}</span>
                                                        <div className="pick-color" style={{ background: "rgba("+pick.color.r+","+pick.color.g+","+pick.color.b+",1)" }} />
                                                        <span>{pick.name}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="preview-section">
                                <div className="preview-content table">
                                    <h2><span>Teams</span> <i className="far fa-edit" onClick={()=> this.props.setTabSection("draftteams")}/></h2>
                                    {this.state.draftPreview.teams ?
                                        <CustomTable rows={this.state.draftPreview.teams} rowsPerPage={10} headCells={this.teamsCol} deleteBtn={false}/>
                                        : <div className="empty-preview">No Teams</div>
                                    }
                                </div>

                                <div className="preview-content table">
                                    <h2><span>Players In Draft</span> <i className="far fa-edit" onClick={()=> this.props.setTabSection("draftplayers")}/></h2>
                                    {this.state.draftPreview.players ?
                                        <CustomTable rows={this.state.draftPreview.players} rowsPerPage={8} headCells={this.playersCol} deleteBtn={false}/>
                                        : <div className="empty-preview">No Players</div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default DraftEditorPreview;