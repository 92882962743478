import axios from 'axios';
import { Link } from "react-router-dom";
import LoadSpinner from '../shared/loadSpinner';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Select from 'react-select';

import { colourStyles } from '../shared/utils';

/* Table */
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

/* Column Fields */
const headCells = [
    { id: 'playerName', label: 'Name', align:'left', class: 'sticky full'},{ id: "gameCount", label: "League Games", align: 'right', class: ''},
    { id: 'qTd', label: 'Passing TD', align: 'right', class: '' }, { id: "qInt", label: "Passing INT", align: 'right', class: ''},
    { id: 'recTd', label: 'Rec TD', align: 'right', class: '' }, { id: "rushTd", label: "Rushing TD", align: 'right', class: ''},
    { id: 'conv1', label: '1pt Conv', align: 'right', class: '' }, { id: "conv2", label: "2pt Conv", align: 'right', class: ''},
    { id: 'dInt', label: 'INT', align: 'right', class: '' }, { id: "dSack", label: "Sacks", align: 'right', class: ''},
    { id: 'dSafety', label: 'Safety', align: 'right', class: '' }, { id: 'dTd', label: 'Defensive TD', align: 'right', class: '' }
];

/* Table Function */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) { return -1; }
    if (b[orderBy] > a[orderBy]) { return 1; }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'asc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

/* Table Header */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
            <TableCell align="center" colSpan={2}/>
            <TableCell align="center" colSpan={6}>Offense</TableCell>
            <TableCell align="center" colSpan={4}>Defense</TableCell>
        </TableRow>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align={headCell.align} padding='normal' className={headCell.class} sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className="visually-hidden">
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}

/* Stats Main */
class Stats extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading1: true, loading2: true, loading3: false, statList:[], spotlightData:[],
            leagues:[], page: 0, order: 'asc', orderBy: null, rowsPerPage: 25,
            selLeague: null, topPlayers: null
        }

        this.handleTooltipToggle = this.handleTooltipToggle.bind(this);
        this.loadLeagueInfo = this.loadLeagueInfo.bind(this);
        this.loadLeagueTopPerformers = this.loadLeagueTopPerformers.bind(this);
        this.selectLeague = this.selectLeague.bind(this);
        this.changeLeague = this.changeLeague.bind(this);
        this.searchStats = this.searchStats.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.getCellView = this.getCellView.bind(this);
        this.contentBuilder = this.contentBuilder.bind(this);
    }

    handleTooltipToggle(idx, status){
        try {
            var tmpTopPlayers = this.state.topPlayers;
            if(idx < tmpTopPlayers.length){
                tmpTopPlayers[idx].tooltip = status;
                this.setState({ topPlayers: tmpTopPlayers });
            }
        }
        catch(ex){
            console.log(`Handling Tooltip Toggle: ${ex}`);
        }
    }

    loadLeagueInfo(){
        var self = this;
        try {
            this.setState({ loading1: true }, ()=> {
                axios.get(self.props.rootPath + "/api/stats/leagues", {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Stat League Data (E1): ", response.data.error);
                            self.setState({ loading2: false, loading3: false });
                        }
                        else {
                            var tmpLeagues = response.data.results.map(function(item){
                                var d = new Date(item.startTime),
                                itemLabel = `(${(d.getMonth()+1)}/${d.getFullYear()}) - ${item.name.slice(0, 30)}${item.name.length > 30 ? '...' : ''}`;
                                return { value: item._id, label: itemLabel };
                            });

                            self.setState({ leagues: tmpLeagues, loading2: (tmpLeagues.length <= 0 ? false : self.state.loading2) }, ()=>{
                                if(self.state.leagues.length > 0) {
                                    var leagueId = self.state.leagues[0].value;
                                    self.selectLeague(leagueId);
                                }
                            });
                        }
                    }).then(function(){
                        self.setState({ loading1: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] loading stat league list: ",ex);
        } 
    }

    loadLeagueTopPerformers(){
        var self = this;
        try {
            if(this.state.selLeague){
                this.setState({ loading2: true }, ()=> {
                    axios.get(`${self.props.rootPath}/api/stats/rating/league/${self.state.selLeague}`, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Getting League Top Performers (E1): ", response.data.error);
                            }
                            else { 
                                response.data.results = response.data.results.map(function(tp){
                                    return { ...tp, tooltip: false };
                                });

                                self.setState({ topPlayers: response.data.results });
                            }
                        }).then(function(){
                            self.setState({ loading2: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`[Error] Searching Stats: ${ex}`);
        }
    }
    
    selectLeague(leagueId){
        var self = this;
        try {
            if(leagueId != this.state.selLeague){
                this.setState({ selLeague: leagueId }, ()=> { 
                    self.loadLeagueTopPerformers(); self.searchStats();
                });
            }
        }
        catch(ex){
            console.log(`[Error] selectin league: ${ex}`);
        }
    }

    changeLeague(newValue, actionMeta){
        try {
            this.selectLeague(newValue.value);
        }
        catch(ex){
            console.log(`[Error] changing league: ${ex}`);
        }
    }

    searchStats(){
        var self = this;
        try {
            this.setState({ loading3: true }, ()=> {
                var postData = (self.state.selLeague === null ? {"criteria":{}} : {"criteria":{ leagueId: self.state.selLeague }});
                axios.post(self.props.rootPath + "/api/stats/search", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Searching Stats (E1): ", response.data.error);
                        }
                        else {                            
                            self.setState({ statList: response.data.results.stats });
                        }
                    }).then(function(){
                        self.setState({ loading3: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Searching Stats: ${ex}`);
        }
    }

    handleRequestSort(event, property){
        try {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({ order: (isAsc ? 'desc' : 'asc'), orderBy: property });
        }
        catch(ex){
            console.log("Handling Request Sort: ",ex);
        }
    }

    handleChangePage(event, newPage){
        try {
            this.setState({ page: newPage });
        }
        catch(ex){
            console.log(`[Error] Changing Page: ${ex}`);
        }
    }

    handleChangeRowsPerPage(event){
        try {
            this.setState({ page: 0,  rowsPerPage: event.target.value });
        }
        catch(ex){
            console.log(`[Error] Changing Rows Per Page: ${ex}`);
        }
    }

    getCellView(type, row){
        try {
            if(row){               
                switch(type){
                    case "playerName":
                        var tmpNm = (row.leeleeName ? row.leeleeName : row.playerName);
                        var nmList = tmpNm.split(" ");
                        var smTmpNm = (tmpNm.length < 2 ? tmpNm : `${nmList[0][0]}. ${nmList.slice(1).join(" ")}`);
                        
                        return(
                            <Link className="player-name" to={"/player-stats/" + (row.leeleeId || row.playerId)}>
                                <span className="lrg">{tmpNm}</span>
                                <span className="sm">{smTmpNm}</span>
                            </Link> 
                        );
                    default:
                        return(<span>{row[type]}</span>);
                }
            }
        }
        catch(ex){
            console.log("[Error] Getting Cell View: ",ex);
        }
    }

    contentBuilder(item, key){
        try {
            if(item){               
                switch(item.type){
                    case "player":
                        return(
                            <div className="item-container player" key={key}>
                                <div className="item-row split">
                                    <div className="img-main"><img src={item.image} /></div>
                                    <div className="split-content">
                                        <h1>{item.title}</h1>
                                        <h2 className="color-alt">{item.name}</h2>
                                    </div>
                                </div>
                                <div className="item-row"><p>{item.text}</p></div>
                            </div>);
                    case "league":
                        return(
                            <div className="item-container spotlight" key={key}>
                                <div className="item-row">
                                    <div className="img-main">
                                        <img src={item.image} />
                                    </div>
                                </div>
                                <div className="item-row text">
                                    {item.title && <h1>{item.title}</h1>}
                                    {item.name && <h2>{item.name}</h2>}
                                    {item.text && <p>{item.text}</p>}
                                </div>
                            </div>);
                    default:
                        return(<span key={key}/>);
                }
            }
        }
        catch(ex){
            console.log("[Error] Building content item: ",ex);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "League Stats";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/stats');
        this.loadLeagueInfo(); 
    }

    render(){  
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.statList.length - this.state.page * this.state.rowsPerPage);

        return(
            <div className="page-body stats">
                {(
                 (!this.state.loading1 && !this.state.loading2 && !this.state.loading3) && 
                 (!this.state.leagues || this.state.leagues.length === 0) &&
                 (!this.state.statList || this.state.statList.length === 0)
                ) &&
                    <div className="data-coming-soon">League Stats Coming Soon</div>
                }
                <section className="slim-landing min-landing">                   
                    <div className="slim-landing-text">
                        <span>Leagues</span>
                    </div> 
                    <div className="sub-text">
                        <span>Select a league to view the updated player stats and ratings for that league.</span>
                    </div>                     
                </section>
                <section className="slim-landing league-selection">
                    {/*this.state.leagues.map((league, i) =>
                        <div className={"league-pill" + (this.state.selLeague === league._id ? " sel" : "")} key={i} onClick={()=> this.selectLeague(league._id)} key={i}>{league.name}</div>
                    )*/}
                    {(!this.state.loading1 && this.state.leagues.length) &&
                        <Select className="select-pill" name="league-list" styles={colourStyles} onChange={this.changeLeague} options={this.state.leagues} defaultValue={this.state.leagues[0]} isClearable={false} isSearchable={true} />
                    }

                    {/* Loading Shimmer */}
                    {(this.state.loading1) &&
                        <>
                            <div className="empty-pill empty-animate"/>
                        </>
                    }

                    {/* No Data Item */}
                    {(!this.state.loading1 && (!this.state.leagues || this.state.leagues.length === 0)) &&
                        <div className="no-data">
                            <span className="fa-stack fa-2x icon">
                                <i className="fas fa-stream fa-stack-1x" />
                                <i className="fas fa-slash fa-stack-1x top-color" />
                            </span>
                            <span className="no-data-text">Sorry - No Avaliable Leagues With Stats</span>
                        </div>                        
                    }
                </section>

                <section className="slim-landing min-landing">                   
                    <div className="slim-landing-text">
                        <span>Top Players</span>
                    </div>  
                    <div className="sub-text">
                        <span>Statistic Based Ratings</span>
                    </div>                    
                </section>

                <section className="player-spotlight-container">
                    {/* Loading */}
                    {(this.state.loading2) &&
                        <>
                            <div className="empty-top-list empty-animate"/> <div className="empty-top-list empty-animate"/> <div className="empty-top-list empty-animate"/>
                        </>
                    }

                    {/* No Data Item */}
                    {(!this.state.loading2 && (!this.state.leagues || this.state.leagues.length === 0)) &&
                        <div className="no-data">
                            <span className="fa-stack fa-2x icon">
                                <i className="fas fa-running fa-stack-1x" />
                                <i className="fas fa-slash fa-stack-1x top-color" />
                            </span>
                            <span className="no-data-text">Top Stat Rated Players Not Avaliable</span>
                        </div>                        
                    }

                    {this.state.topPlayers && this.state.topPlayers.map((top,i) =>
                        <div className="top-container" key={i}>
                            {(top.list && top.list.length > 0) &&
                                <div className="top-list-container" key={i}>
                                    <div className="list-title">{top.title}</div>
                                    <div className="list-container">
                                        <div className="list-item sub-title">
                                            <div className="sub-field center">Name</div>
                                            <div className="sub-field">
                                                Stat Rating
                                                <ClickAwayListener onClickAway={()=> this.handleTooltipToggle(i, false)}>
                                                    <Tooltip 
                                                        title={<span className="tooltip-text">Player "Stat Ratings" are determined based on the players <b>individual stats</b> each week in comparison with the stats of the <b>rest of the league</b>.</span>} 
                                                        classes={{ tooltip: { maxWidth: 250 } }} placement="top" 
                                                        open={top.tooltip} onClose={()=> this.handleTooltipToggle(i, false)}
                                                        disableFocusListener disableHoverListener disableTouchListener arrow>
                                                        <i className="far fa-question-circle" onClick={()=> this.handleTooltipToggle(i, true)}/>
                                                    </Tooltip>
                                                </ClickAwayListener>
                                            </div>
                                        </div>

                                        {top.list.map((item,j)=>
                                            <div className="list-item" key={j}>
                                                <div className="list-num">{(j+1)}</div>
                                                {this.getCellView("playerName", item)}
                                                <div className="list-pts">{item[top.field]}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </section>

                <section className="slim-landing min-landing">                   
                    <div className="slim-landing-text">
                        <span>League Stats</span>
                    </div>                      
                </section>

                <section className="stats-table-container">
                    {this.state.loading3 && <LoadSpinner colorClass={"c2"}/> }

                    {/* No Data Item */}
                    {(!this.state.loading3 && (!this.state.statList || this.state.statList.length === 0)) &&
                        <div style={{ padding: "10px 3%" }}>
                            <div className="no-data">
                                <span className="fa-stack fa-2x icon">
                                    <i className="fas fa-table fa-stack-1x" />
                                    <i className="fas fa-slash fa-stack-1x top-color" />
                                </span>
                                <span className="no-data-text">No Avaliable League Statistics</span>
                            </div>
                        </div>                        
                    }

                    {(this.state.statList && this.state.statList.length > 0) &&
                        <Paper className="table-paper" sx={{width: '100%' }}>
                            <TableContainer sx={{ maxHeight: '450px' }}>
                                <Table stickyHeader aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                                    <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} />

                                    <TableBody>
                                        {stableSort(this.state.statList, getComparator(this.state.order, this.state.orderBy))
                                            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                            .map((row, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {headCells.map((cell,j) => 
                                                            <TableCell align={cell.align} className={cell.class} key={j}>
                                                                {this.getCellView(cell.id, row)}                                                     
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                );
                                        })}

                                        {(emptyRows > 0 && emptyRows < 6)&& (
                                            <TableRow style={{ height: 25 * emptyRows }}>
                                                <TableCell colSpan={(headCells ? headCells.length : 0)} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination rowsPerPageOptions={[25, 50]}
                                component="div" count={this.state.statList.length}
                                rowsPerPage={this.state.rowsPerPage} page={this.state.page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </Paper>
                    }
                </section>
            </div>
        );
    }
}
export default Stats;