import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

class PickBoard extends Component{
    constructor(props) {
        super(props);
        this.state = {}

        this.buildRoundObj = this.buildRoundObj.bind(this);
    }

    buildRoundObj(round, teamName){
        try {
            var tmpName = teamName.split(" ").join("").toLowerCase();
            var data = this.props.draftBoard[round][tmpName];

            if(data === null) {
                return(<div className="no-pick-data" />);
            }
            else if(data.type === "nopick" ){
                return(<div className="no-pick" />);
            }
            else if(data.type === "current" ){
                return(<div className="current-pick">+ Pick</div>);
            }
            else if(data.type === "skipped") {
                return(<div className="skipped-pick">Pick Skipped</div>);
            }
            else {
                return(
                    <div className={"player-pick pos-"+ data.basePosition}>
                        <h2>{data.displayFirst}</h2>
                        <h1>{data.displayLast}</h1>
                        <div className="basePos">{data.basePosition} <span>{data.gender}</span></div>
                    </div>
                );
            }
        }
        catch(ex){
            console.log("[Error] Building Round Obj: ",ex);
        }
    }

    componentDidMount(){ }
    
    render(){  
        return(
            <div className="pick-board-container">
                <Paper className="table-paper">
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Round</TableCell>
                                    {this.props.teamList.map((team,i) =>
                                        <TableCell key={i} align="center">{team.name}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="draft-row cap-row">
                                    <TableCell align="center"><span className="captain-row">Captain</span></TableCell>
                                    {this.props.teamList.map((team,k) =>
                                        <TableCell key={k} align="center"><span className="captain-row">{(team.captainInfo ? team.captainInfo.name : "N/A")}</span></TableCell>
                                    )}
                                </TableRow>
                                {this.props.draftBoard.map((round,j) =>
                                    <TableRow key={j} className="draft-row">
                                        <TableCell align="center"><span>{round.round}</span></TableCell>
                                        {this.props.teamList.map((team,k) =>
                                            <TableCell key={k}>{this.buildRoundObj(j, team.name)}</TableCell>
                                        )}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        );
    }
}
export default PickBoard;