import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';

import LoadSpinner from './loadSpinner';

class AvaliablePlayerSearch extends Component{
    constructor(props) {
        super(props);
        this.state = {
            minSearchLength: 0, draftPlayerModal: false,
            search:"", resultsMax:20, loading: false,
            searchResults:[], spinner: false, selectedPlayer: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.draftPlayer = this.draftPlayer.bind(this);
        this.setPlayer = this.setPlayer.bind(this);
    }

    handleChange(e){
        var self = this;
        try {
            var query = e.target.value;
            this.setState({[e.target.name]: query, spinner: true }, ()=> {
                if(self.state.search.length >= self.state.minSearchLength){                                        
                    var resultList = self.props.playerList.filter(function(player){
                        var isDrafted = ("draftedPos" in player && player.draftedPos >= 0);
                        return (!isDrafted) && player.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                    }).slice(0, self.state.resultsMax);

                    self.setState({ searchResults: resultList, spinner: false });
                }
            });
        }
        catch(ex){
            console.log("[Error] handling change: ",ex);
        }
    }

    draftPlayer(){
        var self = this;
        this.setState({ draftPlayerModal: false }, ()=>{
            self.props.draftPlayer(self.state.selectedPlayer);
        });
    }

    setPlayer(player){     
        this.setState({ search: "", selectedPlayer: player, draftPlayerModal: true });          
    }

    componentDidMount(){ }
    
    render(){  
        return(
            <div className="search-container">
                <i className="fas fa-search" />
                <input name="search" type="text" className="search-bar" autoComplete="off" value={this.state.search} onChange={this.handleChange} />

                {this.state.search.length > this.state.minSearchLength && 
                    <div className={"results-container" + (this.state.spinner ? " loading":"")}>
                        {this.state.searchResults.map((item,i) => 
                            <div className="result-item" key={i} onClick={()=> this.setPlayer(item) }>
                                <span>{item.name}</span>
                                <div className="result-info">
                                    <div className={"result-pos pos-" + item.basePosition}>{item.basePosition}</div>
                                    <div className="result-gender">{item.gender}</div>
                                </div>
                            </div>
                        )}
                        {this.state.spinner && <div className="loader-container"><div className="hm-spinner mini" /></div> }
                    </div>
                }

                <Modal open={this.state.draftPlayerModal} onClose={()=>this.setState({ draftPlayerModal: false })}>
                    <div className="modal-body c1">
                        {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                        {this.state.selectedPlayer != null &&
                            <div className="player-card">
                                <i className="close-modal fas fa-times" onClick={()=>this.setState({ draftPlayerModal: false })}/>
                                <div className="player-info">
                                    <div className="info-section">
                                        <h2>{this.state.selectedPlayer.displayFirst}</h2>
                                        <h1>{this.state.selectedPlayer.displayLast}</h1>
                                        <div className="pos-list">{this.state.selectedPlayer.positions}</div>

                                        {this.state.selectedPlayer.highlights && <a href={this.state.selectedPlayer.highlights} target="_blank" className="player-highlights"><i className="fas fa-football-ball"/> Player Highlights</a>}
                                    </div>
                                    <div className="icon-section">
                                        {this.state.selectedPlayer.photo ? 
                                            <img src={this.state.selectedPlayer.photo} /> :
                                            <i className="far fa-user" />
                                        }
                                    </div>
                                </div>

                                {this.props.draftAdmin &&
                                    <div className="player-draft">
                                        <div className="team-info">
                                            <div className="logo-container"><img src={this.props.curPick.logo} /></div>
                                            <div className="pick-info">
                                                <div className="team-name" style={{ color: "rgba("+this.props.curPick.color.r+","+this.props.curPick.color.g+","+this.props.curPick.color.b+",1)" }}>{this.props.curPick.name}</div>
                                                <div className="team-pick">
                                                    <span>RND {this.props.curPick.round}</span>
                                                    <span>PK {this.props.curPick.pick}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="draft-btn-container">
                                            <div className="link-btn cg3" onClick={this.draftPlayer}><i className="fas fa-user-plus" />Draft Player</div>
                                        </div>                                    
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}
export default AvaliablePlayerSearch;