import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';

/* Styles */
import "../css/app.less";

/* Components */
import Layout1 from './shared/_layout';
import Layout2 from './shared/_toolLayout';

import Home from './components/home';
import CatchAllPage from './components/404';
import Draft from './components/draft';
import LeagueInfo from './components/leagueInfo';
import LeagueSchedule from './components/leagueSchedule';
import LeagueStore from './components/leagueStore';
import LiveDraftContainer from './components/livedraft';
import Media from './components/media';
import PlayerSpotlight from './components/playerspotlight';
import PlayerStats from './components/playerStats';
import Rules from './components/rules';
import ResetAccount from './components/resetAccount';
import Stats from './components/stats';
import StatTrackerContainer from './components/statTracker';


/* Constants */
const history = createBrowserHistory(); 
const rootPath = ( window.location.href.indexOf("localhost") > -1  ? "http://localhost:3714" : "");

const routes = [
    { path:"/resetAccount", component: ResetAccount },
    { path:"/rules", component:Rules },
    { path:"/leagueinfo", component:LeagueInfo },
    { path:"/schedule", component:LeagueSchedule },
    { path:"/playerspotlight", component:PlayerSpotlight},
    { path:"/drafts", component:Draft },
    { path: "/media", component: Media },
    { path: "/stats", component: Stats },
    { path: "/player-stats/:playerId", component: PlayerStats }
];

const SiteRoutes = route => (
    <Route path={route.path} render={props => (<Layout1 rootPath={rootPath}><route.component {...props} rootPath={rootPath} /></Layout1>)} />
);

class App extends Component{
    constructor(props) { super(props); }   
    
    render(){   
        return(
            <Router history={history}>  
                <Switch>            
                    <Route exact path="/" render={props => (<Layout1 rootPath={rootPath}><Home {...props} rootPath={rootPath}/></Layout1>)} />
                    { routes.map((route, i) => <SiteRoutes key={i} {...route} />) }                    

                    {/* Live Draft */}               
                    <Route path="/livedraft" render={props => (<Layout2 rootPath={rootPath}><LiveDraftContainer {...props} rootPath={rootPath}/></Layout2>)} /> 

                    {/* Stat Tracker */}               
                    <Route path="/stattracker" render={props => (<Layout2 rootPath={rootPath}><StatTrackerContainer {...props} rootPath={rootPath}/></Layout2>)} /> 

                    {/* League Store */}
                    <Route path="/leaguestore" render={props => (<Layout2 rootPath={rootPath}><LeagueStore {...props} rootPath={rootPath}/></Layout2>)} /> 

                    {/* Default Route */}
                    <Route render={props => (<Layout1 rootPath={rootPath}><CatchAllPage {...props} /></Layout1>)} />                     
                </Switch>
            </Router>
        );
    }
}

export default App;