import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
import marked from 'marked';
import axios from 'axios';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';

import LoadSpinner from '../shared/loadSpinner';

class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            scrollDuration: 7000, loading: false,
            carouselData:[], photoList:[],
            responsivePhoto: { 0: { items: 1 }, 600: { items: 2 }, 1024: { items: 2 }},
            responsiveSponsor: { 0: { items: 1 }, 600: { items: 2 }, 1024: { items: 4 }},
            leagues:[], playerSpotlight:[], sponsorList:[]
        }  

        this.titlePage = { type: "title", img: "/imgs/logo/leeleekiddz_logo.jpg", title:"Lee Lee", subtitle:"Kiddz Sports", text:""};
        
        this.markText = this.markText.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.buildSpotlightCarousel = this.buildSpotlightCarousel.bind(this);
        this.buildCarousel = this.buildCarousel.bind(this);
        this.loadPageData = this.loadPageData.bind(this);

        this.loadCarousel = this.loadCarousel.bind(this);                
        this.buildPhotoList = this.buildPhotoList.bind(this);
        this.buildSponsorList = this.buildSponsorList.bind(this);
        this.loadLeagueInfo = this.loadLeagueInfo.bind(this);
    }

    markText(text) {
        var rawMarkup = "";
        try {
            if(text) {
                rawMarkup = marked(text);
            }        
        }
        catch(ex){
            console.log("[Error] marking body Text: ",ex);
        }
        return { __html:  rawMarkup};
    }

    formatDate(date){
        var ret = "";
        try {
            var d = new Date(date);
            ret = (d.getMonth()+1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
        catch(ex){
            console.log("[Error] formatting date: ",ex);
        }

        return ret;
    }

    buildSpotlightCarousel() {
        try {
            if(this.state.playerSpotlight.length > 0) {
                return(                    
                    this.state.playerSpotlight.map((spotlight,i) => (
                        <div className="spotlight-page" key={i}>
                            <h1 className="back-title lrgTitle ctr c1" data-text={spotlight.header} />
                            <div className="spotlight-content">
                                <div className="content-text">
                                    <h2>{spotlight.name}</h2>
                                    <h1>{spotlight.title}</h1>
                                    <p>{spotlight.text}</p>
                                </div>
                                <div className="content-img"><img src={spotlight.image} /></div>
                            </div>
                        </div>
                    ))                    
                )
            }
            else {
                return(<div className="spotlight-page empty"/>)
            }
        }
        catch(ex){
            console.log("[Error] Building Spotlight Carousel Item: ",ex);
        }
    }

    buildCarousel(){
        try {
            if(this.state.carouselData.length > 0) {
                return(                    
                    this.state.carouselData.map((item,i) => (
                        <div className={"carousel-page " + item.type} key={i}>
                            {item.type === "title" &&
                                <div className="carousel-text-container">
                                    <div className="txt-carousel-title">{item.title}</div>
                                    <div className="txt-subtitle">{item.subtitle}</div>
                                </div>
                            }

                            {(item.type === "text-left" || item.type === "text-right")  &&
                                <div className={"carousel-text-container " + item.title}>
                                    <div className="frame-title">{item.title}</div>
                                    <div className="frame-subtitle">{item.subtitle}</div>
                                    <p dangerouslySetInnerHTML={this.markText(item.text)}/>
                                    <div className="btn-container">
                                        <a href={item.link} className="link-btn c3">{item.linktitle}</a>
                                    </div>
                                </div>
                            }

                            <div className="carousel-img-container">
                                <img className="carousel-img" src={item.img} />
                            </div>
                        </div>
                    ))                    
                )
            }
            else {
                return(<div className="carousel-page empty"/>)
            }
        }
        catch(ex){
            console.log("[Error] Building Carousel Item: ",ex);
        }
    }

    loadPageData(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { url:"stories/home" };
                axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Home Page Data (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.setState({ 
                                carouselData: self.loadCarousel(response.data.results.homeslider),
                                photoList: response.data.results.photoList,
                                playerSpotlight: response.data.results.playerspotlight,
                                sponsorList: response.data.results.sponsorList
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Home Page Data (E2)",error);
                        self.setState({ carouselData: self.loadCarousel([]) });
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("Error Loading Page Data: ", ex);
        }
    }

    loadCarousel(carouselData){
        var tmpCarousel = [];
        try {
            // Set Default Image
            const imgList = ["/imgs/kiddz_site/default0.jpg", "/imgs/kiddz_site/default1.jpg", "/imgs/kiddz_site/default2.jpg", "/imgs/kiddz_site/default3.jpg"];
            const rndInt = Math.floor(Math.random() * imgList.length);

            this.titlePage.img = imgList[rndInt];
            tmpCarousel.push(this.titlePage);

            // Load Carousel Data
            if(carouselData && carouselData.length > 0){
                tmpCarousel = tmpCarousel.concat(carouselData);
            }
        }
        catch(ex){
            console.log("[Error] Loading Carousel: ",ex);
        }

        return tmpCarousel;
    }


    buildPhotoList(){
        try {
            if(this.state.photoList.length > 0) {
                return(                    
                    this.state.photoList.map((image,i) => (
                        <div className="photo-item" key={i}>
                            <img src={image} alt={"LeeLee Tournament Photo "+i}/> 
                        </div>
                    ))                    
                )
            }
            else {
                return(<div className="no-photo"/>)
            }
        }
        catch(ex){
            console.log("[Error] building photo list: ",ex);
        }  
    }

    buildSponsorList(){
        try {
            if(this.state.sponsorList.length > 0) {
                return(                    
                    this.state.sponsorList.map((sponsor,i) => (
                        <div className="sponsor-item" key={i}>
                            <div className="sponsor-logo"><img src={sponsor.logo} /></div>
                            <div className="sponsor-content name">{sponsor.name}</div>
                            <div className="sponsor-content description">{sponsor.description}</div>
                            <div className="sponsor-content contact">{sponsor.contact}</div>
                        </div>
                    ))                    
                )
            }
            else {
                return(<div className="no-sponsors"/>)
            }
        }
        catch(ex){
            console.log("[Error] building photo list: ",ex);
        }  
    }

    loadLeagueInfo(){
        var self = this;
        try {
            var postData = { count: 3, type: "" };
            axios.post(self.props.rootPath + "/api/getLeagueEvents", postData, {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Getting League Event Data (E1): ", response.data.error);
                    }
                    else {
                        self.setState({ leagues: response.data.results });
                    }
                }); 
        }
        catch(ex){
            console.log("[Error] loading league info list: ",ex);
        } 
    }

    componentDidMount(){
        window.scrollTo(0, 0); 
        document.title = "Lee Lee Flag Football League";
        this.loadPageData(); this.loadLeagueInfo();

        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/home');
    }

    render(){  
        return(
            <div className="page-body home">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="landing">
                    {this.state.carouselData.length > 0 && <Carousel className="site-carousel main-carousel" showThumbs={false} showStatus={false} showIndicators={false} interval={this.state.scrollDuration} infiniteLoop autoPlay>
                        {this.buildCarousel()}
                    </Carousel>}
                </div>

                <section className="body-section photos">
                    <div className="photo-title">
                        <h1>League Photos</h1>
                        <p>View some of the images from our league tournaments.</p>

                        <div className="scroll-ctrl">
                            <div className="ctrl-item left" onClick={() => this.photoCarousel.slidePrev()}><i className="fas fa-chevron-left"/></div>
                            <div className="ctrl-item right" onClick={() => this.photoCarousel.slideNext()}><i className="fas fa-chevron-right"/></div>
                        </div>
                    </div>
                    <div className="photo-container">
                        {this.state.photoList && this.state.photoList.length > 0 &&
                            <AliceCarousel className="photo-scroller" items={this.buildPhotoList()}
                                autoPlayInterval={7000} disableDotsControls disableButtonsControls mouseTracking infinite
                                responsive={this.state.responsivePhoto} ref={(el) => this.photoCarousel = el }/>     
                        }  
                    </div>
                </section>

                <section className="body-section leagues light">
                    <h1 className="lrgTitle ctr c0" data-text="Leagues">Upcoming Leagues</h1>
                    <div className="league-container">
                        {this.state.leagues.length === 0 ?
                            <div className="no-leagues">More Leagues Coming Soon</div> :
                            <div className="league-list">
                                {this.state.leagues.map((league,i) =>
                                    <div className="league-item" key={i}>
                                        <div className="item-container">
                                            <div className="item img-icon"><img src="/imgs/logo/leeleekiddz_logo.jpg"/></div>
                                            <div className="item item-name">{league.name}</div>
                                            <div className="item item-date">{this.formatDate(league.startTime)}</div>
                                            {league.registerUrlHtml ? 
                                                <a href={league.registerUrlHtml} target="_blank" className="item link-btn c5">Sign-up</a>
                                                : <div className="item link-btn c1 disabled">Closed</div>
                                            }
                                        </div>
                                    </div>
                                )}
                                <Link to="/leagueinfo" className="all-container">View More</Link>
                            </div>
                        }
                    </div>
                </section>

                <section className="body-section playerSplotlight">
                    <div className="spotlight-background">
                        <img className="spotlight-img" src="/imgs/site/football_back.jpg" />
                    </div>
                    {this.state.playerSpotlight.length > 0 &&                        
                        <Carousel className="spotlight-carousel" showThumbs={false} showStatus={false} showIndicators={false} interval={this.state.scrollDuration} infiniteLoop>
                            {this.buildSpotlightCarousel()}
                        </Carousel>                        
                    }

                    {(!this.state.playerSpotlight || this.state.playerSpotlight.length === 0) &&
                        <div className='empty-carousel'>...More Spotlights To Come</div>
                    }
                </section>

                <section className="body-section sponsors light">
                    <h1 className="lrgTitle ctr c0" data-text="Sponsors">Sponsors</h1>
                        <div className="sponsor-container">  
                            {this.state.sponsorList && this.state.sponsorList.length > 0 &&                          
                                <AliceCarousel className="sponsor-scroller" items={this.buildSponsorList()}
                                    autoPlayInterval={7000} disableDotsControls disableButtonsControls mouseTracking
                                    responsive={this.state.responsiveSponsor} ref={(el) => this.sponsorCarousel = el }/>     
                            }

                            {this.state.sponsorList && this.state.sponsorList.length > 4 &&
                                <div className="scroll-ctrl">
                                    <div className="ctrl-item left" onClick={() => this.sponsorCarousel.slidePrev()}><i className="fas fa-chevron-left"/></div>
                                    <div className="ctrl-item right" onClick={() => this.sponsorCarousel.slideNext()}><i className="fas fa-chevron-right"/></div>
                                </div>  
                            }                        
                        </div>                    
                </section>
            </div>
        );
    }
}
export default Home;