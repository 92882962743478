import React, { Component } from 'react';
import { InputLabel, FormControl, Select } from '@material-ui/core';
import axios from 'axios';
import Ticker from 'react-ticker';
import Sound from 'react-sound';

import LoadSpinner from '../../shared/loadSpinner';
import AvaliablePlayerSearch from '../../shared/avaliablePlayerSearch';
import SocketConnect from '../../shared/socketConnect';
import PickBoard from './pickBoard';
import PlayerBoard from './playerBoard';

/* Images */
import logo from '../../../assets/imgs/logo0.png';

/* Draft Sounds */
import pick_sound from '../../../assets/sounds/draft_pick.mp3';
import crowd_sound from '../../../assets/sounds/crowd_cheer.mp3';
import countdown_sound from '../../../assets/sounds/count_down_tick.mp3';
import welcome_sound from '../../../assets/sounds/welcome.mp3';

const soundChart = {
    "pick":{ url: pick_sound, playFromPosition: 0 },
    "crowd":{ url: crowd_sound, playFromPosition: 0 },
    "countdown":{ url: countdown_sound, playFromPosition: 0 },
    "welcome":{ url: welcome_sound, playFromPosition: 0 },
}

/* Live Data */
var timeInt = null, localSock = null;

class DraftBoard extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading:false, draftData: null, soundOn: true,
            draftTime: 0, draftCountdown:true, nextUpTeams:[], boardType: "draft",
            genderFilter: "all", positionFilter: "all", 
            currentSong:pick_sound, playFromPosition:0, 
            playStatus: Sound.status.STOPPED, 
            draftAdmin: false, adminCheck: false
        }

        this.checkIsAdmin = this.checkIsAdmin.bind(this);
        this.toggleSound = this.toggleSound.bind(this);
        this.playSound = this.playSound.bind(this);
        this.exitDraft = this.exitDraft.bind(this);
        this.draftPlayer = this.draftPlayer.bind(this);
        this.skipPick = this.skipPick.bind(this);
        this.toggleDraftStatus = this.toggleDraftStatus.bind(this);
        this.getDraftData = this.getDraftData.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.getPickData = this.getPickData.bind(this);
        this.setDraftBoard = this.setDraftBoard.bind(this);
        this.buildTicker = this.buildTicker.bind(this);
        this.socketDeclaration = this.socketDeclaration.bind(this);
    }

    checkIsAdmin(){
        try {
            var sessionInfo = localStorage.getItem(this.props.mySessKey);

            if(sessionInfo){
                var { localUser, isExpired } = parseToken(sessionInfo);

                this.setState({ draftAdmin: !isExpired && localUser.admin, adminCheck: true });
            }
            else {
                this.setState({ draftAdmin: false, adminCheck: true });
            }
        }
        catch(ex){
            console.log("[Error] Checking If Admin: ",ex);
        }
    }

    toggleSound(){
        try {
            this.setState({ soundOn: !this.state.soundOn, playStatus: Sound.status.STOPPED });
        }
        catch(ex){
            console.log("[Error] Toggling Sound: ",ex);
        }
    }
    playSound(type){
        try {
            var sound = null, position = 0;
            
            if(type in soundChart){
                sound = soundChart[type].url;
                position = soundChart[type].playFromPosition;
            }

            if(sound != null && this.state.soundOn){
                this.setState({ currentSong: sound, playFromPosition: position, playStatus: Sound.status.PLAYING });
            }
        }
        catch(ex){
            console.log("[Error] Playing Sound: ",ex);
        }
    }

    exitDraft(){
        var self = this;
        try {
            if(confirm("Are you sure you want to exit the draft?")){
                if(self.state.draftData.draftStatus != "active"){
                    localStorage.removeItem(this.props.draftSessKey);
                    this.props.selectDraft(null);
                }
                else {
                    this.setState({ loading: true }, ()=> {
                        var token = localStorage.getItem(self.props.mySessKey);
                        if(!token){
                            self.setState({ loading: false });
                        }
                        else {
                            var postData = {draftId: self.props.selectedDraftId, draftStatus: "paused" };
                            axios.post(self.props.rootPath + "/api/livedraft/updateStatus", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                            .then(function(response) {
                                if(response.data.error){
                                    console.log("[Error] Toggling Draft Status: ",response.data.error);
                                }
                                else {
                                    localStorage.removeItem(self.props.draftSessKey);
                                    self.props.selectDraft(null);
                                }
                            }).then(function(){
                                self.setState({ loading: false });
                            }); 
                        }
                    });
                }
            }
        }
        catch(ex){
            console.log("[Error] Exiting Draft: ",ex);
        }
    }

    draftPlayer(player){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                if(!token){
                    self.setState({ loading: false });
                }
                else {
                    var postData = { draftId: self.props.selectedDraftId, pickPos: self.state.draftData.curPick, player: player };
                    axios.post(self.props.rootPath + "/api/livedraft/turnInPick", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error && !response.data.results){
                            console.log("[Error] Turning In Draft Pick: ", response.data.error);
                            if(response.data.userError) { alert(response.data.error) };
                        }
                        else {
                            self.playSound("pick");
                            self.getDraftData();
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
                }
            });
        }
        catch(ex){
            console.log("[Error] Turning In Draft Pick: ",ex);
        }
    }

    skipPick(){
        var self = this;
        try {

            if(confirm("Are you sure you want to skip this pick?")){
                this.setState({ loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    if(!token){
                        self.setState({ loading: false });
                    }
                    else {
                        var postData = { draftId: self.props.selectedDraftId, pickPos: self.state.draftData.curPick};
                        axios.post(self.props.rootPath + "/api/livedraft/skipPick", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error && !response.data.results){
                                console.log("[Error] Skipping Draft Pick: ", response.data.error);
                                if(response.data.userError) { alert(response.data.error) };
                            }
                            else {
                                self.playSound("pick");
                                self.getDraftData();
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                    }
                });
            }
        }
        catch(ex){
            console.log("[Error] Skipping Draft Pick: ",ex);
        }
    }

    toggleDraftStatus(){
        var self = this;
        try { 
            if(self.state.draftData.draftStatus != "completed"){
                this.setState({ loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    if(!token){
                        self.setState({ loading: false });
                    }
                    else {
                        var postData = {draftId: self.props.selectedDraftId, draftStatus: (self.state.draftData.draftStatus === "active" ? "paused" : "active")};
                        axios.post(self.props.rootPath + "/api/livedraft/updateStatus", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){
                                console.log("[Error] Toggling Draft Status: ",response.data.error);
                            }
                            else {
                                if(postData.draftStatus === "active") { self.playSound("crowd"); }
                                self.getDraftData();
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                    }
                });
            }
        }
        catch(ex){
            console.log("[Error] Toggling Draft Status: ",ex);
        }
    }

    getDraftData(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {                
                axios.get(self.props.rootPath + "/api/livedraft/" + self.props.selectedDraftId, {headers:{'Content-Type': 'application/json'}})
                .then(function(response) {
                    if(response.data.error){
                        console.log("[Error] Getting Live Draft Data: ",response.data.error);
                    }
                    else {
                        self.setDraftBoard(response.data.results);
                    }
                }).then(function(){
                    self.setState({ loading: false });
                });                                
            });
        }
        catch(ex){
            console.log("[Error] Getting Live Draft Data: ",ex);
        }
    }

    handleSelectChange(e){
        try {
            const name = e.target.name;
            this.setState({ [name]: e.target.value });
        }
        catch(ex){
            console.log("[Error] with handle select change: ",ex);
        }
    }

    getPickData(type){
        var ret = "X";
        try {
            if(this.state.draftData.curPick < this.state.draftData.draftPicks.length){
                if(type === "all"){
                    ret = this.state.draftData.draftPicks[this.state.draftData.curPick];
                }
                else if(type in this.state.draftData.draftPicks[this.state.draftData.curPick]){
                    ret = this.state.draftData.draftPicks[this.state.draftData.curPick][type];

                    ret = (type === "logo" && ret === null ? logo : ret);
                    ret = (type === "color" && ret === null ? {r: 238, g: 36, b: 110} : ret);
                }
            }            
        }
        catch(ex){
            console.log("[Error] Getting Pick Data: ",ex);
        }
        return ret;
    }

    setDraftBoard(draftData){
        var self = this;
        try {
            // Draft Timer
            var draftTime = 0;
            if(draftData.draftStatus === "paused"){
                draftTime = draftData.pausedTime;
            }
            else {
                var now = new Date(), utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
                var pick_now = new Date(draftData.pickEndTime), utc_pickTime = new Date(pick_now.getUTCFullYear(), pick_now.getUTCMonth(), pick_now.getUTCDate(),  pick_now.getUTCHours(), pick_now.getUTCMinutes(), pick_now.getUTCSeconds(), pick_now.getUTCMilliseconds());

                draftTime = utc_pickTime.getTime() - utc_now.getTime();
            }

            // Stop Timer
            clearInterval(timeInt);

            // Next Up Teams
            var tmpNext = draftData.draftPicks.slice(draftData.curPick+1, (draftData.curPick + 15)).map(function(item){ return item.name; });
            
            this.setState({ draftData: draftData, draftTime: draftTime, nextUpTeams: tmpNext, draftCountdown: true }, ()=> {
                // Set Time Interval
                if(self.state.draftData.draftStatus === "active" && self.state.draftCountdown){
                    timeInt = setInterval(function(){ 
                        var cdt = countdownTime(self.state.draftTime);
                        if(cdt > 0) { 
                            if(cdt < 12000 && cdt >= 11000) { self.playSound("countdown");}
                            self.setState({ draftTime: self.state.draftTime - 1000 });
                        }
                        else { 
                            self.setState({ draftTime: 0, draftCountdown: false }, ()=> { clearInterval(timeInt); });
                        }
                    }, 1000);
                }
                else if(self.state.draftData.draftStatus === "completed"){
                    if(confirm("Draft has completed do you want to exit the draft room?")){
                        localStorage.removeItem(self.props.draftSessKey);
                        self.props.selectDraft(null);                
                    }
                }
            });
        }
        catch(ex){
            console.log("[Error] Setting Draft Board: ",ex);
        }
    }

    buildTicker(idx){
        try {
            if(this.state.draftData.draftPicks.length >= 0){
                var pick = this.state.draftData.draftPicks[(idx % this.state.draftData.draftPicks.length)];

                return(
                    <div className="pick-tick">
                        <span>{pick.round}.{pick.pick}</span>
                        <span>{pick.name}</span>
                        {(pick.drafted) && <span>|| {pick.drafted.name}</span>}
                        {(pick.skipped && !pick.drafted) && <span>|| Pick Was Skipped</span>}
                    </div>
                )
            }
            else {
                <div className="pick-tick"></div>
            }
        }
        catch(ex){
            console.log("[Error] Building Ticker: ",ex);
        }
    }

    socketDeclaration(tmpSock){
        var self = this;
        try {
            if(tmpSock) {
                tmpSock.on('draft update',function(res) { 
                    console.log("Draft Update"); 
                    self.getDraftData();
                });

                // Connect To Draft Room
                if(self.props.selectedDraftId){
                    tmpSock.emit('findDraftRoom', { id: self.props.selectedDraftId });
                }

                localSock = tmpSock;
            }
        }
        catch(ex){
            console.log("[Error] with socket declaration: ", ex);
        }
    }

    componentDidMount(){         
        window.scrollTo(0, 0);
        this.checkIsAdmin();
        this.playSound("welcome"); 
        this.getDraftData();
    }

    componentWillUnmount() {
        if(localSock){ localSock.close(); }
    }

    render(){  
        return(
            <div className="draft-board">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                {this.state.draftData != null &&
                    <div className="draft-board-container">
                        {/* Draft Socket Connection */}
                        {(!this.state.draftAdmin && this.state.adminCheck) && <SocketConnect socketDeclaration={this.socketDeclaration}/> }

                        {/* Draft Board Header */}
                        <div className="board-header">
                            <div className="header-info">
                                <div className="info-section time-split">
                                    {this.state.draftData.draftStatus === "completed" &&
                                        <div className="time"><span>--</span><span>:</span><span>--</span></div>
                                    }
                                    {this.state.draftData.draftStatus != "completed" &&
                                        <div className="time">
                                            <span>{formatTime(this.state.draftTime,"m")}</span>
                                            <span>:</span>
                                            <span>{formatTime(this.state.draftTime,"s")}</span>
                                        </div>
                                    }
                                    <div className="status-btn-container">
                                        {this.state.draftData.draftStatus != "completed" &&
                                            <div className={"status-btn" + (this.state.draftData.draftStatus === "active" ? "":" paused") + (this.state.draftAdmin ? "" : " disable")} onClick={this.toggleDraftStatus}>
                                                <i className={"fas " + (this.state.draftData.draftStatus === "active" ?"fa-play":"fa-pause")}></i>
                                                <span>{this.state.draftData.draftStatus}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="info-section pick-info">
                                    <div className="draft-pick-info">
                                        <div className="pick">{this.getPickData("round")}</div>
                                        <span>Round</span>
                                    </div>
                                    <div className="draft-pick-info">
                                        <div className="pick">{this.getPickData("pick")}</div>
                                        <span>Pick</span>
                                    </div>
                                </div>
                                <div className="info-section next-picks">
                                    <div className="team-pick-info">
                                        <div className="logo-img"><img src={this.getPickData("logo")}/></div>
                                        <span>{this.getPickData("captainName")}</span>
                                    </div>
                                    <div className="team-pick-info full">
                                        <div className="team-name">
                                            <span style={{ color: "rgba("+this.getPickData("color").r+","+this.getPickData("color").g+","+this.getPickData("color").b+",1)" }}>{this.getPickData("name")}</span>
                                            {this.state.draftAdmin && 
                                                <div className="skip-btn-container">
                                                    <div className="skip-btn" onClick={this.skipPick}>
                                                        <i className="fas fa-forward" />
                                                        <span>Skip Pick</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="next-up-teams">
                                            <span>Next Up:</span>
                                            {this.state.nextUpTeams.map((team, j) => <span key={j}>{team}</span>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-ctrl">
                                <div className="ctrl-section">
                                    <FormControl variant="outlined" className="formControl">
                                        <InputLabel htmlFor="outlined-age-native-simple">Board Type</InputLabel>
                                        <Select native name="boardType" value={this.state.boardType} onChange={this.handleSelectChange} label="Board Type">
                                            <option value="draft">Draft Board</option>
                                            <option value="players">Players</option>
                                        </Select>
                                    </FormControl>

                                    {/* Filter Drop Downs */}
                                    {this.state.boardType === "players" &&
                                        <FormControl variant="outlined" className="formControl">
                                            <InputLabel htmlFor="outlined-age-native-simple">Positions</InputLabel>
                                            <Select native name="positionFilter" value={this.state.positionFilter} onChange={this.handleSelectChange} label="Positions">
                                                <option value="all">All Positions</option>
                                                <option value="QB">QB</option>
                                                <option value="WR">WR</option>
                                                <option value="CB">CB</option>
                                                <option value="LB">LB</option>
                                                <option value="FS">FS</option>
                                                <option value="RS">RS</option>
                                            </Select>
                                        </FormControl>
                                    }

                                    {this.state.boardType === "players" &&
                                        <FormControl variant="outlined" className="formControl">
                                            <InputLabel htmlFor="outlined-age-native-simple">Genders</InputLabel>
                                            <Select native name="genderFilter" value={this.state.genderFilter} onChange={this.handleSelectChange} label="Genders">
                                                <option value="all" />
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <div className="ctrl-section">
                                    <AvaliablePlayerSearch playerList={this.state.draftData.players} curPick={this.getPickData("all")} draftPlayer={this.draftPlayer} draftAdmin={this.state.draftAdmin}/>
                                </div>
                                <div className="ctrl-section">
                                    <div className="sound-status-btn" onClick={this.toggleSound}>
                                        <i className={"fas " + (this.state.soundOn ? "fa-volume-up" : "fa-volume-mute")} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Draft Board Body */}
                        <div className="board-body">
                            {this.state.boardType === "draft" && <PickBoard draftBoard={this.state.draftData.draftBoard} teamList={this.state.draftData.teams} /> }
                            {this.state.boardType === "players" && <PlayerBoard playerList={this.state.draftData.players} curPick={this.getPickData("all")} draftPlayer={this.draftPlayer} draftAdmin={this.state.draftAdmin} positionFilter={this.state.positionFilter} genderFilter={this.state.genderFilter}/> }
                        </div>

                        {/* Draft Board Footer */}
                        <div className="board-footer">
                            <div className="logo-container" onClick={this.exitDraft}><img src={logo} /> <span>LEE LEE</span></div>
                            {this.state.draftData.draftPicks.length > 0 &&
                                <div className="ticker-container">
                                    <Ticker>
                                        {({index}) => ( <div>{ this.buildTicker(index) }</div> )}
                                    </Ticker>
                                </div>
                            }
                            <Sound url={this.state.currentSong} playStatus={this.state.playStatus} onFinishedPlaying={() => this.setState({ playStatus: Sound.status.STOPPED })}/>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default DraftBoard;

function countdownTime(time){
    var ret = 0;
    try {
        var dt = new Date(time);
        ret = (dt.getMinutes() * 60 * 1000) + (dt.getSeconds() * 1000);
    }
    catch(ex){
        console.log("[Error] Getting Countdown Time: ",ex);
    }

    return ret;
}

function formatTime(time, type){
    var ret = "XX";
    try {
        var d = new Date(time);            

        switch(type){
            case "m":
                ret = (d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes());
                break;
            case "s":
                ret = (d.getSeconds() < 10 ? "0"+d.getSeconds() : d.getSeconds());
                break;
            default:
                break;
        }
    }
    catch(ex){
        console.log("[Error] Formatting Time:",ex);
    }
    return ret;
}

function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}