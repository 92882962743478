import React, { Component } from 'react';

import logo from '../../../assets/imgs/leeleekiddz_logo.jpg';
import AdminStoreTool from './adminStoreTool';
import AdminUsersTool from './adminUsersTool';
import AdminInvoiceTool from './adminInvoiceTool';
import AdminRegToggleTool from './adminRegToggleTool';

class Admin extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading: false, pageNav:"invoice-admin"
        }
    }
    
    componentDidMount(){ }

    render(){  
        return(
            <div className="admin-page">
                <div className='admin-nav-panel'>
                    <div className='title'>
                        <span>Admin Tools</span>
                    </div>
                    <div className='admin-nav-link-container'>
                        <div className={`admin-nav-link ${this.state.pageNav === "uniform-admin" ? 'sel':''}`} onClick={()=> this.setState({ pageNav: "uniform-admin" })}>
                            <div className='nav-title'>
                                <i className="fas fa-store" />
                                <span>Uniform Store</span>
                            </div>
                            <i className="fas fa-chevron-right" />
                        </div>

                        <div className={`admin-nav-link ${this.state.pageNav === "league-admin" ? 'sel':''}`} onClick={()=> this.setState({ pageNav: "league-admin" })}>
                            <div className='nav-title'>
                                <i className="fas fa-store" />
                                <span>League Store</span>
                            </div>
                            <i className="fas fa-chevron-right" />
                        </div>

                        <div className={`admin-nav-link ${this.state.pageNav === "contact-admin" ? 'sel':''}`} onClick={()=> this.setState({ pageNav: "contact-admin" })}>
                            <div className='nav-title'>
                                <i className="fas fa-users" />
                                <span>User Accounts</span>
                            </div>
                            <i className="fas fa-chevron-right" />
                        </div>

                        <div className={`admin-nav-link ${this.state.pageNav === "registration-toggles" ? 'sel':''}`} onClick={()=> this.setState({ pageNav: "registration-toggles" })}>
                            <div className='nav-title'>
                                <i className="fas fa-users" />
                                <span>Registration Toggles</span>
                            </div>
                            <i className="fas fa-chevron-right" />
                        </div>

                        <div className={`admin-nav-link ${this.state.pageNav === "invoice-admin" ? 'sel':''}`} onClick={()=> this.setState({ pageNav: "invoice-admin" })}>
                            <div className='nav-title'>
                                <i className="fas fa-file-invoice" />
                                <span>Invoice Tool</span>
                            </div>
                            <i className="fas fa-chevron-right" />
                        </div>
                    </div>
                </div>
                <div className='admin-body-container'>
                    {this.state.pageNav == "uniform-admin" && <AdminStoreTool type="uniform" /> }
                    {this.state.pageNav == "league-admin" && <AdminStoreTool type="league" /> }
                    {this.state.pageNav == "contact-admin" && <AdminUsersTool /> }
                    {this.state.pageNav == "registration-toggles" && <AdminRegToggleTool /> }
                    {this.state.pageNav == "invoice-admin" && <AdminInvoiceTool activeUser={this.props.activeUser} /> }
                </div>
            </div>
        );
    }
}
export default Admin;