import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';

import LoadSpinner from '../loadSpinner';
import CustomTable from '../customTable';

class DraftTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, selectedDraft: null,
            upcomingDrafts:[], pastDrafts:[], 
            draftModal: false, modalType: null,
            draftTitle: null, draftPlayerList:[]
        }

        this.draftPlayersCol = [
            { label: '', id: 'photo' }, { label: 'Name', id: 'name' }, 
            { label: 'Positions', id: 'positions' }, { label: 'Gender', id: 'gender' },
            { label: 'Highlights', id: 'highlights' }
        ]
        
        this.formatDate = this.formatDate.bind(this);
        this.loadDraftList = this.loadDraftList.bind(this);
        this.getDraftResults = this.getDraftResults.bind(this);
        this.getDraftPlayers = this.getDraftPlayers.bind(this);
        this.createDraft = this.createDraft.bind(this);
        this.deleteDraft = this.deleteDraft.bind(this);
    }

    formatDate(date, type){
        var ret = "";
        try {
            var d = new Date(date);
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            switch(type){
                case "day":
                    ret = days[d.getDay()] + " " + months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
                    break;
                case "time":
                    ret = (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0":"") + d.getMinutes() + (d.getHours() >= 12 ? "PM" : "AM");
                    break;
                default:
                    break;
            }
        }
        catch(ex){
            console.log("[Error] Formatting Date:",ex);
        }
        return ret;
    }

    loadDraftList(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                axios.get(self.props.rootPath + "/api/draft", {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Loading Draft List (E1): ", response.data.error);
                    } else{
                        self.setState({ 
                            upcomingDrafts: response.data.results.upcoming, selectedDraft: null,
                            pastDrafts: response.data.results.past
                        })
                    }
                }).catch(function (error) {
                    console.log("[Error] Loading Draft List (E2)",error);
                })
                .then(function(){
                    self.setState({ loading: false });
                });  
            });
        }
        catch(ex){
            console.log("[Error] Loading Draft List:",ex);
        }
    } 

    getDraftResults(draftId){
        var self = this;
        try {
            this.setState({ loading: true, modalType: "draftResults", draftModal: true }, ()=> {
                axios.get(self.props.rootPath + "/api/draft/"+draftId, {headers: {'Content-Type': 'application/json'}})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Getting Draft Info (E1): ", response.data.error);
                    }
                    else{
                        self.setState({ selectedDraft: response.data.results });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] Loading Draft Results:",ex);
        }
    }

    getDraftPlayers(draftId, title){
        var self = this;
        try {
            if(draftId) {
                this.setState({ loading: true, modalType: "draftPlayers", draftModal: true, draftTitle: title }, ()=> {
                    axios.get(self.props.rootPath + "/api/draft/players/"+draftId, {headers:{'Content-Type': 'application/json'}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log("[Error] Getting Draft Players: ",response.data.error);
                        }
                        else {
                            self.setState({ draftPlayerList: response.data.results });
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
                });  
            }
        }
        catch(ex){
            console.log("[Error] Getting Draft Players", ex);
        }
    }

    createDraft(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                var postData = { draft: {title: "New Draft", dateTime: new Date()}};

                axios.post(self.props.rootPath + "/api/draft/update", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Creating Draft (E1): ", response.data.error);
                            alert("Unable To Create Draft");
                        }
                        else if(response.data.results){
                            self.props.setDraftId(response.data.results, true);
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Creating Draft (E2)",error);
                        alert("Unable To Create Draft");
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] Creating Draft: ",ex);
        }
    }

    deleteDraft(draftId){
        var self = this;
        try {
            if(confirm("Are you sure you want to delete this draft?")){
                this.setState({ loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    axios.get(self.props.rootPath + "/api/draft/delete/" + draftId, {headers: {'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Deleting Draft (E1): ", response.data.error);
                        }
                        else {
                            self.loadDraftList();
                        }
                    }).catch(function (error) {
                        console.log("[Error] Deleting Draft (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    });
                });  
            }
        }
        catch(ex){
            console.log("[Error] Deleting Draft", ex);
        }
    }


    componentDidMount(){ 
        this.loadDraftList();
    }

    render(){  
        return(
            <div className="tab-content">
                {this.state.loading && <LoadSpinner colorClass={"c3"}/> }
                
                <div className="draft-page">
                    <div className="title-btn-container">
                        <h1 className="tab-title">Upcoming Drafts</h1>

                        {this.props.isAdmin() &&
                            <div className="btn-container">
                                <div className="link-btn cgadd" onClick={this.createDraft}><i className="fas fa-users" />Add Draft</div>
                            </div>
                        }
                    </div>
                    <div className="draft-list">                   
                        {this.state.upcomingDrafts.map((draft,i) =>
                            <div className="draft-row" key={i}>
                                <div className="row-col draft-icon future" />
                                <div className="row-col"><h3>{draft.title}</h3></div>
                                <div className="row-col">
                                    <div className="day">{this.formatDate(draft.dateTime, "day")}</div>
                                    <div className="time">{this.formatDate(draft.dateTime, "time")}</div>
                                </div>
                                <div className="row-col"><span className="location"><i className="fas fa-map-marked-alt"/> {draft.location}</span></div>
                                <div className="row-col admin-panel">
                                    <div className="btn-container">
                                        {draft.liveId != null &&
                                            <a href={"/liveDraft?draftId=" + draft.liveId} target="_blank" className="view-btn" title="Watch Live Draft">Watch Draft</a>
                                        }
                                         <div className="view-btn" title="Draft Roster" onClick={() => this.getDraftPlayers(draft._id, draft.title)}>Roster</div>             
                                        {this.props.isAdmin() &&                                                
                                            <div className="admin-btn edit" title="Edit Upcoming Draft" onClick={() => this.props.setDraftId(draft._id, true)}><i className="far fa-edit" /></div>             
                                        }
                                        {(this.props.isAdmin() && draft.liveId === null) &&                                                
                                            <div className="admin-btn edit" title="Delete Upcoming Draft" onClick={() => this.deleteDraft(draft._id)}><i className="fas fa-trash" /></div>             
                                        }
                                    </div>
                                </div>                                    
                            </div>
                        )} 
                        {this.state.upcomingDrafts.length == 0 && <h1>Please Check Back For Upcoming Drafts</h1>}  
                    </div>

                    {/* Previous Drafts */}
                    <h1 className="tab-title">Previous Drafts</h1>
                    <div className="draft-list">                   
                        {this.state.pastDrafts.map((draft,i) =>
                            <div className="draft-row" key={i}>
                                <div className="row-col draft-icon past" />
                                <div className="row-col"><h3>{draft.title}</h3></div>
                                <div className="row-col">
                                    <div className="day past">{this.formatDate(draft.dateTime, "day")}</div>
                                    <div className="time">{this.formatDate(draft.dateTime, "time")}</div>
                                </div>
                                <div className="row-col"><span className="location"><i className="fas fa-map-marked-alt"/> {draft.location}</span></div>
                                <div className="row-col draft-ctrls">
                                    <div className="link-btn c5" onClick={()=> this.getDraftResults(draft._id)}>Results</div>
                                </div>
                            </div>
                        )}   

                        {this.state.pastDrafts.length == 0 && <h1>Draft Results will be released after completion of live draft</h1>}
                    </div>
                </div>
                
                <Modal open={this.state.draftModal} onClose={()=>this.setState({ draftModal: false })}>
                    <div className="modal-body cg1 lrg">
                        <i className="close-modal fas fa-times" onClick={()=>this.setState({ draftModal: false })}/>
                        {(this.state.modalType == "draftPlayers" && this.state.draftPlayerList) &&
                            <div className="draft-player-list">
                                <h1>Draft Roster</h1>
                                <p className="draft-subtitle">{this.state.draftTitle}</p>
                                <div className="player-panel">
                                    <CustomTable rows={this.state.draftPlayerList} headCells={this.draftPlayersCol} deleteBtn={false} />
                                </div>
                            </div>
                        }

                        {(this.state.modalType == "draftResults" && this.state.selectedDraft != null) &&
                            <div className="draft-results">
                                <div className="draft-title">
                                    <h1>{this.state.selectedDraft.title}</h1>
                                    <div className="date">
                                        <span>{this.formatDate(this.state.selectedDraft.dateTime, "day")}</span> 
                                        <span>{this.formatDate(this.state.selectedDraft.dateTime, "time")}</span> 
                                    </div>                            
                                </div>
                                <div className="results-container">
                                    {Object.keys(this.state.selectedDraft.draftResults).map((teamNm,i) =>
                                        <div className="draft-team" key={i}>
                                            <div className="team-title">
                                                <div className="title-logo"><img src={this.state.selectedDraft.draftResults[teamNm].logo} /></div>
                                                <h2 style={{ color: "rgba("+this.state.selectedDraft.draftResults[teamNm].color.r+","+this.state.selectedDraft.draftResults[teamNm].color.g+","+this.state.selectedDraft.draftResults[teamNm].color.b+",0.9)" }}>{this.state.selectedDraft.draftResults[teamNm].name}</h2>
                                            </div>
        
                                            <div className="roster-list">
                                                {this.state.selectedDraft.draftResults[teamNm].roster.map((player,j) =>
                                                    <div className="player-card" key={j}>
                                                        <div className="number-icon" style={{ color: "rgba("+this.state.selectedDraft.draftResults[teamNm].color.r+","+this.state.selectedDraft.draftResults[teamNm].color.g+","+this.state.selectedDraft.draftResults[teamNm].color.b+",0.9)" }}>{player.playerNum}</div>
                                                        <div className="info-section">
                                                            <div className="play-info">
                                                                <span className="gender">{player.gender}</span>
                                                                <span>{player.positions}</span>
                                                            </div>
                                                            <div className="name" style={{ color: "rgba("+this.state.selectedDraft.draftResults[teamNm].color.r+","+this.state.selectedDraft.draftResults[teamNm].color.g+","+this.state.selectedDraft.draftResults[teamNm].color.b+",0.9)" }}>{player.name}</div>
                                                            {(player.round === 0 ? 
                                                                <div className="draft-info captain">Captain</div> :
                                                                <div className="draft-info">
                                                                    <span>RD: {player.round}</span>
                                                                    <span>PK: {player.pick}</span>
                                                                </div>
                                                            )}                                                    
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}
export default DraftTab;