import React, { Component } from 'react';
import axios from 'axios';

import LoadSpinner from '../loadSpinner';

/* Images */
const imgList = [
        "/imgs/site/mini/default5.jpg", "/imgs/site/mini/default9.jpg",
        "/imgs/site/mini/action1.jpg", "/imgs/site/mini/action2.jpg", "/imgs/site/mini/default7.jpg",
        "/imgs/site/mini/default10.jpg", "/imgs/site/mini/default6.jpg", "/imgs/site/mini/default15.jpg",
        "/imgs/site/mini/mini1.jpg", "/imgs/site/mini/mini2.jpg", "/imgs/site/mini/mini3.jpg",
        "/imgs/site/mini/mini4.jpg", "/imgs/site/mini/mini5.jpg"
];

class PlayerSpotlightTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, dateData:[], spotlightData:[]
        }

        this.toggleSpotlightImg = this.toggleSpotlightImg.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.contentBuilder = this.contentBuilder.bind(this);
        this.getPlayerSpotlights = this.getPlayerSpotlights.bind(this);
        this.getPlayerDates = this.getPlayerDates.bind(this);
    }
    
    toggleSpotlightImg(key){
        try {
            if(key < this.state.spotlightData.length){
                var tmpSpotlight = this.state.spotlightData;

                tmpSpotlight[key].fullImg = ("fullImg" in tmpSpotlight[key] ? !tmpSpotlight[key].fullImg : true);
                this.setState({ spotlightData: tmpSpotlight });
            }
        }
        catch(ex){
            console.log("[Error] Toggling spotlight: ",ex);
        }
    }

    formatDate(date, type){
        var ret = "";
        try {
            var d = new Date(date);
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            switch(type){
                case "day":
                    ret = days[d.getDay()] + " " + months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
                    break;
                case "date":
                    ret = d.getDate();
                    break;
                case "month":
                    ret = months[d.getMonth()];
                    break;
                case "time":
                    ret = (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0":"") + d.getMinutes() + (d.getHours() >= 12 ? "PM" : "AM");
                    break;
                default:
                    break;
            }
        }
        catch(ex){
            console.log("[Error] Formatting Date:",ex);
        }
        return ret;
    }

    contentBuilder(item, key){
        try {
            if(item){               
                switch(item.type){
                    case "player":
                        return(
                            <div className="item-container player" key={key}>
                                <div className="item-row split">
                                    <div className="img-main half"><img src={item.image} /></div>
                                    <div className="split-content">
                                        <h1>{item.title}</h1>
                                        <h2 className="color-alt">{item.name}</h2>
                                    </div>
                                </div>
                                <div className="item-row"><p>{item.text}</p></div>
                            </div>);
                    case "league":
                        return(
                            <div className="item-container spotlight" key={key}>
                                <div className="item-row">
                                    <div className={"img-main" + (item.fullImg === true ? " open" : "")} >
                                        <img src={item.image} />
                                        <div className="expand-icon" onClick={()=> this.toggleSpotlightImg(key)}>
                                            <i className={"fas " + (item.fullImg === true ? "fa-compress-arrows-alt" : "fa-expand-arrows-alt")}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-row"><h1>{item.title}</h1></div>
                                <div className="item-row"><h2>{item.name}</h2></div>
                                <div className="item-row"><p>{item.text}</p></div>
                            </div>);
                    default:
                        return(<span key={key}/>);
                }
            }
        }
        catch(ex){
            console.log("[Error] Building content item: ",ex);
        }
    }
    
    getPlayerSpotlights(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                axios.get(self.props.rootPath + "/api/getPlayerSpotlights", {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(!response.data.error){
                        self.setState({ spotlightData: response.data.results });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });
        }
        catch(ex){
            console.log("[Error] Loading Draft List:",ex);
        }
    }

    getPlayerDates(){
        var self = this;
        try {
            axios.get(self.props.rootPath + "/api/getPlayerDates", {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(!response.data.error){
                        self.setState({ dateData: response.data.results });
                    }
                }).then(function(){});             
        }
        catch(ex){
            console.log("[Error] Loading Draft List:",ex);
        }
    }

    componentDidMount(){ this.getPlayerSpotlights(); this.getPlayerDates(); }

    render(){  
        return(
            <div className="tab-container player-spotlight-info">
                <h1 className="tab-title">Player Spotlight</h1>
                    {/*<div className="date-info-container">
                        <div className="info-title"><i className="fas fa-gift"/> Birthdays</div>
                        {this.state.dateData && this.state.dateData.length > 0 &&
                            <div className="date-item-container">
                                {this.state.dateData.map((item,i) =>
                                    <div className="date-item" key={i}>
                                        <div className="date-block">
                                            <span>{this.formatDate(item.birthDate, "month")}</span>
                                            <span className="lrg">{this.formatDate(item.birthDate, "date")}</span>
                                        </div>
                                        <div className="date-name">
                                            <span>{item.firstname}</span> 
                                            <span>{item.lastname}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>*/}
                    
                    <div className="league-info-container">
                        {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                        {this.state.spotlightData.map((info,key) => this.contentBuilder(info, key) )}
                        {!this.state.loading && this.state.spotlightData.length === 0 && <div className="lrgTitle ctr cw" data-text="Coming Soon">More Player Spotlights Coming Soon!</div>}
                    </div>
                </div>
        );
    }
}
export default PlayerSpotlightTab;