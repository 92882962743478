import React, { Component } from 'react';


class Layout2 extends Component{
    componentDidMount(){ }
    render(){  
        return(
            <>                    
                {this.props.children}
            </>
        );
    }
}

export default Layout2;