import React, { Component } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Papa from "papaparse";

class FileUploader extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fileData:[], uploadFiles: null, loading: false,
            posList:["QB","WR","CB","LB","FS","RS","ATH"],
            genderMap: { "M": true, "F":true },
            playerData:[
                { title: "name", required: true, type: "text" }, { title: "gender", required: true, type: "gender" },
                { title: "positions", required: true, type: "position" }, { title: "email", required: false },
                { title: "number", required: false }
            ]
        }

        this.fileInputRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.buildCSV = this.buildCSV.bind(this);
        this.validateRow = this.validateRow.bind(this);
        this.parseCSV = this.parseCSV.bind(this);
    }

    handleChange(e){
        let self = this;
        try {
            let files = [...e.target.files];

            if(files.length > 0){
                this.setState({ uploadFiles: files }, ()=> {
                    // Trigger File Processing
                    self.parseCSV();
                });
            }            
        }
        catch(ex){
            console.log("[Error] handling change: ",ex);
        }
    }

    buildCSV(){
        let ret = [];
        try {
            ret = this.state.playerData.map((pd)=> { return { 
                id: pd.title, displayName: `${pd.title}`
            }; });
        }
        catch(ex){
            console.log(`Downloading CSV: ${ex}`);
        }
        return ret;
    }

    validateRow(row, idx) {
        let error = [], self = this;
        try {           
            this.state.playerData.forEach((pd)=> {
                if(pd.required){
                    if(!(pd.title in row)){
                        error.push({"isCol": true, "msg":`${pd.title} Column Missing`});
                    }
                    else if(pd.type == "text" && row[pd.title]?.length <= 0){
                        error.push({"isCol": false, "msg":` - ${pd.title} Missing (Row: ${(idx+2)})`});
                    }
                    else if(pd.type == "gender" && !(row[pd.title].toUpperCase() in this.state.genderMap)){
                        error.push({"isCol": false, "msg":` - ${pd.title} must be either M or F (Row: ${(idx+2)})`});
                    }
                    else if(pd.type == "position"){
                        let posRow = row[pd.title].split(',');
                        let valid = posRow.length > 0;

                        for(let i=0; i < posRow.length; i++){
                            let idx = self.state.posList.indexOf(posRow[i].toUpperCase());
                            valid = (idx >= 0);
                            if(!valid) break;
                        }

                        if(!valid){
                            error.push({"isCol": false, "msg":` - ${pd.title} must be [${self.state.posList.join(",")}] (Row: ${(idx+2)})`});
                        }
                    }
                }
            });
        }
        catch(ex){
            console.log(`Validating Row: ${ex}`);
            error.push({"isCol": true, "msg":`Validating Row`});
        }

        return error;
    }

    parseCSV(){
        let self = this;
        try {
            if(this.state.uploadFiles?.length >= 0){
                let file = this.state.uploadFiles[0];

                const reader = new FileReader();
                reader.onload = async ({ target }) => {
                    const csv = Papa.parse(target.result, {
                        header: true,
                    });

                    const parsedData = csv?.data;

                    // Parse Data
                    let errors = [];
                    parsedData.forEach((d, idx)=> {
                        errors = errors.concat(self.validateRow(d, idx));
                    });

                    if(errors.length > 0){
                        let colErrors = errors.filter((e)=> e.isCol == true),
                            defaultErrors = errors.filter((e)=> e.isCol == false);

                        colErrors = colErrors.filter((item, index) => colErrors.map((r)=> r.msg).indexOf(item.msg) === index);
                        
                        alert(`Please fix the following issues with your file:\n${"\n"+colErrors.map((ce)=> ce.msg).join('\n')}${"\n"+defaultErrors.map((de)=> de.msg).join('\n')}`);
                    }
                    else {
                        // Submit Data
                        self.props.addPlayers(parsedData);
                    }                    
                };

                reader.readAsText(file);
            }
        }
        catch(ex){
            console.log(`Parsing CSV File: ${ex}`);
        }
    }

    render(){
        return(
            <div className='file-uploader'>
                <div className='selection-btn link-btn c2' onClick={()=> { this.fileInputRef?.current?.click(); }}>
                    <i className="fas fa-upload" />
                    Upload Player List
                </div>
                <div className='selection-btn link-btn c5'>
                    <CsvDownloader filename="player_list_template" extension=".csv" 
                        separator="," columns={this.buildCSV()} datas={[]}>
                        <i className="fas fa-file-csv" />
                        Template
                    </CsvDownloader>
                </div>

                {/* Hidden */}
                <input type="file" accept=".csv" multiple={false}
                    onChange={this.handleChange} ref={this.fileInputRef} hidden
                    onClick={(e)=> { e.target.value = null; }}
                />
            </div>
        );
    }
}

export default FileUploader;