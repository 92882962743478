import React, { Component } from 'react';
import { Link } from "react-router-dom";

/* Tab Components */
import LeagueInfoTab from './tabs/leagueInfoTab';
import LeagueCalendarTab from './tabs/leagueCalendarTab';
import PlayerSpotlightTab from './tabs/playerSpotlightTab';
import DraftTab from './tabs/draftTab';
import SignIn from './tabs/signIn';
import DraftEditorInfo from './tabs/draftEditorInfo';
import DraftEditorTeams from './tabs/draftEditorTeams';
import DraftEditorPlayers from './tabs/draftEditorPlayers';
import DraftEditorPreview from './tabs/draftEditorPreview';
import LeagueRoster from './tabs/leagueRoster';
import UserManagement from './tabs/userManagement';

const mySessKey = "leeleeDraftTourney_.";
const tabList = {
    "leagueinfo":1, "leaguecalendar":1, "draft":1,
    "draftinfo":1, "draftplayers":1, "leagueroster":1,
    "draftteams":1, "draftpreview":1, "signin":1, 
    "playerspotlight":1, "usermanagement":1
};

class TabSection extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userName: null, isAdmin: false,
            selectedTab: null, selectedDraftId: null
        }
        
        this.signOut = this.signOut.bind(this);
        this.checkToken = this.checkToken.bind(this);
        this.checkIsAdmin = this.checkIsAdmin.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.setTabSection = this.setTabSection.bind(this);
        this.createDraft = this.createDraft.bind(this);
        this.setDraftId = this.setDraftId.bind(this);
    }

    signOut(){
        var self = this;
        try {
            this.userHandler(null, function(){ self.setTabSection("signin"); })
        }
        catch(ex){
            console.log("[Error] Signing Out: ", ex);
        }
    }

    checkToken() {
        try {
            var sessionInfo = localStorage.getItem(mySessKey);

            if(sessionInfo && sessionInfo[0] === "{") {
                localStorage.removeItem(mySessKey);
                this.setState({ userName: null, isAdmin: false });
            }
            else if(sessionInfo){
                var { localUser, isExpired } = parseToken(sessionInfo);

                if(!isExpired){
                    this.setState({ userName: localUser.name, isAdmin: localUser.admin });
                }
                else {
                    localStorage.removeItem(mySessKey);
                    this.setState({ userName: null, isAdmin: false });
                }
            }
        }
        catch(ex) {
            console.log("[Error] Checking Token: ", ex);
        }
    }

    checkIsAdmin(){
        var ret = false;
        try {
            var sessionInfo = localStorage.getItem(mySessKey);

            if(sessionInfo){
                var { localUser, isExpired } = parseToken(sessionInfo);

                if(!isExpired){
                    ret = localUser.admin;
                }
            }
        }
        catch(ex){
            console.log("[Error] Check Is Admin: ",ex);
        }
        return ret;
    }

    userHandler(newToken, cb) {
        var self = this;
        try {   
            var usr = null;
            if(newToken){
                var { localUser, isExpired } = parseToken(newToken);                

                if(!isExpired) { usr = localUser.name; }
            }   

            self.setState({ userName: usr },() => {
                if(!self.state.userName){
                    localStorage.removeItem(mySessKey); cb();
                } 
                else {
                    localStorage.setItem(mySessKey, newToken); cb();
                }
            });          
        }
        catch(ex){
            console.log("Error with user Handler: ", ex);
        }
    }

    setTabSection(section){
        try {
            if(!(section.toLowerCase() in tabList)){
                section = Object.keys(tabList)[0];
            }

            this.setState({ selectedTab: section.toLowerCase() });
        }
        catch(ex){
            console.log("[Error] Setting Tab Section: ",ex);
        }
    }

    createDraft() {
        var self = this;
        try {
            this.setState({ selectedDraftId: 0}, ()=>{ self.setTabSection("draftinfo"); });
        }
        catch(ex){
            console.log("[Error] Creating Draft: ",ex);
        }
    }

    setDraftId(id, defaultTab){
        try {
            this.setState({ selectedDraftId: id, selectedTab: (defaultTab && id.length > 1 ? "draftinfo" : this.state.selectedTab) });
        }
        catch(ex){
            console.log("[Error] Setting Draft Id: ",ex);
        }
    }

    componentDidMount(){ 
        this.setTabSection(this.props.selected);
        this.checkToken();
    }
    
    render(){  
        return(
            <div className="tab-section">
                <div className="tab-nav-container">
                    <Link className={"tab-link c2" + (this.state.selectedTab == "leagueinfo" ? " sel" : "")} to="/leagueinfo" onClick={()=> this.setTabSection("leagueinfo")}>League Info</Link>
                    <a className={"tab-link c2" + (this.state.selectedTab == "leaguecalendar" ? " sel" : "")} onClick={()=> this.setTabSection("leaguecalendar")}>Calendar</a>
                    <Link className={"tab-link c2" + (this.state.selectedTab == "playerspotlight" ? " sel" : "")} to="/playerspotlight" onClick={()=> this.setTabSection("playerspotlight")}>Player Spotlight</Link>
                    <Link className={"tab-link c3" + (this.state.selectedTab == "draft" ? " sel" : "")} to="/drafts" onClick={()=> this.setTabSection("draft")}>Drafts</Link>
                    {(this.state.selectedDraftId != null && this.checkIsAdmin() == true) &&
                        <div>
                            <a className={"tab-link c1" + (this.state.selectedTab == "draftinfo" ? " sel" : "")} onClick={()=> this.setTabSection("draftinfo")}>Draft Info</a>
                            {this.state.selectedDraftId.length > 1 &&
                                <div>
                                    <a className={"tab-link c1" + (this.state.selectedTab == "draftteams" ? " sel" : "")} onClick={()=> this.setTabSection("draftteams")}>Draft Teams</a>
                                    <a className={"tab-link c1" + (this.state.selectedTab == "draftplayers" ? " sel" : "")} onClick={()=> this.setTabSection("draftplayers")}>Draft Players</a>
                                    <a className={"tab-link c1" + (this.state.selectedTab == "draftpreview" ? " sel" : "")} onClick={()=> this.setTabSection("draftpreview")}>Draft Preview</a>
                                </div>
                            }
                        </div>
                    }

                    {this.checkIsAdmin() == true && <a className={"tab-link c5" + (this.state.selectedTab == "leagueroster" ? " sel" : "")} onClick={()=> this.setTabSection("leagueroster")}>League Roster</a> }
                    {this.checkIsAdmin() == true && <a className={"tab-link c1" + (this.state.selectedTab == "usermanagement" ? " sel" : "")} onClick={()=> this.setTabSection("usermanagement")}>Manage Users</a> }
                </div>
                <div className="tab-content-container">
                    <div className="admin-panel">
                        <div className="btn-container">
                            {(this.state.userName == null ? 
                                <div className="admin-btn" onClick={()=> this.setTabSection("signin")}><i className="fas fa-user" /></div> 
                                : <div className="admin-text">Welcome {this.state.userName} <i className="far fa-times-circle" onClick={this.signOut}></i></div> 
                            )}
                        </div>
                    </div>

                    {this.state.selectedTab == "leagueinfo" && <LeagueInfoTab rootPath={this.props.rootPath}/> }
                    {this.state.selectedTab == "leaguecalendar" && <LeagueCalendarTab rootPath={this.props.rootPath}/> }
                    {this.state.selectedTab == "playerspotlight" && <PlayerSpotlightTab rootPath={this.props.rootPath}/> }
                    {this.state.selectedTab == "draft" && <DraftTab rootPath={this.props.rootPath} mySessKey={mySessKey} isAdmin={this.checkIsAdmin} setDraftId={this.setDraftId}/> } 
                    {this.state.selectedTab == "signin" && <SignIn rootPath={this.props.rootPath} setTabSection={this.setTabSection} userHandler={this.userHandler} />}
                    {(this.state.selectedTab == "draftinfo" && this.checkIsAdmin() == true) && <DraftEditorInfo rootPath={this.props.rootPath} mySessKey={mySessKey} selectedDraftId={this.state.selectedDraftId} setDraftId={this.setDraftId} setTabSection={this.setTabSection}/> }                   
                    {(this.state.selectedTab == "draftteams" && this.checkIsAdmin() == true) && <DraftEditorTeams rootPath={this.props.rootPath} mySessKey={mySessKey} selectedDraftId={this.state.selectedDraftId} setTabSection={this.setTabSection} />}
                    {(this.state.selectedTab == "draftplayers" && this.checkIsAdmin() == true) && <DraftEditorPlayers rootPath={this.props.rootPath} mySessKey={mySessKey} selectedDraftId={this.state.selectedDraftId} setTabSection={this.setTabSection} />}
                    {(this.state.selectedTab == "draftpreview" && this.checkIsAdmin() == true) && <DraftEditorPreview rootPath={this.props.rootPath} mySessKey={mySessKey} selectedDraftId={this.state.selectedDraftId} setTabSection={this.setTabSection} />}                    
                    {(this.state.selectedTab == "leagueroster" && this.checkIsAdmin() == true) && <LeagueRoster rootPath={this.props.rootPath} mySessKey={mySessKey} selectedDraftId={this.state.selectedDraftId} setTabSection={this.setTabSection} />}

                    {(this.state.selectedTab == "usermanagement" && this.checkIsAdmin() == true) && <UserManagement rootPath={this.props.rootPath} mySessKey={mySessKey} setTabSection={this.setTabSection} />}
                </div>
            </div>
        );
    }
}
export default TabSection;

function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}