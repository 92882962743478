import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

/* Images */
import back from '../../assets/imgs/404.jpg'

class CatchAllPage extends Component{
    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Page 404 Error";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/404');
    }
    render(){  
        return(
            <div className="page-body error-page">
                <section className="error-container">                   
                    <div className="img-container"><img src={back} /></div>
                    <div className="error-text">
                        <h1>Oops!</h1>
                        <p>We can't seem to find the page you are looking for.</p>
                        <span>Here are some helpful links instead:</span>
                        <div className="link-container">
                            <Link to="/" className="home-link">Home</Link>
                            <Link to="/leagueinfo" className="home-link">League Information</Link>
                            <Link to="/rules" className="home-link">Rules</Link>
                        </div>
                    </div>                        
                </section>
            </div>
        );
    }
}
export default CatchAllPage;