import axios from 'axios';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Select from 'react-select';

/* Table */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

/* Images */
const imgList = ["/imgs/site/s11.png","/imgs/site/s3.png","/imgs/site/s4.png"];

/* Column Fields */
const headCells = [
    { id: "gameTime", label: "Date", align: 'left', class: 'full'}, { id: "teamName", label: "Team", align: 'left', class: ''},
    { id: "oppName", label: "Opponent", align: 'left', class: ''},
    { id: 'qTd', label: 'Pass TD', align: 'right', class: '' }, { id: "qInt", label: "Pass Int", align: 'right', class: ''},
    { id: 'recTd', label: 'Rec TD', align: 'right', class: '' }, { id: "rushTd", label: "Rush TD", align: 'right', class: ''},
    { id: 'conv1', label: '1PT Conv', align: 'right', class: '' }, { id: "conv2", label: "2PT Conv", align: 'right', class: ''},
    { id: 'dInt', label: 'Int', align: 'right', class: '' }, { id: "dSack", label: "Sacks", align: 'right', class: ''},
    { id: 'dSafety', label: 'Safety', align: 'right', class: '' }, { id: 'dTd', label: 'Defensive TD', align: 'right', class: '' }
];

const cellTitles = {
    "qTd":"Passing TD", "qInt":"Passing Int", "recTd":"Rec TD", "rushTd":"Rush TD",
    "conv1":"1pt Conv.", "conv2":"2pt Conv.", "dInt":"Def Int", "dSack":"Def Sacks",
    "dSafety":"Def Safety", "dTd":"Defensive TD"
};

/* Select Styles */
const colourStyles = {
    control: (styles) => ({ 
        ...styles, backgroundColor: '#000', borderRadius: '50px', boxShadow: 'none !important', 
        '&:hover':{ borderColor: '#ee246e' },
        '&:focus':{ boxShadow: 'none', borderColor: '#ee246e' } 
    }),
    option: (styles, { isFocused }) => ({ 
        ...styles, backgroundColor: isFocused ? 'rgba(238,36,110,0.4)' : 'none' ,
        color: '#000', textTransform: 'capitalize'
    }),
    input: (styles) => ({ ...styles, backgroundColor: '#000', color:'#fff' }),
    singleValue: (styles) => ({ ...styles, color:'#fff', zIndex:1 }),
  };

/* Table Header */
function EnhancedTableHead(props) {  
    return (
      <TableHead>
        <TableRow>
            <TableCell align="center" colSpan={3}/>
            <TableCell align="center" colSpan={6}>Offense</TableCell>
            <TableCell align="center" colSpan={4}>Defense</TableCell>
        </TableRow>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align={headCell.align} padding='normal' className={headCell.class}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}

class PlayerStats extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading1: true, loading2: true, loading3: false, playerId: null, 
            playerInfo: null, displayImg: null, leagues:[],
            page: 0, rowsPerPage: 10, playerRating: null, selLeague: null
        }

        this.formatDate = this.formatDate.bind(this);
        this.getCellName = this.getCellName.bind(this);
        this.selectLeague = this.selectLeague.bind(this);
        this.changeLeague = this.changeLeague.bind(this);
        this.loadLeagueInfo = this.loadLeagueInfo.bind(this);
        this.loadPlayerStats = this.loadPlayerStats.bind(this);
        this.loadPlayerRating = this.loadPlayerRating.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    formatDate(date){
        var ret = "";
        try {
            var monthList = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            d = new Date(date);
            ret = `${monthList[d.getMonth()]}. ${d.getDate()}, ${d.getFullYear()}`;
        }
        catch(ex){
            console.log("[Error] formatting date: ",ex);
        }

        return ret;
    }

    getCellName(id){
        var ret = "";
        try {
            ret = cellTitles[id];
        }
        catch(ex){
            console.log(`[Error] getting cell name: ${ex}`);
        }

        return ret;
    }

    selectLeague(selLeague){
        var self = this;
        try {
            if(selLeague && selLeague != this.state.selLeague){
                this.setState({ selLeague: selLeague }, ()=> { 
                    self.loadPlayerStats(self.state.selLeague.value);
                });
            }
        }
        catch(ex){
            console.log(`[Error] selectin league: ${ex}`);
        }
    }

    changeLeague(newValue, actionMeta){
        try {
            this.selectLeague(newValue);
        }
        catch(ex){
            console.log(`[Error] changing league: ${ex}`);
        }
    }

    loadLeagueInfo(){
        var self = this;
        try {
            const rndInt = Math.floor(Math.random() * imgList.length);

            this.setState({ loading2: true, displayImg: imgList[rndInt] }, ()=> {
                axios.get(self.props.rootPath + "/api/stats/leagues", {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Stat League Data (E1): ", response.data.error);
                        }
                        else {
                            var tmpLeagues = response.data.results.map(function(item){
                                var d = new Date(item.startTime),
                                itemLabel = `(${(d.getMonth()+1)}/${d.getFullYear()}) - ${item.name.slice(0, 30)}${item.name.length > 30 ? '...' : ''}`;
                                return { value: item._id, label: itemLabel };
                            });

                            // Add League Career Stats Select
                            tmpLeagues.unshift({ value:null, label:"All League Stats" });

                            self.setState({ leagues: tmpLeagues }, ()=>{
                                var leagueId = (self.state.leagues.length > 1 ? self.state.leagues[1] : null);
                                self.selectLeague(leagueId);
                            });
                        }
                    }).then(function(){
                        self.setState({ loading2: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] loading stat league list: ", ex);
        } 
    }

    loadPlayerStats(leagueId){
        var self = this;
        try {
            if(this.props.match.params.playerId) {
                const leagueQuery = (leagueId ? `?leagueId=${leagueId}` : '');

                this.setState({ loading1: true, loading3: true, playerId: this.props.match.params.playerId }, ()=> {
                    axios.get(`${self.props.rootPath}/api/stats/search/${self.state.playerId}${leagueQuery}`, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Searching Player Stats (E1): ", response.data.error);
                                self.setState({ loading1: false, loading3: false });
                            }
                            else {                            
                                self.setState({ playerInfo: response.data.results }, ()=>{
                                    self.loadPlayerRating(leagueId);
                                });
                            }
                        }).then(function(){
                            self.setState({ loading1: false, loading3: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`[Error] loading player stats: ${ex}`);
        }
    }

    loadPlayerRating(leagueId){
        var self = this;
        try {
            var playerId = (this.state.playerInfo && this.state.playerInfo.laId ? this.state.playerInfo.laId : null);

            if(!leagueId){
                self.setState({ loading3: false, playerRating: null });
            }
            else if(leagueId && playerId) {
                this.setState({ loading3: true }, ()=> {
                    axios.get(`${self.props.rootPath}/api/stats/rating/league/${leagueId}/player/${playerId}`, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Getting Player Rating (E1): ", response.data.error);
                            }
                            else {                            
                                self.setState({ playerRating: response.data.results });
                            }
                        }).then(function(){
                            self.setState({ loading3: false });
                        }); 
                });   
            }         
        }
        catch(ex){
            console.log(`[Error] Getting Player Rating: ${ex}`);
        }
    }

    handleChangePage(event, newPage){
        try {
            this.setState({ page: newPage });
        }
        catch(ex){
            console.log(`[Error] Changing Page: ${ex}`);
        }
    }

    handleChangeRowsPerPage(event){
        try {
            this.setState({ page: 0,  rowsPerPage: event.target.value });
        }
        catch(ex){
            console.log(`[Error] Changing Rows Per Page: ${ex}`);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Player Stats";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/player-stats');
        this.loadLeagueInfo();
    }
    render(){  
        const emptyRows = ((this.state.playerInfo && this.state.playerInfo.playerStats.length > 0) ?
            this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.playerInfo.playerStats.length - this.state.page * this.state.rowsPerPage)
            : 0);

        return(
            <div className="page-body player-stats">
                <Link to="/stats" className="stats-back"><i className="fas fa-angle-double-left" /> <span>Back To Player Stats</span></Link>
                {this.state.displayImg && 
                    <div className="img-container">
                        {this.state.playerInfo && this.state.playerInfo.number && <div className="player-number">{this.state.playerInfo.number}</div> }
                        <img src={this.state.displayImg} alt="player stat silohette" />
                    </div>
                }

                {(this.state.loading1 && !this.state.playerInfo) && 
                    <>
                        <section className="player-info">
                            <div className="info-row name">
                                <div className="player-img"><i className="fas fa-user" /></div>
                                <div className="player-base-info">
                                    <div className="empty-name empty-animate" />
                                    <section className="league-selection">
                                        <div className="empty-pill empty-animate"/>                                            
                                    </section>
                                    <div className="player-rating-container">
                                        <div className="empty-rating empty-animate" /> <div className="empty-rating empty-animate" />
                                        <div className="empty-rating empty-animate" /> <div className="empty-rating empty-animate" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }

                {this.state.playerInfo &&
                    <>
                        <section className="player-info">
                            <div className="info-row name">
                                <div className="player-img">
                                    {(this.state.playerInfo.photo ? 
                                        <img src={this.state.playerInfo.photo} alt="player image" /> :
                                        <i className="fas fa-user" />
                                    )}
                                </div>
                                <div className="player-base-info">
                                    <div className="player-name">{this.state.playerInfo.name}</div>
                                    <section className="league-selection">
                                        {!this.state.loading2 && this.state.leagues && 
                                            <Select className="select-pill" name="league-list" styles={colourStyles} onChange={this.changeLeague} options={this.state.leagues} defaultValue={(this.state.selLeague ? this.state.selLeague : this.state.leagues[0])} isClearable={false} isSearchable={true} />
                                        }

                                        {/* Loading Shimmer */}
                                        {(this.state.loading2) &&
                                            <>
                                                <div className="empty-pill empty-animate"/>
                                            </>
                                        }
                                    </section>
                                    
                                    {this.state.loading3 &&
                                        <div className="player-rating-container">
                                            <div className="empty-rating empty-animate" /> <div className="empty-rating empty-animate" />
                                            <div className="empty-rating empty-animate" /> <div className="empty-rating empty-animate" />
                                        </div>
                                    }

                                    {(this.state.playerRating && !this.state.loading3) &&
                                        <div className="player-rating-container">
                                            <div className="rating"><span className="title">Quarterback</span>{(this.state.playerRating.qb === 0 ? <span className="no-val">N/A</span> : this.state.playerRating.qb)}</div>
                                            <div className="rating"><span className="title">Receiving</span>{(this.state.playerRating.wr === 0 ? <span className="no-val">N/A</span> : this.state.playerRating.wr)}</div>
                                            <div className="rating"><span className="title">Defensive Back</span>{(this.state.playerRating.db === 0 ? <span className="no-val">N/A</span> : this.state.playerRating.db)}</div>
                                            <div className="rating"><span className="title">Rusher</span>{(this.state.playerRating.rs === 0 ? <span className="no-val">N/A</span> : this.state.playerRating.rs)}</div>
                                        </div>
                                    }

                                    {(!this.state.playerRating && !this.state.loading3) &&
                                        <div className="player-rating-container">
                                            <div className="null-rating" /> <div className="null-rating" />
                                            <div className="null-rating" /> <div className="null-rating" />
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="info-row">
                                <p>{this.state.playerInfo.positions}</p>
                            </div>
                        </section>
                        
                        <section className="stat-section stat-highlights">
                            <h1>Stat Highlights</h1>

                            <div className="highlight-container">
                                {!this.state.loading1 &&                                
                                    <>
                                        {((this.state.playerInfo.topStats && this.state.playerInfo.topStats.length > 0) ? this.state.playerInfo.topStats.map((stat,i) =>
                                            <div className="stat-highlight" key={i}>
                                                <div className="stat-title">{this.getCellName(stat[0])}</div>
                                                <div className="stat-data">{stat[1]}</div>
                                            </div>
                                            ) : <div className="empty-stats">No Stats Avaliable</div>
                                        )}
                                    </>
                                }

                                {this.state.loading1 &&
                                    <>
                                        <div className="empty-highlight empty-animate" />
                                        <div className="empty-highlight empty-animate" />
                                        <div className="empty-highlight empty-animate" />
                                        <div className="empty-highlight empty-animate" />
                                    </>
                                }
                            </div>
                        </section>

                        <section className="stat-section game-stats stats-table-container">
                            <h1>Game Stats</h1>
                            
                            {!this.state.loading1 &&
                                <>
                                    {(this.state.playerInfo.playerStats && this.state.playerInfo.playerStats.length > 0) ?
                                        <Paper className="table-paper back" sx={{ width: '100%' }}>
                                            <TableContainer sx={{ maxHeight: '450px' }}>
                                                <Table stickyHeader aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                                                    <EnhancedTableHead />

                                                    <TableBody>
                                                        {this.state.playerInfo.playerStats && this.state.playerInfo.playerStats
                                                            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                            .map((row, i) => {
                                                                return (
                                                                    <TableRow key={i}>
                                                                        {headCells.map((cell,j) => 
                                                                            <TableCell align={cell.align} className={cell.class} key={j}>
                                                                                <span>{(cell.id === "gameTime" ? this.formatDate(row[cell.id]) : row[cell.id])}</span>                                                     
                                                                            </TableCell>
                                                                        )}
                                                                    </TableRow>
                                                                );
                                                        })}

                                                        {(emptyRows > 0 && emptyRows <= 5) && (
                                                            <TableRow style={{ height: 25 * emptyRows }}>
                                                                <TableCell colSpan={(headCells ? headCells.length : 0)} />
                                                            </TableRow>
                                                        )}

                                                        {this.state.playerInfo.totals &&
                                                            <TableRow>
                                                                {headCells.map((cell,j) => 
                                                                    <TableCell align={cell.align} className={"total-cell " + cell.class} key={j}>
                                                                        <span>{this.state.playerInfo.totals[cell.id]}</span>                                                     
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination rowsPerPageOptions={[10, 25, 50]}
                                                component="div" count={this.state.playerInfo.playerStats.length}
                                                rowsPerPage={this.state.rowsPerPage} page={this.state.page}
                                                onPageChange={this.handleChangePage}
                                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                            />
                                        </Paper> :
                                        <div className="empty-stats">No Game Stats Avaliable</div>
                                    }
                                </>
                            }

                            {this.state.loading1 &&
                                <div className="empty-table empty-animate" />
                            }
                        </section>
                    </>
                }

                {!this.state.playerInfo && !this.state.loading1 && <div className="empty-user">Unable To Find User</div> }
            </div>
        );
    }
}
export default PlayerStats;