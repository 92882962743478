import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';

/* Images */
import logo_c from '../../assets/imgs/logo_kiddz2.png';

function MobileNav(props){       
    return (
        <div className={"sidenav-container" + (props.sidebarOpen ? " active": "")}>
            <div className="nav-close" onClick={() => props.setSidebarDisplay(false)}><span className="close-nav" /></div>
            <div className="sidenav-section">
                <NavLink className="sidenav-link" activeClassName="nav-sel" to="/leagueinfo" onClick={() => props.setSidebarDisplay(false)}>League Info</NavLink>
                <NavLink className="sidenav-link" activeClassName="nav-sel" to="/schedule" onClick={() => props.setSidebarDisplay(false)}>Schedules</NavLink>
                <NavLink className="sidenav-link" activeClassName="nav-sel" to="/stats" onClick={() => props.setSidebarDisplay(false)}>Stats</NavLink>
                <NavLink className="sidenav-link" activeClassName="nav-sel" to="/playerspotlight" onClick={() => props.setSidebarDisplay(false)}>Player Spotlight</NavLink>
                <NavLink className="sidenav-link" activeClassName="nav-sel" to="/rules" onClick={() => props.setSidebarDisplay(false)}>Rules</NavLink>
                <NavLink className="sidenav-link" activeClassName="nav-sel" to="/media" onClick={() => props.setSidebarDisplay(false)}>Media</NavLink>
                <a href="https://account.venmo.com/u/leeleeff" target="_blank" className="link-btn c2alt" onClick={() => props.setSidebarDisplay(false)}>Donate</a>
            </div>
        </div>
    );
}

class Layout1 extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            copyrightDate: "2020",sidebarOpen: false, navChange: false
        }

        this.setSidebarDisplay = this.setSidebarDisplay.bind(this);
        this.getCopyrightDate = this.getCopyrightDate.bind(this);    
        this.listenToScroll = this.listenToScroll.bind(this); 
    }

    setSidebarDisplay(status) {
        document.body.classList.toggle('noscroll', status);
        this.setState({ sidebarOpen: status });
    }

    getCopyrightDate(){
        var self = this;
        try {
            axios.get(this.props.rootPath + "/api/getCopyrightDate", {'Content-Type': 'application/json'})
            .then(function(response) {
                if(!response.data.error){
                   self.setState({ copyrightDate: response.data.results });
                }
            });   
        }
        catch(ex){
            console.log("Error Retrieving Copyright Date: ",ex);
        }
    }

    listenToScroll() {
        try {
            if(window){
                //const height = (window.innerHeight/3);
                const height = (window.innerHeight * .10);

                if(window.scrollY <= height && this.state.navChange) { 
                    this.setState({ navChange: false });                    
                }
                else if(window.scrollY > height && !this.state.navChange){
                    this.setState({navChange: true });
                }
            }
        }
        catch(ex){
            //console.log("[Error] listening to Scroll: ", ex);
        }
    }

    componentDidMount(){ 
        this.getCopyrightDate();
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    } 

    render(){  
        return(
            <div className="leelee-body">
                {/* Header */}
                <MobileNav setSidebarDisplay={this.setSidebarDisplay} sidebarOpen={this.state.sidebarOpen}/>
                <nav className={"navbar navbar-expand-lg" + (this.state.navChange ? " back":"")}>
                    <Link className="navbar-brand" to="/">
                        <div className="initial-wrapper">
                            <img src={logo_c} alt="Lee Lee Flag Football Logo"/>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={() => this.setSidebarDisplay(true)}>
                        <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
                    </button>

                    <div className="collapse navbar-collapse">
                        <div className="collapse-container">
                            <div className='page-nav-container'>
                                <NavLink className="nav-item nav-link" activeClassName="nav-sel" to="/leagueinfo">League Info</NavLink>
                                <NavLink className="nav-item nav-link" activeClassName="nav-sel" to="/schedule">Schedules</NavLink>
                                <NavLink className="nav-item nav-link" activeClassName="nav-sel" to="/stats">Stats</NavLink>
                                <NavLink className="nav-item nav-link" activeClassName="nav-sel" to="/rules">Rules</NavLink>
                                <NavLink className="nav-item nav-link" activeClassName="nav-sel" to="/media">Media</NavLink>
                            </div>
                            <a href="https://account.venmo.com/u/leeleeff" target="_blank" className="link-btn c2alt">Donate</a>                   
                        </div>
                    </div>
                </nav>
                {/* Body */}                    
                {this.props.children}

                {/* Footer */}
                <div className="footer">
                    <div className="footer-section">
                        <div className="footer-title main">Lee Lee</div>
                        <div className="footer-subtitle plus">KIDDZ LEAGUE</div>

                        <div className="footer-title">Contact Us</div>
                        <p className="item"><i className="far fa-envelope"/> <span>leeleekiddz@leeleeff.com</span></p>

                        <div className="item icon-section">
                            <a href="https://www.instagram.com/leeleekiddz/" target="_blank"><i className="fab fa-instagram"/></a>
                            <a href="https://www.facebook.com/profile.php?id=100092479205096/" target="_blank"><i className="fab fa-facebook"/></a>
                            <a href="https://www.youtube.com/channel/UCJmXEwpe1vFAmsuuQT9onwQ" target="_blank"><i className="fab fa-youtube"/></a>
                        </div>
                    </div>

                    <div className="footer-section ctr">                            
                        <img src={logo_c} alt="Lee Lee Flag Football Logo"/>
                    </div>

                    <div className="footer-section split">
                        <div className="footer-title">Site Resources</div>
                        <div className="link-column">
                            <Link className="item item-link" to="/leagueinfo">League Info</Link>
                            <Link className="item item-link" to="/schedule">League Schedules</Link>
                            <Link className="item item-link" to="/playerspotlight">Player Spotlight</Link>
                            <Link className="item item-link" to="/stattracker">Stat Admin</Link>
                        </div>

                        <div className="link-column">
                            <Link className="item item-link" to="/stats">Stats</Link>                            
                            <Link className="item item-link" to="/media">Media</Link>
                            <Link className="item item-link" to="/rules">Rules</Link>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <i className="far fa-copyright"/>
                    <span>{this.state.copyrightDate}. Lee Lee Kiddz. All Rights Reserved.</span>
                </div>
            </div>
        );
    }
}
export default Layout1;