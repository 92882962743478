import React, { Component } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import LoadSpinner from '../shared/loadSpinner';

/* Images */
import back from '../../assets/imgs/media.jpg'
const imgList = {
    "tall": ["/imgs/kiddz_site/default5.jpg","/imgs/kiddz_site/default6.jpg", "/imgs/kiddz_site/default7.jpg"],
    "wide":[
        "/imgs/kiddz_site/default8.jpg", "/imgs/kiddz_site/default9.jpg", "/imgs/kiddz_site/default10.jpg",
        "/imgs/kiddz_site/default3.jpg", "/imgs/kiddz_site/default4.jpg"
    ]
}


class Media extends Component{
    constructor(props) {
        super(props);
        this.state = {
            imgItem1: imgList.tall[0], imgItem2: imgList.wide[0], 
            imgItem3: imgList.wide[1], galleryList:[], loading: false
        }

        this.setRandomPhoto = this.setRandomPhoto.bind(this);
        this.loadPageData = this.loadPageData.bind(this);
    }

    setRandomPhoto(){
        try {
            const rndInt = Math.floor(Math.random() * imgList.tall.length);
            const rndInt2 = Math.floor(Math.random() * imgList.wide.length);
            const rndInt3 = Math.floor(Math.random() * imgList.wide.length);

            this.setState({ imgItem1: imgList.tall[rndInt], imgItem2: imgList.wide[rndInt2], imgItem3: imgList.wide[rndInt3] });
        }
        catch(ex){
            console.log("[Error] Setting Random Photo: ",ex);
        }
    }

    loadPageData(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { url:"stories/gallery" };
                axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Media Page Data (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.setState({ galleryList: response.data.results });
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("Error Loading Media Page Data: ", ex);
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0); 
        document.title = "League Media";
        this.setRandomPhoto();
        this.loadPageData();

        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/media');
    }

    render(){  
        return(
            <div className="page-body gallery">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <section className="sub-landing">                   
                    <div className="sub-landing-text lrgTitle cw" data-text="Media">Media</div>
                    <div className="sub-landing-img"><img src={back} /></div>                        
                </section>

                <section className="text-section">
                    <h1>Gallery</h1>
                    <p>View league images and videos from previous tournaments and events, re-live the moments during our tournament or see content from others sharing the field.</p>
                </section>

                <section className="media-section">
                    <div className="img-grid">
                        <div className="img-col">
                            <div className="img-container"><img src={this.state.imgItem1} /></div>
                        </div>
                        <div className="img-col">
                            <div className="img-container"><img src={this.state.imgItem2} /></div>
                            <div className="img-container"><img src={this.state.imgItem3} /></div>
                        </div>
                    </div>
                    <div className="gallery-list">
                        {this.state.galleryList.map((gallery,i) =>
                            <a href={gallery.link.url} target="_blank" key={i} className="gallery-link">
                                <span>{gallery.title}</span>
                                <i className="fas fa-external-link-alt" />
                            </a>
                        )}
                    </div>
                </section>
            </div>
        );
    }
}
export default Media;