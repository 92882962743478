import React, { Component } from 'react';
import ReactGA from 'react-ga';

/* Components */
import TabSection from '../shared/tabSection';

/* Images */
import back from '../../assets/imgs/drafts.jpg'

class Draft extends Component{
    componentDidMount(){ 
        document.title = "League Drafts";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/drafts');
    }
    render(){  
        return(
            <div className="page-body tabpage">
                <section className="sub-landing">                   
                    <div className="sub-landing-text lrgTitle cw" data-text="Drafts">League Drafts</div>
                    <div className="sub-landing-img"><img src={back} /></div>                        
                </section>
                <section className="tab-container">
                    <TabSection selected="draft" rootPath={this.props.rootPath}/>
                </section>
            </div>
        );
    }
}
export default Draft;