import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) { return -1; }
    if (b[orderBy] > a[orderBy]) { return 1; }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, deleteBtn } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align='left' padding='normal' sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className="visually-hidden">
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

          {deleteBtn == true && <TableCell align='right' padding='normal'>Remove</TableCell>}
        </TableRow>
      </TableHead>
    );
}

class CustomTable extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 0, order: 'asc', orderBy: null, rowsPerPage: 10
        }

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.getCellView = this.getCellView.bind(this);
    }

    handleChangePage(event, newPage){ this.setState({ page: newPage }); }
    
    handleRequestSort(event, property){
        try {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';

            this.setOrder(isAsc ? 'desc' : 'asc');
            this.setOrderBy(property);
        }
        catch(ex){
            console.log("Handling Request Sort: ",ex);
        }
    }

    setOrder(newOrder){ this.setState({ order: newOrder }); }
    setOrderBy(newOrderBy){ this.setState({ orderBy: newOrderBy }); }

    getCellView(type, row){
        try {
            if(row){               
                switch(type){
                    case "color":
                        return(
                            <div className="table-color" style={{ background: "rgba("+row[type].r+","+row[type].g+","+row[type].b+",1)" }} /> 
                        );
                    case "photo":
                        return(
                            <div className="table-photo">
                                {("photo" in row && row.photo ? <img src={row.photo} /> : <i className="far fa-user" /> )}
                            </div>
                        );
                    case "highlights":
                        if(row.highlights) {
                            return(
                                <a href={row.highlights} target="_blank" className="player-highlights"><i className="fas fa-football-ball"/> Player Highlights</a>
                            );
                        }
                        else { return(<span/>); }
                    default:
                        return(<span>{row[type]}</span>);
                }
            }
        }
        catch(ex){
            console.log("[Error] Getting Cell View: ",ex);
        }
    }
    componentDidMount(){ }
    
    render(){  
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.rows.length - this.state.page * this.state.rowsPerPage);
        
        return(
            <div className="custom-table-container">
                <Paper className="table-paper">
                    <TableContainer>
                        <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                            <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} headCells={this.props.headCells} deleteBtn={this.props.deleteBtn}/>
                            <TableBody>
                                {stableSort(this.props.rows, getComparator(this.state.order, this.state.orderBy))
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((row, i) => {
                                        return (
                                            <TableRow key={i}>
                                                {this.props.headCells.map((cell,j) => 
                                                    <TableCell align="left" key={j}>
                                                        {this.getCellView(cell.id, row)}                                                        
                                                    </TableCell>
                                                )}

                                                {(this.props.deleteBtn) && <TableCell align="right"> 
                                                    {(row._id && !row.laId) && <i className="fas fa-user-times" onClick={()=> this.props.deleteAction(row)}/> }
                                                </TableCell>}
                                            </TableRow>
                                        );
                                })}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 25 * emptyRows }}>
                                        <TableCell colSpan={(this.props.headCells ? this.props.headCells.length + (this.props.deleteBtn ? 1 : 0) : 0)} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination component="div"
                        count={this.props.rows.length} rowsPerPage={this.state.rowsPerPage} rowsPerPageOptions={[10]}
                        page={this.state.page} onPageChange={this.handleChangePage} labelRowsPerPage='' />
                </Paper>
            </div>
        );
    }
}

export default CustomTable;