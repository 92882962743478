import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { rootPath, mySessKey } from '../../shared/utils';
import LoadSpinner from '../../shared/loadSpinner';

const default_merchant_toggle = {
    key:"", name:"", 
    merchant_info:{
        uniform_invoice_title:"",  league_invoice_title:"", 
        uniform_invoice_sub_text:"",  league_invoice_sub_text:"",
        uniform_invoice_default_logo: false, league_invoice_default_logo: false
    }
},
register_toggle_fields = [
    { "title":"Toggle Title", "name":"name", "sz":10, "type":"text" },
    { 
        "title":"Uniform Invoice Header", "sz":5, "type":"input-section" ,
        "name":["uniform_invoice_title", "uniform_invoice_sub_text","uniform_invoice_default_logo"], 
        "placeholders":[
            "Please Enter Title For This Invoice", 
            `Please Enter Address Or Subtitle, \nFormat each line accordingly`
        ]         
    },
    { 
        "title":"League Invoice Header", "sz":5, "type":"input-section",
        "name":["league_invoice_title", "league_invoice_sub_text","league_invoice_default_logo"], 
        "placeholders":[
            "Please Enter Title For This Invoice", 
            `Please Enter Address Or Subtitle, \nFormat each line accordingly`
        ]         
    }
];

class AdminRegToggleTool extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false, 
            merchant_toggle_list:[],
            sel_merchant_toggle:"", 
            merchant_toggle: JSON.parse(JSON.stringify(default_merchant_toggle))
        }

        this.saveToggle = this.saveToggle.bind(this);
        this.deleteToggle = this.deleteToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.getToggleList = this.getToggleList.bind(this);
        this.addressItemField = this.addressItemField.bind(this);
    }

    saveToggle(){ 
        let self = this;
        try {
            if(this.state?.merchant_toggle?.name?.length <= 0){
                toast.warning(`Please make sure the toggle has a name`, { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                    draggable: true, progress: undefined, theme: "dark", });
            }
            else {
                this.setState({ loading: true}, ()=>{
                    let postData = { ...self.state.merchant_toggle },
                        token = localStorage.getItem(mySessKey);

                    axios.post(`${rootPath}/v2/api/merchant`, postData, { headers: {'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error || !response.data?.results){
                                console.log(" [Error] Saving/Updating Toggle(E1): ", response.data.error);
                                toast.error(`${response.data.error}`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                            }
                            else {
                                toast.success(`Successfully Saved/Updated Toggle`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                                self.getToggleList();
                                self.setState({ merchant_toggle: JSON.parse(JSON.stringify(default_merchant_toggle)) });
                            } 
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`Error with Sign-up: ${ex}`);
            toast.error("Sorry, There was an issue attempting to Save/Update Toggle [Please Contact Site Admin]", { position: "top-right",
                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                draggable: true, progress: undefined, theme: "dark", });
        }
    }

    deleteToggle(){ 
        let self = this;
        try {
            if(confirm("Are you sure you want to delete this toggle?")){
                this.setState({ loading: true}, ()=> {
                    let postData = { ...self.state.merchant_toggle, delete_toggle: true },
                        token = localStorage.getItem(mySessKey);
                    axios.post(`${rootPath}/v2/api/merchant`, postData, { headers: {'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error || !response.data?.results){
                                console.log(" [Error] Deleting Toggle(E1): ", response.data.error);
                                toast.error(`${response.data.error}`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                            }
                            else {
                                toast.success(`Successfully Delete Toggle`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                                self.getToggleList();
                                self.setState({ merchant_toggle: JSON.parse(JSON.stringify(default_merchant_toggle)) });
                            } 
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`Error with Sign-up: ${ex}`);
            toast.error("Sorry, There was an issue attempting to Save/Update Toggle [Please Contact Site Admin]", { position: "top-right",
                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                draggable: true, progress: undefined, theme: "dark", });
        }
    }
    
    handleChange(e) { 
        try {
            let name = e.target.name;
            
            let update_form = {[name]: e.target.value };

            if(name === "sel_merchant_toggle") {
                let idx = parseInt(e.target.value);
                update_form["merchant_toggle"] = (idx < 0 ? 
                    JSON.parse(JSON.stringify(default_merchant_toggle)) : 
                    JSON.parse(JSON.stringify(this.state.merchant_toggle_list[idx]))
                );
            }

            this.setState({ ...update_form });
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }
    
    handleFormChange(e, type){
        try {
            let tmpData = {...this.state.merchant_toggle }, name = e.target.name; 

            if(type?.length == 0){
                tmpData[name] = e.target.value;
            }
            else {
                tmpData[type][name] = e.target.type === 'toggle' ? !tmpData[type][name] : e.target.value;
            }

            this.setState({ merchant_toggle: tmpData });
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    getToggleList() { 
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                axios.get(`${rootPath}/v2/api/merchant`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(`[Error] Getting Toggles: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving toggle list [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            self.setState({ merchant_toggle_list: response.data.results });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Toggles (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Toggles: ${ex}`);
        }
    }

    addressItemField(item){
        try {
            switch(item.type){
                case "text":
                    return <input type="text" name={`${item.name}`} value={this.state.merchant_toggle?.[item.name]} onChange={(e)=> this.handleFormChange(e,"")} disabled={(this.state.sel_merchant_toggle.length > 0 ? "" : "disabled")}/>;
                case "input-section":
                    return <div className='input-section-container'>
                                <input type="text" placeholder={item?.placeholders[0]} name={`${item.name[0]}`} value={this.state.merchant_toggle?.merchant_info?.[item.name[0]]} onChange={(e)=> this.handleFormChange(e,"merchant_info")} disabled={(this.state.sel_merchant_toggle.length > 0 ? "" : "disabled")}/>
                                <div className='icon-selection-container'>
                                    <span>Show LeeLee Logo On Invoice(s)?</span>
                                    <div className={`selection-toggle ${(this.state.merchant_toggle?.merchant_info?.[item.name[2]] ? 'sel' : '')}`} onClick={(e)=> this.handleFormChange({"target":{ "name": item.name[2], "type":"toggle" }},"merchant_info")}/>
                                </div>
                                <textarea rows={3} placeholder={item?.placeholders[1]} name={`${item.name[1]}`} value={this.state.merchant_toggle?.merchant_info?.[item.name[1]]} onChange={(e)=> this.handleFormChange(e,"merchant_info")} disabled={(this.state.sel_merchant_toggle.length > 0 ? "" : "disabled")}/>
                            </div>;
                default:
                    return <></>;
            }
        }
        catch(ex){
            console.log(`[Error] building address Item Field`)
            return <></>
        }
    }

    componentDidMount(){ 
        this.getToggleList();
    }

    render(){
        return(
            <div className="admin-tool-container contacts">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className='store-config-container'>
                    <div className='title'>
                        <i className="fas fa-users" />
                        <span>Registration Toggles</span>
                    </div>
                </div>

                <div className='address-container'>
                    <div className='address-title'>
                        <h1>Toggle List</h1>
                        <div className='btn-list'>
                            <div className={`save-btn del ${this.state.sel_merchant_toggle.length > 0 && this.state.merchant_toggle?._id ? '': 'disabled'}`} onClick={this.deleteToggle}>Delete</div>
                            <div className={`save-btn ${this.state.sel_merchant_toggle.length > 0 ? '': 'disabled'}`} onClick={this.saveToggle}>Save</div>
                        </div>
                    </div>

                    <div className={`form-item sz-10 spacer`}>
                        <span className='title'>Select Location Toggle</span>
                        <select name="sel_merchant_toggle" value={this.state.sel_merchant_toggle} onChange={this.handleChange}>
                            <option hidden>Select A Location Toggle</option>
                            <option value={-1}>Add New Toggle</option>
                            {this.state?.merchant_toggle_list?.map((op, l) =>
                                <option value={l} key={l}>{op.name}</option>
                            )}
                        </select>
                    </div>

                    {register_toggle_fields.map((item,i) =>
                        <div className={`form-item sz-${item.sz}`} key={i}>
                            <span className='title'>{item.title}</span>
                            {this.addressItemField(item)}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default AdminRegToggleTool;