import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadSpinner from '../shared/loadSpinner';
import StoreSignin from './leagueStore/signin';
import Store from './leagueStore/store';
import StoreItem from './leagueStore/storeItem';
import Invoices from './leagueStore/invoice';
import Admin from './leagueStore/admin';
import Quotes from './leagueStore/quotes';

import { mySessKey, storeKey, parseToken } from '../shared/utils';

class LeagueStore extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading:false, activeUser:null, navSel:"", 
            invoiceType: null, invoiceList:[], activeItem:null,
            edit_item_loc: -1
        }

        this.userHandler = this.userHandler.bind(this);
        this.signOut = this.signOut.bind(this);
        this.checkToken = this.checkToken.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.setActiveItem = this.setActiveItem.bind(this);
        this.addToInvoice = this.addToInvoice.bind(this);
        this.editInvoiceItem = this.editInvoiceItem.bind(this);
        this.removeInvoiceItem = this.removeInvoiceItem.bind(this);
        this.resetInvoice = this.resetInvoice.bind(this);
        this.loadInvoiceList = this.loadInvoiceList.bind(this);
    }

    userHandler(newToken, cb) {
        let self = this;
        try {   
            var usr = null;
            if(newToken){
                var { localUser, isExpired } = parseToken(newToken);                
                if(!isExpired) { usr = localUser; }
            }   

            self.setState({ activeUser: usr },() => {
                if(!self.state.activeUser){
                    localStorage.removeItem(mySessKey); cb();
                    self.setState({navSel: "signin"});
                } 
                else {
                    localStorage.setItem(mySessKey, newToken); cb();
                    self.setState({navSel: (self.state.activeUser.admin ? "admin" : "quotes")});
                }
            });          
        }
        catch(ex){
            console.log("Error with user handler: ", ex);
        }
    }

    signOut(){
        let self = this;
        try {
            if(confirm("Are you sure you want to sign-out?")){
                this.userHandler(null, function(){});
            }
        }
        catch(ex){
            console.log("[Error] Signing Out: ", ex);
        }
    }

    checkToken() {
        try {
            const self = this;
            let sessionInfo = localStorage.getItem(mySessKey);

            this.setState({ loading: true }, ()=>{
                if(sessionInfo && sessionInfo[0] === "{") {
                    localStorage.removeItem(mySessKey);
                    self.setState({ activeUser: null, loading: false });
                }
                else if(sessionInfo){
                    var { localUser, isExpired } = parseToken(sessionInfo);
    
                    if(!isExpired){
                        self.setState({ activeUser: localUser, navSel:(localUser.admin ? "admin" : "quotes"), loading: false });
                    }
                    else {
                        localStorage.removeItem(mySessKey);
                        self.setState({ activeUser: null, navSel:"signin", loading: false });
                    }
                }
                else {
                    self.setState({ navSel:"signin", loading: false });
                }
            });
        }
        catch(ex) {
            console.log("[Error] Checking Token: ", ex);
            self.setState({ navSel:"signin", loading: false });
        }
    }

    changeTab(newTab){
        try {
            this.setState({ navSel: newTab, activeItem: null });
        }
        catch(ex){
            console.log(`[Error] changing tab: ${ex}`);
        }
    }

    setActiveItem(item){
        try {
            this.setState({ activeItem: (item === null ? item : { ...item })});
        }
        catch(ex){
            console.log(`Setting Active Item: ${ex}`);
        }
    }

    addToInvoice(personalized_items, detail_items, jersey_info){
        let self = this;
        try {
            let item_type = this.state.activeItem.type;
            if((this.state.invoiceType === null || this.state.invoiceType === item_type) || 
                confirm(`Adding this item will clear your current ${this.state.invoiceType} order, and start a new ${item_type} order.  Is this ok?`)){
                
                let tmp_item = {
                    ...this.state.activeItem, item_details: personalized_items, 
                    detail_items: detail_items, jersey_info: jersey_info 
                };
                
                let tmp_invoice = [];
                if(this.state.edit_item_loc >= 0){
                    tmp_invoice = [...this.state.invoiceList];
                    tmp_invoice[this.state.edit_item_loc] = tmp_item;
                }
                else {
                    tmp_invoice = ((this.state.invoiceType === null || this.state.invoiceType === item_type) ? [...this.state.invoiceList, tmp_item] : [tmp_item]);
                }

                this.setState({invoiceType: item_type, activeItem: null, invoiceList: tmp_invoice, edit_item_loc: -1},()=>{
                    localStorage.setItem(storeKey, JSON.stringify({ invoiceType: self.state.invoiceType, invoiceList: self.state.invoiceList}));
                    toast.success(`Added Item To Invoice`, { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                        draggable: true, progress: undefined, theme: "dark" });
                });
            }
        }
        catch(ex){
            console.log(`Error Adding To Invoice: ${ex}`);
        }
    }

    editInvoiceItem(loc, item){
        try {
            this.setState({ navSel:`${this.state.invoiceType}-store`, activeItem: { ...item }, edit_item_loc: loc });
        }
        catch(ex){
            console.log(`Setting Active Edit Item: ${ex}`);
        }
    }

    removeInvoiceItem(loc, cb){
        let self = this;
        try {
            if(confirm("You will remove this item from the current invoice?")){
                let tmp_invoice = [...this.state.invoiceList];
                tmp_invoice.splice(loc,1);

                let item_type = tmp_invoice.length > 0 ? this.state.invoiceType : null;

                this.setState({ invoiceType: item_type, invoiceList: tmp_invoice, edit_item_loc: -1},()=>{
                    localStorage.setItem(storeKey, JSON.stringify({ invoiceType: self.state.invoiceType, invoiceList: self.state.invoiceList}));
                    cb();
                    toast.success(`Removed Item From Invoice`, { position: "top-right",
                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                        draggable: true, progress: undefined, theme: "dark" });
                });
            }
        }
        catch(ex){
            console.log(`Removing Item: ${ex}`);
        } 
    }

    resetInvoice(){
        try {
            this.setState({ 
                navSel:"quotes", invoiceType: null, invoiceList:[], 
                activeItem:null, edit_item_loc: -1 
            });
            localStorage.removeItem(storeKey);
        }
        catch(ex){
            console.log(`Resetting Invoice: ${ex}`);
        }
    }

    loadInvoiceList(){
        try {
            let cacheInvoice = localStorage.getItem(storeKey);
            if(cacheInvoice?.length > 0){
                let tmp_invoice = JSON.parse(cacheInvoice);
                this.setState({ invoiceList: tmp_invoice.invoiceList, invoiceType: tmp_invoice.invoiceType });
            }
        }
        catch(ex){
            console.log(`Error Loading Invoice List: ${ex}`);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Lee Lee Kidz League Store";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/leaguestore');
        this.checkToken();
        this.loadInvoiceList();
    }

    render(){  
        return(
            <div className="league-store-container">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <ToastContainer />

                <div className="layout-header">
                    <div className='store-header-base'>
                        <h1>League Store</h1>
                    </div>

                    <div className='store-header-nav'>
                        {this.state.activeUser &&
                            <>
                                <div className={`store-nav-link ${this.state.navSel === "league-store" ? 'sel' : ''}`} onClick={()=> this.changeTab('league-store')}>Leagues</div>
                                <div className={`store-nav-link ${this.state.navSel === "uniform-store" ? 'sel' : ''}`} onClick={()=> this.changeTab('uniform-store')}>Jerseys</div>
                                <div className={`store-nav-link ${this.state.navSel === "quotes" ? 'sel' : ''}`} onClick={()=> this.changeTab('quotes')}>Quotes</div>

                                {this.state.activeUser.admin && <div className={`store-nav-link admin ${this.state.navSel === "admin" ? 'sel' : ''}`} onClick={()=> this.changeTab('admin')}>Admin Tool</div>}
                            </>
                        }

                        <Link className="store-nav-link divider" to="/">
                            <div className='store-link-container'><i className="fas fa-home"/> <span>Home</span></div>
                        </Link>

                        {this.state.activeUser &&
                            <>
                                <div className='store-nav-link' onClick={this.signOut}><i className="fas fa-sign-out-alt" /></div>
                            </>
                        }
                    </div>
                </div>

                <div className='store-body'>
                    {this.state.navSel === "signin" && <StoreSignin userHandler={this.userHandler}/>}

                    {/* Uniform Store */}
                    {(this.state.navSel === "uniform-store" && this.state.activeItem === null) && <Store type="uniform" setActiveItem={this.setActiveItem} invoiceList={this.state.invoiceList} viewInvoice={() => this.changeTab('orderDetails')} />}
                    {(this.state.navSel === "uniform-store" && this.state.activeItem?.type === "uniform") && <StoreItem type="uniform"  item={this.state.activeItem} setActiveItem={this.setActiveItem} addToInvoice={this.addToInvoice} />}

                    {/* League Store */}
                    {(this.state.navSel === "league-store" && this.state.activeItem === null) && <Store type="league" setActiveItem={this.setActiveItem} invoiceList={this.state.invoiceList} viewInvoice={()=> this.changeTab('orderDetails')} />}                   
                    {(this.state.navSel === "league-store" && this.state.activeItem?.type === "league") && <StoreItem type="league" item={this.state.activeItem} setActiveItem={this.setActiveItem} addToInvoice={this.addToInvoice} />}

                    {/* Order Details */}
                    {this.state.navSel === "orderDetails" && <Invoices invoiceList={this.state.invoiceList} invoiceType={this.state.invoiceType} editInvoiceItem={this.editInvoiceItem} removeInvoiceItem={this.removeInvoiceItem} resetInvoice={this.resetInvoice} />}
                    
                    {/* Order Quotes */}
                    {this.state.navSel === "quotes" && <Quotes activeUser={this.state.activeUser} />}

                    {/* Admin */}
                    {this.state.navSel === "admin" && <Admin activeUser={this.state.activeUser}  />}
                </div>
            </div>
        );
    }
}
export default LeagueStore;