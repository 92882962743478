import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Resizer from "react-image-file-resizer";
import axios from 'axios';
import { toast } from 'react-toastify';

import { currencyFormat, rootPath, mySessKey } from '../../shared/utils';

import LoadSpinner from '../../shared/loadSpinner';
import OptionDropper from './optionDropper';

const MAX_IMG_COUNT = 1, store_config = {
    "uniform":{ 
        title: "Uniforms",
        data_field_values:{ min_amount: "", list_title: "", option_list: [] },
        data_fields: [
            { title:"Min # of Store Items", name:"min_amount", type:"number", sz: 2},
            { title:"Options List Name", name:"list_title", type:"text", sz: 5}
        ]
    },
    "league":{ 
        title: "Leagues",
        data_field_values:{ 
            min_amount: "", list_title: "", start_dt: "", end_dt:"", 
            add_player_price:0, option_list: [] 
        },
        data_fields: [
            { title:"Min # of Store Items", name:"min_amount", type:"number", sz: 2},
            { title:"Options List Name", name:"list_title", type:"text", sz: 5}
        ]
    }
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

class AdminStoreTool extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading: false,
            title: "", data_fields:[], config_values:{},
            item_list:[], selected_item:{}
        }

        /* References */
        this.photoRef = React.createRef();

        /* Functions */
        this.onDragEnd = this.onDragEnd.bind(this);
        this.addOption = this.addOption.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.validateConfig = this.validateConfig.bind(this);
        this.validateStoreItem = this.validateStoreItem.bind(this);

        /* Store Items */
        this.selectItem = this.selectItem.bind(this);
        this.handleSelectedChange = this.handleSelectedChange.bind(this);
        this.handleSelectedToggle = this.handleSelectedToggle.bind(this);
        this.onSelDragEnd = this.onSelDragEnd.bind(this);
        this.addSelOption = this.addSelOption.bind(this);
        this.handleSelOptionChange = this.handleSelOptionChange.bind(this);
        this.addStoreItem = this.addStoreItem.bind(this);
        this.setImageFile = this.setImageFile.bind(this);

        /* Data Functions */
        this.getConfig = this.getConfig.bind(this);
        this.getStoreItems = this.getStoreItems.bind(this);

        this.saveConfig = this.saveConfig.bind(this);
        this.saveStoreItems = this.saveStoreItems.bind(this);
        this.deleteStoreItems = this.deleteStoreItems.bind(this);
    }

    onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
          return;
        }

        let tmp_config = this.state.config_values;
        if (source.droppableId === destination.droppableId) {
            const items = reorder(tmp_config.option_list, result.source.index, result.destination.index);
            this.setState({ config_values: { ...tmp_config, option_list: items } });
        }
        else {
            tmp_config.option_list.splice(source.index,1);
            this.setState({ config_values: { ...tmp_config } });
        }
    }

    addOption() {
        try {
            let tmp_config = this.state.config_values;

            tmp_config.option_list.unshift({id: uuidv4(), value: ""});
            this.setState({ config_values: { ...tmp_config } });
        }
        catch(ex){
            console.log(`Error Adding Option: ${ex}`);
        }
    }

    handleFormChange(e){
        try {
            let tmpData = {...this.state.config_values }, name = e.target.name;

            if(name in tmpData) {
                tmpData[name] = e.target.value;
                this.setState({ config_values: tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    handleOptionChange(e, i){
        try {
            let tmpData = {...this.state.config_values };
            tmpData.option_list[i].value = e.target.value;

            this.setState({ config_values: tmpData });
        }
        catch(ex){
            console.log("[Error] on option form change: ",ex);
        }
    }

    validateConfig(){
        let ret = [];
        try {
            let check_list = this.state.data_fields;

            for(let i=0; i < check_list?.length; i++){
                if(!this.state.config_values[check_list[i].name] || this.state.config_values[check_list[i].name].length <= 0){
                    ret.push(check_list[i].title);
                }
            }

            let option_list = this.state.config_values?.option_list;
            for(let j=0; j < option_list?.length; j++){
                if(option_list[j].value.length <=0 ){
                    ret.push("Options"); break;
                }
            }
        }
        catch(ex){
            console.log(`Error validating config: ${ex}`);
            ret.push("error");
        }
        return ret;
    }

    validateStoreItem(){
        let ret = [];
        try {
            let check_list = [
                { "name": "title", "title":"Title" }, { "name": "description", "title":"Description" },
                { "name": "item_price", "title":"Item Price" }, { "name": "min_amount", "title":"Min. Amount" }
            ];

            if(this.props.type == "league") {
                check_list.push({ "name": "start_dt", "title":"Start Date" });
                check_list.push({ "name": "end_dt", "title":"End Date" });
                check_list.push({ "name": "add_player_price", "title":"Additional Player Proce" });
            }

            for(let i=0; i < check_list?.length; i++){
                if(!this.state.selected_item[check_list[i].name] || this.state.selected_item[check_list[i].name].length <= 0){
                    ret.push(check_list[i].title);
                }
            }

            let option_list = this.state.config_values?.option_list;
            for(let j=0; j < option_list?.length; j++){
                if(option_list[j].value.length <=0 ){
                    ret.push("Options"); break;
                }
            }
        }
        catch(ex){
            console.log(`Error validating Store item: ${ex}`);
            ret.push("error");
        }
        return ret;
    }

    /* Store Items */
    selectItem(item){
        try{
            if(item._id){
                let tmp_item = JSON.parse(JSON.stringify(item));
                tmp_item.option_list = tmp_item.option_list.map((option) => {
                    return {id: uuidv4(), value: option};
                });

                this.setState({ selected_item: tmp_item });
            }
        }
        catch(ex){
            console.log(`[Error] Selecting Item: ${ex}`);
        }
    }

    handleSelectedChange(e){
        try {
            let tmpData = {...this.state.selected_item }, name = e.target.name;

           if(name === "img_list"){
                if(tmpData.img_list){
                    tmpData[name].unshift(e.target.value);
                }
                else {
                    tmpData[name]= [e.target.value];
                }
                
                if(tmpData[name].length > MAX_IMG_COUNT){
                    tmpData[name].pop();
                }
            }
            else {
                tmpData[name] = e.target.value;
            }
                
            this.setState({ selected_item: tmpData });
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    handleSelectedToggle(){
        try {
            let new_status = this.state.selected_item.can_design ? false : true;
            this.handleSelectedChange({ target: { name: "can_design", value: new_status }});
        }
        catch(ex){
            console.log(`Error Toggling Selected Item: ${ex}`);
        }
    }

    onSelDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
          return;
        }

        let tmp_config = this.state.selected_item;
        if (source.droppableId === destination.droppableId) {
            const items = reorder(tmp_config.option_list, result.source.index, result.destination.index);
            this.setState({ selected_item: { ...tmp_config, option_list: items } });
        }
        else {
            tmp_config.option_list.splice(source.index,1);
            this.setState({ selected_item: { ...tmp_config } });
        }
    }

    addSelOption() {
        try {
            let tmp_config = this.state.selected_item;

            tmp_config.option_list.unshift({id: uuidv4(), value: ""});
            this.setState({ selected_item: { ...tmp_config } });
        }
        catch(ex){
            console.log(`Error Adding Option: ${ex}`);
        }
    }

    handleSelOptionChange(e, i){
        try {
            let tmpData = {...this.state.selected_item };
            tmpData.option_list[i].value = e.target.value;

            this.setState({ selected_item: tmpData });
        }
        catch(ex){
            console.log("[Error] on option form change: ",ex);
        }
    }

    addStoreItem(){
        try {
            let tmp_config = { 
                title: "", description:"", img_list:[], type: this.props.type, 
                option_list: [...this.state.config_values.option_list], list_title: this.state.config_values.list_title, 
                item_price:0, min_amount:1, start_dt:"", end_dt:"", add_player_price:0,
                can_design: (this.props.type === "uniform"), config_id: this.state.config_values?._id
            };

            this.setState({ selected_item: tmp_config });
        }
        catch(ex){
            console.log(`Error Adding Store Option: ${ex}`);
        }
    }

    setImageFile(e){
        let self = this;
        try {
            if (e.target.files[0]) {
                Resizer.imageFileResizer(e.target.files[0],
                    400, 400, "JPEG", 50, 0,
                    (uri) => { 
                        self.handleSelectedChange({ target:{ name: "img_list", value: uri}});
                        // Clear Target Value
                        e.target.value = null;
                    },
                    "base64", 200, 200
                );
            }
        }
        catch(ex){
            console.log(`Error setting image file: ${ex}`);
        }
    }

    /* Data Functions */
    getConfig(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                let config_name = this.props.type.toLowerCase();
                axios.get(`${rootPath}/v2/api/purchase/config?name=${config_name}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting config data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving config info [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set Config Data
                            let res_data = response.data.results[0];
                            res_data.option_list = res_data.option_list.map((option) => {
                                return {id: uuidv4(), value: option};
                            });

                            // Set State
                            self.setState({ config_values: res_data });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Config (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Config: ${ex}`);
        }
    }

    getStoreItems(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                let config_name = this.props.type.toLowerCase();
                axios.get(`${rootPath}/v2/api/purchase/content?type=${config_name}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting config data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving store items [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            self.setState({ item_list: response.data.results });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Store Items (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Store Items: ${ex}`);
        }
    }

    saveConfig(){
        let self = this;
        try {
            if(this.validateConfig().length > 0) {
                toast.warning("Please ensure you have completed all of the config data fields.", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                    draggable: true, progress: undefined, theme: "dark" });
            }
            else {
                this.setState({ loading: true}, ()=>{ 
                    let id = this.state.config_values?._id, token= localStorage.getItem(mySessKey), 
                        postData = { ...this.state.config_values };

                    // Re-format Option List
                    postData.option_list = postData.option_list.map((option) =>{ return option.value; });

                    if(!id){
                        toast.error("Sorry, unable to update this config info [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
                    }
                    else {
                        axios.post(`${rootPath}/v2/api/purchase/config/${id}`, postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                            .then(function(response) {
                                if(response.data.error || !response.data.results){
                                    console.log(`[Error] Getting config data: ${response.data.error}`);
                                    toast.error("Sorry, There was an issue updating this config info [Please Contact Site Admin]", { position: "top-right",
                                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                        draggable: true, progress: undefined, theme: "dark" });
                                }
                                else { 
                                    //self.getConfig(); 
                                    toast.success("Successfully updated config", { position: "top-right",
                                        autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                        draggable: true, progress: undefined, theme: "dark" });
                                }
                            })
                            .catch(function (error) {
                                console.log("[Error] Saving Config (E2)",error);
                            })
                            .then(function(){
                                self.setState({ loading: false });
                            }); 
                    }
                });
            }
        }
        catch(ex){
            console.log(`[Error] Getting Config: ${ex}`);
        }
    }

    saveStoreItems() {
        let self = this;
        try {
            if(this.validateStoreItem().length > 0) {
                toast.warning("Please ensure you have completed all of the store item data fields.", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                    draggable: true, progress: undefined, theme: "dark" });
            }
            else {
                this.setState({ loading: true}, ()=>{ 
                    let id = this.state.selected_item?._id, token= localStorage.getItem(mySessKey), 
                        postData = { ...this.state.selected_item };

                    // Re-format Option List
                    postData.option_list = postData.option_list.map((option) =>{ return option.value; });

                    axios.post(`${rootPath}/v2/api/purchase/content${id ?`/${id}`:''}`, postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log(`[Error] Saving store item: ${response.data.error}`);
                                toast.error(`Sorry, There was an issue saving this store item [Please Contact Site Admin]`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark" });
                            }
                            else { 
                                self.getStoreItems();
                                self.setState({ selected_item: {} });

                                toast.success(`Successfully saved store item`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark" });
                            }
                        })
                        .catch(function (error) {
                            console.log("[Error] Saving store item (E2)",error);
                            toast.error(`Sorry, There was an issue ${id ? 'updating':'adding'} this store item [Please Contact Site Admin]`, { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        })
                        .then(function(){
                            self.setState({ loading: false });
                        });                     
                });
            }
        }
        catch(ex){
            console.log(`[Error] Saving Store Item: ${ex}`);
        }
    }

    deleteStoreItems(id) {
        let self = this;
        try {
            if(confirm("Are you sure you want to delete this store item?")) {
                this.setState({ loading: true}, ()=>{ 
                    let token = localStorage.getItem(mySessKey);

                    axios.get(`${rootPath}/v2/api/purchase/d/content/${id}`, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Deleting store item: ${response.data.error}`);
                            toast.error(`Sorry, There was an issue deleting this store item [Please Contact Site Admin]`, { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else { 
                            self.getStoreItems();
                            self.setState({ selected_item: {} });
                            toast.success(`Successfully deleted store item`, { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Deleting store item (E2)",error);
                        toast.error(`Sorry, There was an issue deleting this store item [Please Contact Site Admin]`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    });                     
                });
            }
        }
        catch(ex){
            console.log(`[Error] Deleting Store Item: ${ex}`);
        }
    }

    /* Init */
    componentDidMount(){ 
        // Get Config Data Fields
        let self=this, store_config_vals = {...store_config[this.props.type].data_field_values };

        // Set Default States
        this.setState({ 
            title: store_config[this.props.type].title, 
            data_fields: store_config[this.props.type].data_fields,
            config_values: store_config_vals 
        }, ()=> {
            self.getConfig();
            self.getStoreItems();
        });
    }

    render(){  
        return(
            <div className="admin-tool-container store">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className='store-config-container'>
                    <div className='title'>
                        <i className="fas fa-cogs" />
                        <span>{this.state.title} Store Config</span>
                        <p>Update overall configurations for this store type, such as (minimum # of store pieces, personalization type, start date, etc...).</p>
                        
                        <div className={`config-save-btn ${this.validateConfig().length > 0 ? 'disabled' : ''}`} onClick={this.saveConfig}><i className="far fa-save" /> <span>Save Config</span></div>
                    </div>
            
                    <div className='data-field-container'>
                        {this.state.data_fields.map((field, i)=>
                            <div className={`form-item sz-${field.sz}`} key={i}>
                                <span className='title'>{field.title}</span>
                                <input type={field.type} name={field.name} value={this.state.config_values[field.name]} onChange={this.handleFormChange}/>
                            </div>
                        )}
                    </div>
                    <div className='config-option-container'>
                        <OptionDropper title={this.state.config_values?.list_title} full_version={true} option_list={this.state.config_values?.option_list} onDragEnd={this.onDragEnd} addOption={this.addOption} handleOptionChange={this.handleOptionChange} />
                    </div>
                </div>

                <div className='store-item-config-container'>
                    <div className='item-list-panel'>
                        <div className='list-title'>
                            <i className="fas fa-boxes"></i>
                            <span>{this.state.title} Store Items</span>
                        </div>
                        <div className='instructions'>
                            <span className='text'>Add, Edit, & Remove items that will be visible in this store.</span> 

                            <div className='item-btn' onClick={this.addStoreItem}>
                                <i className="fas fa-plus" /> 
                                <span>Add Item</span>
                            </div>  
                        </div>

                        <div className='item-list-container'>
                            {!(this.state.item_list && this.state.item_list?.length > 0) && <div className='empty-store'><span>Add your first store item</span></div>}
                            {this.state.item_list && this.state.item_list?.map((item,i) =>
                                <div className={`item-container ${this.state.selected_item?._id === item._id ? 'sel':''}`} key={i}>
                                    <div className='item-img-container'>
                                        {item.img_list?.length > 0 ? <img src={item.img_list[0]} /> : <></>}
                                    </div>
                                    <div className='item-details'>
                                        <span className='title'>{item.title}</span>
                                        <div className='price-details'>
                                            <span>Price: {currencyFormat(item.item_price)}</span>
                                            <span>Min. Amount: {item.min_amount}</span>
                                        </div>
                                    </div>

                                    <div className='item-btn-container'>
                                        <div className='item-btn' onClick={()=> this.selectItem(item)}>
                                            <i className="far fa-edit" />
                                            <span>Edit</span>
                                        </div>

                                        <div className='item-btn' onClick={()=> this.deleteStoreItems(item._id)}>
                                            <i className="far fa-trash-alt" />
                                            <span>Delete</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='item-edit-panel'>
                        {!("title" in this.state?.selected_item) ?
                            <div className='empty-panel'><span>Please select an item to begin updating.</span></div> :
                            <>  
                                <div className='close-panel' onClick={()=> { this.setState({ selected_item: {}}) }}><i className="fas fa-times" /></div>
                                <div className='title'>
                                    {"_id" in this.state?.selected_item ? "Updating item" : "Adding a new item"} 
                                </div>

                                <div className='item-core-details'>
                                    <div className='item-img-container'>
                                        {this.state?.selected_item.img_list?.length > 0 ? <img src={this.state?.selected_item.img_list[0]} /> : <></>}
                                        <div className='edit-tool' onClick={()=> this.photoRef.current.click() }><i className="far fa-edit" /></div>
                                        <input className='hiddenInput' type="file" accept="image/*" name="image" ref={this.photoRef} onChange={this.setImageFile} />
                                    </div>
                                    <div className='item-title-details'>
                                        <div className='form-item sz-10'>
                                            <span className='form-title'>Title</span>
                                            <input type="text" name="title" value={this.state?.selected_item.title} onChange={this.handleSelectedChange}/>
                                        </div>

                                        <div className='form-item sz-10'>
                                            <span className='form-title'>Description</span>
                                            <input type="text" name="description" value={this.state?.selected_item.description} onChange={this.handleSelectedChange}/>
                                        </div>
                                    </div>
                                </div>

                                <div className='item-core-details'>
                                    <div className='item-title-details'>
                                        <div className='form-item sz-2'>
                                            <span className='form-title'>Price</span>
                                            <input type="number" name="item_price" value={this.state?.selected_item.item_price} onChange={this.handleSelectedChange}/>
                                        </div>
                                        <div className='form-item sz-3'>
                                            <span className='form-title'>Min. Item Amount</span>
                                            <input type="number" name="min_amount" value={this.state?.selected_item.min_amount} onChange={this.handleSelectedChange}/>
                                        </div>

                                        <div className='form-item sz-3'>
                                            <span className='form-title'>Option List Name</span>
                                            <input type="text" name="list_title" value={this.state?.selected_item.list_title} onChange={this.handleSelectedChange}/>
                                        </div>

                                        <div className='form-item sz-2'>
                                            <span className='form-title'>Custom Design?</span>
                                            <div className={`toggle-btn ${this.state?.selected_item.can_design ? 'active' : ''}`} onClick={this.handleSelectedToggle} />
                                        </div>
                                    </div>
                                </div>

                                <div className='config-option-container'>
                                    <OptionDropper title={this.state?.selected_item.list_title} full_version={false} option_list={this.state.selected_item?.option_list} onDragEnd={this.onSelDragEnd} addOption={this.addSelOption} handleOptionChange={this.handleSelOptionChange} />
                                </div>

                                {this.props.type != "league" ? <></>:
                                    <div className='item-core-details'>
                                        <div className='item-title-details'>
                                            <div className='form-item sz-5'>
                                                <span className='form-title'>Start Date</span>
                                                <input type="date" name="start_dt" value={this.state?.selected_item.start_dt} onChange={this.handleSelectedChange}/>
                                            </div>
                                            <div className='form-item sz-5'>
                                                <span className='form-title'>End Date</span>
                                                <input type="date" name="end_dt" value={this.state?.selected_item.end_dt} onChange={this.handleSelectedChange}/>
                                            </div>

                                            <div className='form-item sz-5'>
                                                <span className='form-title'>Additional Player Price</span>
                                                <input type="number" min="0" name="add_player_price" value={this.state?.selected_item.add_player_price} onChange={this.handleSelectedChange}/>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='panel-btn-container'>
                                    <div className={`title-btn add ${this.validateStoreItem().length > 0 ? 'disabled' : ''}`} onClick={this.saveStoreItems}>
                                        <i className="far fa-save" /> <span>Save</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default AdminStoreTool;