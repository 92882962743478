import React, { Component } from 'react';
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import LoadSpinner from '../loadSpinner';

class DraftEditorInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, leagueRosterList:[],
            formData: {
                title:"", leagueType:"coed", dateTime: null,
                location:"", leagueRoster:"", draftType:"default", pickTime: 5
            }
        }   

        this.loadLeagueInfo = this.loadLeagueInfo.bind(this);
        this.loadUpdatedLeagueRoster = this.loadUpdatedLeagueRoster.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);

        this.formatDate = this.formatDate.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.getDraftInfo = this.getDraftInfo.bind(this);
    }

    loadLeagueInfo(){
        var self = this;
        try {
            var postData = { count: -1, type: "" };
            axios.post(self.props.rootPath + "/api/getLeagueEvents", postData, {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Getting League Event Data (E1): ", response.data.error);
                    }
                    else {
                        self.setState({ leagueRosterList: response.data.results });
                    }
                }); 
        }
        catch(ex){
            console.log("[Error] loading league info list: ",ex);
        } 
    }

    loadUpdatedLeagueRoster(callback){
        var self = this;
        try {           
            var token = localStorage.getItem(self.props.mySessKey);

            axios.get(self.props.rootPath + "/api/league/player/merge", {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){ 
                        console.log("[Error] Updating League Roster: ", response.data.error);
                    }
                }).then(function(){ callback(); });                          
        }
        catch(ex){
            console.log("[Error] Updating League Roster (E1): ", ex);
        }
    }

    onElementChange(e){
        try {
            var tmpData = this.state.formData;
            var name = e.target.name;

            if(name in tmpData) {
                if(e.target.type === "number"){
                    tmpData[name] = e.target.value.split('.').join("");
                }
                else {
                    tmpData[name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
                }
                this.setState({ formData:tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on element change: ",ex);
        }
    }

    onDateChange(e){
        try {
            var tmpData = this.state.formData;
            var name = "dateTime";

            if(name in tmpData) {               
                tmpData[name] = e;                
                this.setState({ formData:tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on date change: ",ex);
        }
    }

    formatDate(date){
        var ret = "";
        try {
            var d = new Date(date);
            ret = (d.getMonth()+1) + "-" + d.getDate() + "-" + d.getFullYear();
        }
        catch(ex){
            console.log("[Error] formatting date: ",ex);
        }

        return ret;
    }

    validateForm(){
        var ret = false;

        try{
            var tmpForm = this.state.formData;
            var missingData = [];

            if(tmpForm.title == null || tmpForm.title.length <= 0) { missingData.push("Title");}
            if(tmpForm.leagueType == null || tmpForm.leagueType.length <= 0) { missingData.push("League Type");}
            if(tmpForm.dateTime == null) { missingData.push("Date & Time");}
            if(tmpForm.draftType == null || tmpForm.draftType.length <= 0) { missingData.push("Draft Type");}
            if(tmpForm.pickTime == null || tmpForm.pickTime <= 0) { missingData.push("Pick Time");}
            if(tmpForm.pickTime > 10) { missingData.push("Pick Time lower than 10m");}

            if(missingData.length <= 0) { ret = true;}
            else {
                alert("Unable to Create This Draft Please Check: " + missingData.join(", "));
            }
        }
        catch(ex){
            console.log("[Error] Validating Form: ",ex);
        }

        return ret;
    }

    saveDraft(){
        var self = this;
        try{
            if(this.validateForm()){
                this.setState({ loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    var postData = { draft: {...self.state.formData }};
                    if(self.props.selectedDraftId.length >= 1) { postData.draft._id = self.props.selectedDraftId; }

                    axios.post(self.props.rootPath + "/api/draft/update", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Creating/Editing Draft (E1): ", response.data.error);
                                alert("Unable To Create/Editing Draft");
                            }
                            else if(response.data.results){
                                self.props.setDraftId(response.data.results, true);
                            }
                        })
                        .catch(function (error) {
                            console.log("[Error] Creating/Editing Draft (E2)",error);
                            alert("Unable To Create/Editing Draft");
                        })
                        .then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log("[Error] Saving Draft: ",ex);
        }
    }

    getDraftInfo(draftId){
        var self = this;
        try {
            const formFields = ["title", "leagueType", "dateTime","location", "leagueRoster", "draftType", "pickTime"];
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                axios.get(self.props.rootPath + "/api/draft/"+draftId, {headers: {'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Getting Draft Info (E1): ", response.data.error);
                    }
                    else{
                        var retObj = {};
                        formFields.forEach(function(item){
                            if(item === "dateTime"){
                                retObj[item] = (response.data.results[item] === null ? null : new Date(response.data.results[item]));
                            } 
                            else { 
                                retObj[item] = response.data.results[item];
                            }
                        });

                        self.setState({ formData: retObj });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] Getting Draft", ex);
        }
    }

    componentDidMount(){
        this.loadLeagueInfo();
        if(this.props.selectedDraftId.length > 1) {
            this.getDraftInfo(this.props.selectedDraftId);
        }
    }

    render(){  
        return(
            <div className="tab-content">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="editor-body">
                    <h1>Create/Edit Draft Information</h1>
                    <div className="editor-form">
                        <div className="form-element sz-7 top">
                            <div className="element-title">Title</div>
                            <input type="text" name="title" value={this.state.formData.title ?? ''} onChange={this.onElementChange}/>
                        </div>
                        
                        <div className="form-element sz-3 top">
                            <div className="element-title">League Type</div>
                            <select name="leagueType" value={this.state.formData.leagueType ?? ''} onChange={this.onElementChange}>
                                <option value="coed">Coed</option>
                                <option value="mens">Mens</option>
                                <option value="womens">Womens</option>
                            </select>
                        </div>

                        <div className="form-element sz-5">
                            <div className="element-title">Field Location</div>
                            <input type="text" name="location" value={this.state.formData.location ?? ''} onChange={this.onElementChange} />
                        </div>

                        <div className="form-element sz-5">
                            <div className="element-title">League Roster</div>
                            <select name="leagueRoster" value={this.state.formData.leagueRoster ?? ''} onChange={this.onElementChange}>
                                <option value="">None</option>
                                {this.state.leagueRosterList.map((league,i) =>
                                    <option value={league.programId} key={i}>{league.name} ({this.formatDate(league.startTime)})</option>
                                )}
                            </select>
                        </div>

                        <div className="form-element sz-3">
                            <div className="element-title">Draft Type</div>
                            <select name="draftType" value={this.state.formData.draftType  ?? ''} onChange={this.onElementChange}>
                                <option value="default">Normal</option>
                                <option value="snake">Snake</option>
                            </select>
                        </div>

                        <div className="form-element sz-4">
                            <div className="element-title">Draft Date & Time</div>
                            <DatePicker showTimeSelect minDate={new Date()} selected={this.state.formData.dateTime} placeholderText='Enter Date & Time' onChange={(date) => this.onDateChange(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                        </div>

                        <div className="form-element sz-3">
                            <div className="element-title">Selection Time</div>
                            <input type="number" name="pickTime" min="1" max="10" step="1" value={this.state.formData.pickTime ?? ''} onChange={this.onElementChange} />
                        </div>
                    </div>
                </div>
                <div className="editor-controls">
                    <div className="btn-container">
                        <div className={"link-btn " + (this.props.selectedDraftId.length >= 1 ? "cg4" : "c4")} onClick={this.saveDraft}><i className={(this.props.selectedDraftId.length >= 1 ? "far fa-save" : "fas fa-atom")} /> {(this.props.selectedDraftId.length >= 1 ? "Save " : "Generate ")} Draft</div>
                        
                        {this.props.selectedDraftId.length >= 1 && <div className="back-link next" onClick={()=> this.props.setTabSection("draftteams")}><span>Edit Draft Teams</span><i className="fas fa-angle-double-right" /></div> }
                    </div>
                </div>
            </div>
        );
    }
}
export default DraftEditorInfo;