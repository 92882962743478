import React, { Component } from 'react';
import { Calendar, momentLocalizer  } from 'react-big-calendar'
import Modal from '@material-ui/core/Modal';
import LoadSpinner from '../loadSpinner';
import axios from 'axios';
import moment from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

class LeagueCalendarTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, events:[], 
            eventModal: false, selectedEvent: null
        }

        this.setEvent = this.setEvent.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getLeagueEvents = this.getLeagueEvents.bind(this);
    }
    
    setEvent(event){     
        this.setState({ selectedEvent: event, eventModal: true });          
    }
    
    formatDate(date){
        var ret = "";
        try {
            var d = new Date(date);
            const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            ret = days[d.getDay()] + " " + months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        }
        catch(ex){
            console.log("[Error] formatting date: ",ex);
        }

        return ret;
    }

    getLeagueEvents(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                axios.post(self.props.rootPath + "/api/getLeagueEvents", {count: -1, type: ""}, {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(!response.data.error && response.data.results.length > 0){
                        var tmpData = response.data.results.map(function(event){
                            return { id: event.programId, title: event.name, desc: event.description, start: new Date(event.startTime), end:new Date(event.startTime), link: event.registerUrlHtml, location: event.location };
                        });

                        self.setState({ events: tmpData });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });
        }
        catch(ex){
            console.log("[Error] Loading Draft List:",ex);
        }
    }

    componentDidMount(){ this.getLeagueEvents(); }

    render(){  
        return(
            <div className="tab-container league-calendar">
                <h1 className="tab-title">League Calendar</h1>
                
                <div className="calendar-container">
                    {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                    <Calendar popup events={this.state.events} 
                        localizer={localizer} defaultDate={new Date()} 
                        onSelectEvent={event => this.setEvent(event)} />
                </div>

                <Modal open={this.state.eventModal} onClose={()=>this.setState({ eventModal: false })}>
                    <div className="modal-body cg1">
                        {this.state.selectedEvent != null &&
                            <div className="event-info">
                                <i className="close-modal fas fa-times" onClick={()=>this.setState({ eventModal: false })}/>
                                <div className="event-card">
                                    <div className="card-info">
                                        <div className="calendar-title">
                                            <div className="icon-cover"><i className="fas fa-trophy" /></div>
                                            <h1>{this.state.selectedEvent.title}</h1>
                                        </div>
                                                                                
                                        <div className="calender-sub-info">
                                            <span>{this.formatDate(this.state.selectedEvent.start)}</span>
                                            <span>{this.state.selectedEvent.location}</span>
                                        </div>                                        
                                        <p className="calender-txt" dangerouslySetInnerHTML={{__html: this.state.selectedEvent.desc}} />
                                        <a href={this.state.selectedEvent.link} target="_blank" className="item link-btn c2" alt="Register"><i className="fas fa-file-signature"/> Register</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}
export default LeagueCalendarTab;