import { Link } from "react-router-dom";
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import FindLeagueTracker from './statTracker/findLeague';
import LoadSpinner from '../shared/loadSpinner';
import SignIn from '../shared/tabs/signIn';
import StatCollectorTracker from "./statTracker/statCollector";

/* Images */
import logo from '../../assets/imgs/logo0.png';

const mySessKey = "leeleeDraftTourney_.";
const tabList = {
    "signin":1, "findleague":["activeUser"], 
    "statcollector":["activeUser", "selectedGame"]
};

class StatTrackerContainer extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading:false, navSel: "", activeUser: null,
            selectedGame: null, selLeague: null, 
            selLeagueSchedule: null, selDay:0
        }

        this.pageSelection = this.pageSelection.bind(this);
        this.setSelectedGame = this.setSelectedGame.bind(this);
        this.setSelectedLeague = this.setSelectedLeague.bind(this);
        this.setSelectedDay = this.setSelectedDay.bind(this);
        this.setTabSection = this.setTabSection.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.checkToken = this.checkToken.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    pageSelection(){
        try {
            switch(this.state.navSel){
                case "signin":
                    return <SignIn rootPath={this.props.rootPath} setTabSection={this.setTabSection} userHandler={this.userHandler} />;
                case "findleague":
                    return <FindLeagueTracker rootPath={this.props.rootPath} selLeague={this.state.selLeague} selLeagueSchedule={this.state.selLeagueSchedule} selDay={this.state.selDay} setSelectedDay={this.setSelectedDay} setSelectedLeague={this.setSelectedLeague} setSelectedGame={this.setSelectedGame}/>;
                case "statcollector":
                    return <StatCollectorTracker rootPath={this.props.rootPath} mySessKey={mySessKey} selectedGame={this.state.selectedGame} selLeague={this.state.selLeague} setTabSection={this.setTabSection}/>;
                default:
                    return <div className="no-display"/>
            }
        }
        catch(ex){
            console.log(`[Error] Selecting Page: ${ex}`);
        }
    }

    setSelectedGame(gameId, gameTime, team1, team1Id, team2, team2Id, type){
        var self = this;
        try {
            var tmpGameInfo = { gameId: gameId, gameTime: gameTime, team1: team1, team1Id:team1Id, team2: team2, team2Id: team2Id, type: type };
            this.setState({ selectedGame: tmpGameInfo }, ()=>{
                self.setTabSection("statcollector");
            });
        }
        catch(ex){
            console.log(`[Error] Selecting Game: ${ex}`);
        }
    }

    setSelectedLeague(league, leagueSchedule, selDay){
        try {
            this.setState({selLeague: league, selLeagueSchedule: leagueSchedule, selDay: selDay });
        }
        catch(ex){
            console.log(`[Error] Selecting League: ${ex}`);
        }
    }

    setSelectedDay(selDay){
        try {
            this.setState({ selDay: selDay });
        }
        catch(ex){
            console.log(`[Error] Selecting League: ${ex}`);
        }
    }
    setTabSection(section){
        try {
            section = section.toLowerCase();
            if(section in tabList){
                this.setState({ navSel: section });
            }            
        }
        catch(ex){
            console.log("[Error] Setting Nav Selection Section: ",ex);
        }
    }

    validateTabChange(section){
        var ret = true;
        try {
            section = section.toLowerCase();
            if(section in tabList){
                for(var i=0; i < tabList[section].length; i++){
                    if(this.state[tabList[section][i]] === null){ ret = false; break; }
                }
                
                if(ret === true){ this.setTabSection(section); }
            }            
        }
        catch(ex){
            console.log("[Error] Validating Nav Selection Section: ",ex);
        }
    }

    userHandler(newToken, cb) {
        var self = this;
        try {   
            var usr = null;
            if(newToken){
                var { localUser, isExpired } = parseToken(newToken);                

                if(!isExpired) { usr = localUser.name; }
            }   

            self.setState({ activeUser: usr },() => {
                if(!self.state.activeUser){
                    localStorage.removeItem(mySessKey); cb();
                    self.setTabSection("signin");
                } 
                else {
                    localStorage.setItem(mySessKey, newToken); cb();
                    self.setTabSection("findleague");
                }
            });          
        }
        catch(ex){
            console.log("Error with user handler: ", ex);
        }
    }

    checkToken() {
        try {
            var sessionInfo = localStorage.getItem(mySessKey);

            if(sessionInfo && sessionInfo[0] === "{") {
                localStorage.removeItem(mySessKey);
                this.setState({ activeUser: null });
            }
            else if(sessionInfo){
                var { localUser, isExpired } = parseToken(sessionInfo);

                if(!isExpired){
                    this.setState({ activeUser: localUser.name, navSel:"findleague" });
                }
                else {
                    localStorage.removeItem(mySessKey);
                    this.setState({ activeUser: null, navSel:"signin" });
                }
            }
            else {
                this.setState({ navSel:"signin" });
            }
        }
        catch(ex) {
            console.log("[Error] Checking Token: ", ex);
        }
    }

    signOut(){
        var self = this;
        try {
            if(confirm("Are you sure you want to sign-out?")){
                this.userHandler(null, function(){ self.setTabSection("signin"); });
            }
        }
        catch(ex){
            console.log("[Error] Signing Out: ", ex);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Stat Tracker";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/stattracker');

        this.checkToken();
    }

    render(){  
        return(
            <div className="stat-tracker-container">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className="tracker-header">
                    <h1>Lee Lee</h1>
                    <h2>Stat Tracker</h2>
                </div>

                <div className="tracker-body">{this.pageSelection()}</div>

                <div className="tracker-nav">
                    <div className={"tracker-nav-icon" + (this.state.navSel === "findleague" ? " sel":"") + (this.state.activeUser === null ? " disabled":"")} alt="Find League" onClick={()=> this.validateTabChange("findleague")}>
                        <i className="fas fa-search"/>
                        <div className="icon-txt">Find League</div>
                    </div>

                    <div className={"tracker-nav-icon" + (this.state.navSel === "statcollector" ? " sel":"") + (this.state.activeUser === null || this.state.selectedGame == null ? " disabled":"")} onClick={()=> this.validateTabChange("statcollector")} alt="Stat Collector">
                        <i className="fas fa-football-ball"/>
                        <div className="icon-txt">Stat Collector</div>
                    </div>

                    <Link to="/" alt="Return Home"  className="tracker-nav-icon">
                        <div className="logo-container"><img src={logo} /></div>
                        <div className="icon-txt">Website</div>
                    </Link>

                    <div className={"tracker-nav-icon" + (this.state.navSel === "signin" ? " sel":"")} onClick={this.signOut}>
                        <i className={"fas fa-user" + (this.state.activeUser === null ? "" : "-times")}/>
                        <div className="icon-txt">Sign-{(this.state.activeUser === null ? "in" : "out")}</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default StatTrackerContainer;

/* Local Functions */
function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}