import React, { Component } from 'react';
import PlayerSearch from '../playerSearch';
import FileUploader from '../fileUploader';

import axios from 'axios';

import LoadSpinner from '../loadSpinner';
import CustomTable from '../customTable';

class DraftEditorInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, playerList: [], draftName: null
        }

        this.headerCol = [
            { label: 'Photo', id: 'photo' }, { label: 'Name', id: 'name' }, 
            { label: 'Email', id: 'email' }, { label: 'Gender', id: 'gender' },  
            { label: 'Jersey #', id:'number'}, { label: 'Positions', id: 'positions' }
        ];
        
        this.playerInList = this.playerInList.bind(this);
        this.addPlayer = this.addPlayer.bind(this);
        this.addBulkPlayers = this.addBulkPlayers.bind(this);
        this.removePlayer = this.removePlayer.bind(this);
        this.getDraftInfo = this.getDraftInfo.bind(this);
        this.getDraftPlayers = this.getDraftPlayers.bind(this);
    }

    playerInList(player){
        var ret = false;
        try {
            var tmpList = this.state.playerList.filter((plr) => { 
                var checkLaId = "laId" in player && player.laId === plr.laId;
                return plr._id === player._id || checkLaId;
            });

            ret = tmpList.length > 0;
        }
        catch(ex){
            console.log("[Error] Checking Player List: ",ex);
        }

        return ret;
    }

    addBulkPlayers(bulkData){
        let self = this;
        try {
            this.setState({loading: true }, ()=> {
                let token = localStorage.getItem(self.props.mySessKey),
                    postData = { "draftId": self.props.selectedDraftId, "playerList": bulkData };
                
                axios.post(self.props.rootPath + "/api/draft/players/bulkadd", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){ 
                            alert("Error Adding Players: ", response.data.error);
                        }
                        else {
                            self.getDraftPlayers();
                            alert("Players Added Successfully");
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    });  
            });
        }
        catch(ex){
            console.log(`Submitting Bulk Add: ${ex}`);
        }
    }

    addPlayer(player){
        var self = this;
        try {
            if(this.playerInList(player)){
                alert(player.name + " is already in this draft!");
            }
            else {
                this.setState({ loading: true, saveStatus: 1 }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    var postData = { draftId: self.props.selectedDraftId, playerId: player._id };
                    axios.post(self.props.rootPath + "/api/draft/player/add", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Adding Draft Player (E1): ", response.data.error);
                            }
                            else if(response.data.results){
                                self.getDraftPlayers();
                            }
                        })
                        .catch(function (error) {
                            console.log("[Error] Adding Draft Player (E2)",error);
                        })
                        .then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log("[Error] Adding Draft Player: ", ex);
        }
    }

    removePlayer(player){
        var self = this;
        try {            
            this.setState({ loading: true, saveStatus: 1 }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                var postData = { draftId: this.props.selectedDraftId, playerId: player._id };
                axios.post(self.props.rootPath + "/api/draft/player/remove", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Removing Draft Player (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.getDraftPlayers();
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Removing Draft Player (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
                });
        }
        catch(ex){
            console.log("[Error] Removing Draft Player: ", ex);
        }
    }

    getDraftInfo(){
        var self = this;
        try {
            var token = localStorage.getItem(self.props.mySessKey);
            var draftId = this.props.selectedDraftId;
            axios.get(self.props.rootPath + "/api/draft/"+draftId, {headers: {'Content-Type': 'application/json', 'Authorization': token}})
            .then(function(response) {
                if(response.data.error){
                    console.log(" [Error] Getting Draft Info (E1): ", response.data.error);
                }
                else{
                    self.setState({ draftName: response.data.results.title });
                }
            }); 
        }
        catch(ex){
            console.log("[Error] Getting Draft", ex);
        }
    }

    getDraftPlayers(){
        var self = this;
        try {
            var draftId = this.props.selectedDraftId;
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                axios.get(self.props.rootPath + "/api/draft/players/"+draftId, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){
                        console.log("[Error] Getting Draft Players: ",response.data.error);
                    }
                    else {
                        self.setState({ playerList: response.data.results });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] Getting Draft Players", ex);
        }
    }


    componentDidMount(){ this.getDraftPlayers(); this.getDraftInfo(); }

    render(){  
        return(
            <div className="tab-content">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="editor-body">
                    <div className="title-btn-container">
                        <h1>Draft Editor Players</h1>
                    </div>

                    <div className="player-panel">
                        {this.state.draftName != null && <div className="draft-name">{this.state.draftName}</div> }
                        <p>Build player avaliable list for this draft, use the search box to find players.</p>
                    </div>

                    <div className="player-panel search">
                        <PlayerSearch rootPath={this.props.rootPath} mySessKey={this.props.mySessKey} selectAction={(player)=> this.addPlayer(player)}/>
                        {this.props.selectedDraftId.length >= 1 && <FileUploader addPlayers={(players)=> this.addBulkPlayers(players) } /> }
                    </div>

                    <div className="player-panel">
                       <CustomTable rows={this.state.playerList} headCells={this.headerCol} deleteBtn={true} deleteAction={(player) => this.removePlayer(player)}/>
                    </div>
                </div>

                <div className="editor-controls">
                    <div className="btn-container">
                        {this.props.selectedDraftId.length >= 1 && <div className="back-link next" onClick={()=> this.props.setTabSection("draftpreview")}><span>View Draft Preview</span><i className="fas fa-angle-double-right" /></div> }
                    </div>
                </div>
            </div>
        );
    }
}
export default DraftEditorInfo;