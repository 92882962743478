import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const getListStyle = isDraggingOver => ({ background: isDraggingOver ? 'rgba(226,224,229,0.3)' : ''});
const getRemoveStyle = isDraggingOver => ({ background: isDraggingOver ? 'rgba(147,36,50,0.7)' : ''});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
  
    // change background colour if dragging
    background: isDragging ? '#000' : '',
  
    // styles we need to apply on draggables
    ...draggableStyle,
});

class OptionDropper extends Component{
    componentDidMount(){ }

    render(){  
        return(
            <>
                <DragDropContext onDragEnd={this.props.onDragEnd}>
                    <div className='title-header'>
                        <div className='title'>
                            <span className='main-title'>Default {this.props.title} Options List</span>
                            {this.props.full_version && <span className='instructions'>(Drag & drop list items to remove and reorder them)</span>}
                        </div>
                        <div className='title-btn-container'>
                            <div className='title-btn add' onClick={this.props.addOption}>
                                <i className="fas fa-plus" /> 
                                {this.props.full_version && <span>Add Option</span>}
                            </div>
                            <Droppable droppableId="droppableRemove">
                                {(providedR, snapshotR) => (
                                    <div className='title-btn remove' ref={providedR.innerRef} style={getRemoveStyle(snapshotR.isDraggingOver)} {...providedR.droppableProps}>
                                        <i className="far fa-trash-alt" />
                                        {this.props.full_version && <span>Remove Option</span>}
                                        {providedR.placeholder}
                                    </div>
                                )}
                             </Droppable>
                        </div>
                    </div>

                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div className='config-option-list' ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                {this.props.option_list?.map((option, i) => (
                                    <Draggable key={option.id} draggableId={option.id} index={i}>
                                        {(provided, snapshot) => (
                                            <div className='option-pill' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}>
                                                <span className='number'>{i+1}</span>
                                                <input type="text" name="value" value={this.props?.option_list[i].value} onChange={(e) => this.props.handleOptionChange(e, i)}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        );
    }
}
export default OptionDropper;