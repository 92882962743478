import React, { Component } from 'react';
import axios from 'axios';

import LoadSpinner from '../../shared/loadSpinner';

class FindLeagueTracker extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            search:"", loading: false,
            leagues: [], filteredLeagues:[], selDay:0
        }

        this.controlSlider = this.controlSlider.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.searchLeagues = this.searchLeagues.bind(this);
        this.dateText = this.dateText.bind(this);
        this.getLeagueSchedule = this.getLeagueSchedule.bind(this);
        this.getNextWeek = this.getNextWeek.bind(this);
        this.loadLeagueInfo = this.loadLeagueInfo.bind(this);
        this.selectLeagueGame = this.selectLeagueGame.bind(this);

        /* References */
        this.dateConList = React.createRef();
    }

    controlSlider(ref, dir, sz){
        try {
            var scrollSz = 250;   
            if(sz === 0) {
                ref.current.scrollTop = ref.current.scrollTop + (dir * scrollSz);  
            }       
            else {
                ref.current.scrollLeft = ref.current.scrollLeft + (dir * scrollSz);  
            } 
                      
        }
        catch(ex){
            console.log("Error with slider control: ", ex);
        }
    }

    handleChange(e){
        var self = this;
        try {
            this.setState({[e.target.name]: e.target.value }, ()=> {
                self.searchLeagues();
            });
        }
        catch(ex){
            console.log("[Error] handling change: ",ex);
        }
    }

    searchLeagues(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=>{
                var tmpList = self.state.leagues.filter(function(item){ 
                    return (item.name.toLowerCase().indexOf(self.state.search.toLowerCase()) >= 0
                        || item.scheduleDays.toLowerCase().indexOf(self.state.search.toLowerCase()) >= 0
                        || item.location.toLowerCase().indexOf(self.state.search.toLowerCase()) >= 0
                        || item.gender.toLowerCase().indexOf(self.state.search.toLowerCase()) >= 0
                    );
                });
    
                self.setState({filteredLeagues: tmpList, loading: false });
            });
        }
        catch(ex){
            console.log(`[Error] Searching Leagues: ${ex}`);
        }
    }

    dateText(text, type){
        var ret = "";
        try {
            var d = new Date();

            if(type === "time") {
                d = new Date(parseInt(text));
            }
            else {
                var tmpDate = text.split("-");
                if(tmpDate.length >= 3) {
                    var y = tmpDate[2], m = parseInt(tmpDate[0]) - 1, day = tmpDate[1];
                    d = new Date(y, m, day);
                }
            }
            
            var monthList = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            
            switch(type){
                case "month":
                    ret = monthList[d.getMonth()];
                    break;
                case "date":
                    ret = d.getDate();
                    break;
                case "time":
                    var h = d.getHours(), m = d.getMinutes();
                    ret = (h > 12 ? h-12 : h) + ":" + (m < 10 ? "0"+m : m) + (h > 12 ? " PM" : " AM");
                    break;
                case "datetime":
                    ret = d.getTime();
                    break;
                default:
                    break;
            }
        }
        catch(ex){
            console.log("[Error] Minimizing Text: ", ex);
        }

        return ret;
    }

    getNextWeek(selLeague){
        var ret = 0, self = this;
        try {
            var curDate = new Date();
            curDate.setDate(curDate.getDate()-1);

            for(var i = 0; i < selLeague.length; i++){
                if(curDate.getTime() <= self.dateText(selLeague[i].day, "datetime")){
                    break;
                }
                else if((i + 1) < selLeague.length){
                    ret++;
                }
            }
        }
        catch(ex){
            console.log("[Error] Getting Next Week: ",ex);
        }
        return ret;
    }

    getLeagueSchedule(league) {
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                axios.get(self.props.rootPath + "/api/league/schedule/"+league.programId, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting League Schedule (E1): ", response.data.error);
                        }
                        else {                            
                            self.props.setSelectedLeague({"id": league.programId, "name":league.name, "startDate": league.startTime}, response.data.results.schedule, self.getNextWeek(response.data.results.schedule));
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] League Schedule: ",ex);
        } 
    }


    loadLeagueInfo(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { count: -1, type: "" };
                axios.post(self.props.rootPath + "/api/getLeagueEvents", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting League Event Data (E1): ", response.data.error);
                        }
                        else {
                            self.setState({ leagues: response.data.results, filteredLeagues: response.data.results });
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] loading league info list: ",ex);
        } 
    }

    selectLeagueGame(gameId, gameTime, team1Name, team1Id, team2Name, team2Id, type){
        try {
            this.props.setSelectedGame(gameId, gameTime, team1Name, team1Id, team2Name, team2Id, type)
        }
        catch(ex){
            console.log(`[Error] Selecting League Game: ${ex}`)
        }
    }

    componentDidMount(){  
        window.scrollTo(0, 0); this.loadLeagueInfo();
    }

    render(){  
        return(
            <div className="stat-tracker-content find-league">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }

                {this.props.selLeague === null ?
                    <div className="league-search">
                        <div className="search-container-title">
                            <h1>Find League</h1>
                            <p className="search-text"><span className="c2">Search</span> or <span>select</span> below the league you are looking to generate stats for.  Once the <span>league</span>, <span>game date/time</span>, & <span>specific game</span> have been selected you will be able to update player stats.</p>
                            <div className="search-container">
                                <i className="fas fa-search" />
                                <input name="search" type="text" className="search-bar" autoComplete="off" value={this.state.search} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="league-list-container">
                            {this.state.filteredLeagues.length === 0 && <div className="empty-list">Sorry No Active Leagues To Track Stats For</div>}
                            {this.state.filteredLeagues.map((league,i) =>
                                <div className="list-item" key={i} onClick={()=> this.getLeagueSchedule(league)}>
                                    <h2>{league.name}</h2>
                                    <div className="bottom-txt">
                                        <span>{league.scheduleDays}</span>
                                        <span>{league.scheduleTimes}</span>
                                        <span>{league.gender}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div> :
                    <div className="game-select">
                        <div className="league-back" onClick={()=> this.props.setSelectedLeague(null,null,0)}><i className="fas fa-chevron-left"/> <span>BACK TO LEAGUE SELECTION</span></div>
                        
                        <div className="ctrl-list date-list-container">
                            <i className="fas fa-chevron-left" onClick={()=> this.controlSlider(this.dateConList, -1, 1)}/>

                            {(this.props.selLeagueSchedule != null) &&
                                <div className="scroll-container" ref={this.dateConList}>
                                    {this.props.selLeagueSchedule.map((date,i) =>
                                        <div className={"list-item" + (i == this.props.selDay ? " sel" : "")} key={i} onClick={() => this.props.setSelectedDay(i)}>
                                            <span className="bold">{this.dateText(date.day, "date")}</span>
                                            <span>{this.dateText(date.day, "month")}</span>
                                        </div>
                                    )}
                                </div>
                            }
                            <i className="fas fa-chevron-right" onClick={()=> this.controlSlider(this.dateConList, 1, 1)}/>
                        </div>

                        {(this.props.selLeagueSchedule != null && this.props.selLeagueSchedule[this.props.selDay]) &&
                            <div className="schedule-list-container">
                                {this.props.selLeagueSchedule[this.props.selDay].schedule.map((timeSlot, i) =>
                                    <div className="time-slot" key={i}>
                                        <div className="time-info"><span>{this.dateText(timeSlot.time, "time")}</span></div>
                                        <div className="game-list-container">
                                            {timeSlot.games.map((game,j) =>
                                                <div className="game-info" key={j} onClick={()=> this.selectLeagueGame(game.gameId, game.startTime, game.team1, game.team1Id, game.team2, game.team2Id, game.type)}>
                                                    <div className="team-container">
                                                        <div className="team-info">
                                                            <div className="info-text"><span>{game.team1}</span></div>
                                                        </div>
                                                        <div className="team-info spacer text">VS</div>
                                                        <div className="team-info">
                                                            <div className="info-text"><span>{game.team2}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}
export default FindLeagueTracker;