import React, { Component } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';

import LoadSpinner from '../shared/loadSpinner';

class ResetAccount extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, 
            formData: { tmppwd: "", userId: "", basePin:"", basePinConfirm:"" }
        }

        this.loadParams = this.loadParams.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
        this.verifyPin = this.verifyPin.bind(this);
        this.resetPin = this.resetPin.bind(this);
    }

    loadParams(){
        try {
            var urlParams = new URLSearchParams(this.props.location.search);
            this.setState({ formData: {tmppwd: urlParams.get("tmppwd"), userId: urlParams.get("uid"), basePin:"", basePinConfirm:"" }});
        }
        catch(ex){
            console.log("[Error] Loading Params: ",ex);
        }
    }

    onElementChange(e){
        try {
            var tmpData = this.state.formData;
            var name = e.target.name;

            if(name in tmpData) {               
                tmpData[name] = e.target.value;                
                this.setState({ formData:tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on element change: ",ex);
        }
    }

    verifyPin(pinLoc){
        var ret = [];
        try {
            var pin1 = this.state.formData.basePin, pin2 = this.state.formData.basePinConfirm;

            if(pinLoc === 1){
                if(pin1.length > 0 && pin1.length < 8) { ret.push("Pin must be a minimum of 8 digits"); }
            }
            else if(pinLoc === 2){
                if(pin1.length > 0 && pin2.length > 0 && pin2 != pin1) { ret.push("Your pins do not match!"); }
            } 
            else {
                if(pin1.length < 8 || pin2.length < 8) { ret.push("Pin does not match length requirements"); }
                if(pin1 != pin2) { ret.push("Pins do not match"); }
            }           
        }
        catch(ex){
            console.log("[Error] verifing pin: ",ex);
        }

        return ret;
    }

    resetPin(){
        var self = this;
        try {
            if(this.verifyPin(3).length < 1) {
                this.setState({ loading: true }, ()=> {
                    var postData = { ...this.state.formData };
                    axios.post(self.props.rootPath + "/api/auth/resetUser", postData, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log("[Error] Resetting Pin: ", response.data.error);
                                alert("Error Reseting Pin, please contact system admin is problems continue.");
                            }
                            else {                                
                                self.setState({ loading: false }, ()=> {
                                    alert("Reset Completed");
                                    // TODO: Move to Sign-in page
                                    location.href = "/leagueinfo";
                                });                                
                            }
                        })
                        .catch(function (error) {
                            console.log("[Error] With Pin Reset (E2)",error);
                        })
                        .then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log("[Error] With Admin Login: ",ex);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); document.title = "Reset Account";
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/resetAccount');
        this.loadParams();
    }

    render(){  
        return(
            <div className="page-body reset">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="sign-in-body reset-container">
                <div className="sign-in-panel login">
                        <h1>Reset Account Pin</h1>
                        <div className="login-element">
                            <input type="text" name="userId" placeholder="Username" value={this.state.formData.userId} disabled={true} onChange={this.onElementChange}/>
                            <i className="far fa-user" />
                        </div>
                           
                        <ul className="login-message">
                            {this.verifyPin(1).map((msg,i) =>
                                <li key={i}>{msg}</li>
                            )}
                        </ul>
                        <div className="login-element">
                            <input type="password" name="basePin" placeholder="8 Digit Pin" value={this.state.formData.basePin} onChange={this.onElementChange}/>
                            <i className="fas fa-passport" />
                        </div>

                        <ul className="login-message">
                            {this.verifyPin(2).map((msg,i) =>
                                <li key={i}>{msg}</li>
                            )}
                        </ul>
                        <div className="login-element">
                            <input type="password" name="basePinConfirm" placeholder="Confirm 8 Digit Pin" value={this.state.formData.basePinConfirm} onChange={this.onElementChange}/>
                            <i className="fas fa-passport" />
                        </div>

                        <div className={"login-btn reset" + (this.verifyPin(3).length > 0 ? " disable": "")} onClick={this.resetPin}>
                            <span>Reset Pin</span>
                            <i className="fas fa-redo" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ResetAccount;