import React, { Component } from 'react';
import PlayerSearch from '../playerSearch';
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import LoadSpinner from '../loadSpinner';
import CustomTable from '../customTable';

class LeagueRoster extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, playerList: [], 
            posList:["QB","WR","CB","LB","FS","RS"],
            selectedPlayer: null, playerModal: false
        }

        this.headerCol = [
            { label: 'Photo', id: 'photo' }, { label: 'Name', id: 'name' }, 
            { label: 'Email', id: 'email' }, { label: 'Gender', id: 'gender' }, 
            { label: 'Jersey #', id:'number'}, { label: 'Positions', id: 'positions' }
        ];
        
        this.loadUpdatedLeagueRoster = this.loadUpdatedLeagueRoster.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.selectPlayer = this.selectPlayer.bind(this);
        this.editPlayer = this.editPlayer.bind(this);
        this.removePlayer = this.removePlayer.bind(this);
        this.getLeaguePlayers = this.getLeaguePlayers.bind(this);
    }

    loadUpdatedLeagueRoster(callback){
        var self = this;
        try {   
            this.setState({ loading: true }, ()=> {        
                var token = localStorage.getItem(self.props.mySessKey);

                axios.get(self.props.rootPath + "/api/league/player/merge", {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){ 
                            console.log("[Error] Updating League Roster: ", response.data.error);
                        }
                    }).then(function(){
                        self.setState({ loading: false }, ()=> { callback(); });
                    });    
            });                      
        }
        catch(ex){
            console.log("[Error] Updating League Roster (E1): ", ex);
        }
    }

    handleSelectChange(e){
        try {
            var tmpData = this.state.selectedPlayer;
            tmpData.positionList = e.target.value;
            this.setState({ selectedPlayer: tmpData });
        }
        catch(ex){
            console.log("[Error] Handling Select Change: ",ex);
        }
    }

    handleFormChange(e){
        try {
            var tmpData = this.state.selectedPlayer;
            var name = e.target.name;

            if(name in tmpData) {
                tmpData[name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);                
                this.setState({ selectedPlayer: tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    selectPlayer(player){
        try {
            player.positionList = (player.positions ? player.positions.split(",") : []);
            player.number = (player.number != undefined ? player.number : "");
            this.setState({ selectedPlayer: player, playerModal: (player != null) });
        }
        catch(ex){
            console.log("[Error] Selecting League Player: ", ex);
        }
    }

    validateForm(){
        var ret = false;

        try{
            var tmpForm = this.state.selectedPlayer;
            var missingData = [];

            if(tmpForm.name == null || tmpForm.name.length <= 0) { missingData.push("Name");}
            if(tmpForm.email == null || tmpForm.email.length <= 0) { missingData.push("Email");}
            if(tmpForm.positionList == null) { missingData.push("Positions");}
            if(tmpForm.gender == null || tmpForm.gender.length <= 0) { missingData.push("Gender");}
            
            if(missingData.length <= 0) { ret = true;}
            else {
                alert("Unable to edit this player please check: " + missingData.join(", "));
            }
        }
        catch(ex){
            console.log("[Error] Validating Form: ",ex);
        }

        return ret;
    }

    editPlayer(){
        var self = this;
        try {
            if(this.validateForm()){
                this.setState({loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    var postData = { "player": self.state.selectedPlayer };
                    postData.player.positions = postData.player.positionList.join(",");

                    axios.post(self.props.rootPath + "/api/league/player/add", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){ 
                                alert("Error Editing Player: ", response.data.error);
                            }
                            else {
                                self.setState({ playerModal: false, selectedPlayer:null }, ()=>{
                                    self.getLeaguePlayers("");
                                });
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        });  
                });
            }
        }
        catch(ex){
            console.log("[Error] Editing League Player: ", ex);
        }
    }

    removePlayer(player){
        var self = this;
        try {            
            this.setState({ loading: true, saveStatus: 1 }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                var postData = { playerId: player._id };
                axios.post(self.props.rootPath + "/api/league/player/remove", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Removing League Player (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.getLeaguePlayers("");
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Removing League Player (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
                });
        }
        catch(ex){
            console.log("[Error] Removing Draft Player: ", ex);
        }
    }


    getLeaguePlayers(query){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                var postData = { "query":query, "maxSize": -1 };
                axios.post(self.props.rootPath + "/api/league/player/search", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){
                        console.log("[Error] Getting League Players: ",response.data.error);
                    }
                    else {
                        self.setState({ playerList: response.data.results });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] Getting League Players", ex);
        }
    }


    componentDidMount(){ 
        var self = this;
        this.loadUpdatedLeagueRoster(function(){
            self.getLeaguePlayers("");
        });
    }

    render(){  
        return(
            <div className="tab-content">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="editor-body">
                    <div className="title-btn-container">
                        <h1 className="tab-title">League Roster</h1>
                    </div>

                    <div className="player-panel">                        
                        <p>View/Edit all of the past and present players in the overall league.</p>
                    </div>

                    <div className="player-panel search">
                        <PlayerSearch rootPath={this.props.rootPath} mySessKey={this.props.mySessKey} selectAction={(player)=> this.selectPlayer(player)} addCB={() => this.getLeaguePlayers("")}/>
                    </div>

                    <div className="player-panel">
                       <CustomTable rows={this.state.playerList} headCells={this.headerCol} deleteBtn={true} deleteAction={(player) => this.removePlayer(player)}/>
                    </div>
                </div>

                <Modal open={this.state.playerModal} onClose={()=>this.setState({ playerModal: false })}>
                    <div className="modal-body c1">
                        <i className="close-modal fas fa-times" onClick={()=>this.setState({ playerModal: false })}/>
                        <h1>Edit League Player</h1>

                        {this.state.selectedPlayer != null &&
                            <div className="editor-form">
                                <div className="form-element sz-5 cm">
                                    <div className="element-title">Name</div>
                                    <input type="text" name="name" value={this.state.selectedPlayer.name} onChange={this.handleFormChange}/>                                                    
                                </div>

                                <div className="form-element sz-5 cm">
                                    <div className="element-title">Email</div> 
                                    <input type="text" name="email" value={this.state.selectedPlayer.email} onChange={this.handleFormChange}/>                                                             
                                </div>

                                <div className="form-element sz-3 cm">
                                    <div className="element-title">Gender</div>  
                                    <select name="gender" value={this.state.selectedPlayer.gender} onChange={this.handleFormChange}>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>                                                  
                                </div>

                                <div className="form-element sz-3 cm">
                                    <div className="element-title">Jersey #</div> 
                                    <input type="text" name="number" value={this.state.selectedPlayer.number} onChange={this.handleFormChange}/>                                                             
                                </div>

                                <div className="form-element sz-4 cm">
                                    <div className="element-title">Positions</div>
                                    <FormControl className="form-element-control">
                                        <Select multiple name="positions" value={this.state.selectedPlayer.positionList}
                                            onChange={this.handleSelectChange} input={<Input />} 
                                            renderValue={(selected) => selected.join(',')}>
                                            {this.state.posList.map((name,j) => (
                                                <MenuItem key={j} value={name}>
                                                    <Checkbox checked={this.state.selectedPlayer.positionList.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        }

                        <div className="btn-container">
                            <div className="link-btn cg4" onClick={this.editPlayer}><i className="fas fa-user-plus" />Save Player</div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default LeagueRoster;