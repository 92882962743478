import React, { Component } from 'react';
import { format } from "date-fns";
import { currencyFormat, rootPath } from '../../shared/utils';
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import { toast } from 'react-toastify';

import LoadSpinner from '../../shared/loadSpinner';
import logo from '../../../assets/imgs/leeleekiddz_logo.jpg';

class Quotes extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading:false, quoteList:[], activeQuote: null, modal: false,
            purchase_order_number: "", purchase_order_file: []
        }

        /* References */
        this.photoRef = React.createRef();

        // Functions 
        this.getQuotes = this.getQuotes.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.setActiveQuote = this.setActiveQuote.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.submitPO = this.submitPO.bind(this);
        this.getSubTextLines = this.getSubTextLines.bind(this);
    }

    getQuotes(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                let active_user = this.props.activeUser;
                axios.get(`${rootPath}/v2/api/purchase/quote?account_id=${active_user._id}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(`[Error] Getting Quotes List: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting quotes [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            self.setState({ quoteList: response.data.results  });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Quotes List (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Quotes List: ${ex}`);
        }
    }

    formatDate(date){
        let ret = "N/A";
        try {
            if(date && !isNaN((new Date(date)).getTime())){
                let d = new Date(), fdt = new Date(date);
                
                if(`${d.getDate()} ${d.getMonth()} ${d.getFullYear()}` === `${fdt.getDate()} ${fdt.getMonth()} ${fdt.getFullYear()}`) {
                    ret = format(date,'h:mm aaaa');
                }
                else if(d.getFullYear() === fdt.getFullYear()){
                    ret = format(date,'MMM. dd');
                }
                else {
                    ret = format(date,'MMM. dd, yyyy');
                }
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    setActiveQuote(item){
        try {
            this.setState({ activeQuote: item, modal: item != null, purchase_order_number: "", purchase_order_file: [] });
        }
        catch(ex){
            console.log(`Setting Active Quote: ${ex}`);
        }
    }

    handleFormChange(e){
        try {
            let name = e.target.name;

            if(name in this.state) {
                this.setState({ [name]: e.target.value });
            }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    handleFileChange(e){
        try {
            let name = e.target.name;

            if(name in this.state) {
                this.setState({ [name]: e.target.files });
            }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    submitPO(id){
        let self = this;
        try {
            if(this.state.purchase_order_number?.length <= 2){
                toast.error("Please Enter In A Valid Purchase Order Number", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
            }
            else {
                let active_user = this.props.activeUser;
                this.setState({ loading: true}, ()=>{ 
                    const postData = new FormData();
                    postData.append('account_id',active_user._id);
                    postData.append('purchase_order_number',self.state.purchase_order_number);
                    postData.append('status','PO SUBMITTED');

                    // Add Files
                    if(self.state.purchase_order_file.length > 0) {
                        let file_name_list = [];

                        for(let i=0; i < Array.from(self.state.purchase_order_file).length; i++){
                            postData.append('files',self.state.purchase_order_file[i]);
                            file_name_list.push(self.state.purchase_order_file[i].name);
                        }

                        postData.append('purchase_order_file', file_name_list);
                    }

                    fetch(`${rootPath}/v2/api/purchase/quote/${id}`, {
                        method: "POST", body: postData,
                    })
                    .then((response) => response.json())
                    .then((res)=> {
                        if(res.error || !res.results){
                            console.log(`[Error] updating quote: ${res.error}`);
                            toast.error("Sorry, There was an issue updating this quote [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else { 
                            self.setActiveQuote(null);
                            self.getQuotes();

                            toast.success("Successfully updated quote", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                    }).catch((err) =>{
                        console.log("[Error] Updating Quote (E2)",err);
                    }).finally(()=> { self.setState({ loading: false }); });
                });
            }
        }
        catch(ex){
            console.log(`Error: Submitting PO: ${ex}`);
        }
    }

    getSubTextLines(text){
        let ret = [];
        try {
            ret = text.split(/(?:\r\n|\r|\n)/g);
        }
        catch(ex){
            console.log(`[Error] Seperating Lines: ${ex}`);
            ret = [text]
        }
        return ret;
    }

    componentDidMount(){ 
        this.getQuotes();
    }

    render(){  
        return(
            <div className="store-quotes">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className='quote-container'>
                    <h1>Your <span>Finalized Quotes</span></h1>

                    <div className='quote-table-container'>
                        <table>
                            <thead>
                                <tr className='header-row'>
                                    <th>Invoice #</th>
                                    <th className='flex-sz'>Creation Date</th>
                                    <th className='flex-sz'>Total Price</th>
                                    <th>Status</th>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.quoteList.map((item,i) =>
                                    <tr key={i}>
                                        <td>{item.invoice_number}</td>
                                        <td className='flex-sz'>{this.formatDate(item.creation_date)}</td>
                                        <td className='flex-sz'>{currencyFormat(item.total_price)}</td>
                                        <td>
                                            <div className={`status-pill ${item.status.toLowerCase().split(" ").join("_")}`}>{item.status}</div>
                                        </td>
                                        <td className='btn-container-cell'>
                                            {item?.status.toLowerCase() === "new" &&
                                                <div className='cell-btn' onClick={()=> this.setActiveQuote(item)}>
                                                    <i className="fas fa-cloud-upload-alt" />
                                                    <span>Upload Purchase Order</span>
                                                </div>
                                            }
                                            <a className='cell-btn' href={`${rootPath}/v2/api/download_invoice/${item._id}`} target='_blank'>
                                                <i className="fas fa-download" />
                                                <span>Download Invoice</span>
                                            </a>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Invoice Modal */}
                <Modal open={this.state.modal} onClose={()=>this.setState({ modal: false })}>
                    <div className="invoice-modal-body">
                        <div className='content-body'>
                            {this.state.activeQuote &&
                                <div className='invoice-container'>
                                    <div className='invoice-header'>
                                        <div className='header-section'>
                                            {(this.state.activeQuote?.org_info.length > 0 && this.state.activeQuote?.line_items.length > 0) ?
                                                <>
                                                    {(
                                                        (this.state.activeQuote?.line_items[0].type === "uniform" && this.state.activeQuote?.org_info[0]?.uniform_invoice_default_logo) ||
                                                        (this.state.activeQuote?.line_items[0].type != "uniform" && this.state.activeQuote?.org_info[0]?.league_invoice_default_logo)
                                                    ) ?
                                                        <img src={logo} /> : <div className='no-logo'/>
                                                    }
                                                    <div className="sender-details">
                                                        <h2>{this.state.activeQuote?.line_items[0].type === "uniform" ? 
                                                            `${this.state.activeQuote?.org_info[0]?.uniform_invoice_title ?? 'Lee Lee Kiddz.'}` : 
                                                            `${this.state.activeQuote?.org_info[0]?.league_invoice_title ?? 'Lee Lee Kiddz.'}`}
                                                        </h2>
                                                        <>
                                                            {this.getSubTextLines((
                                                                this.state.activeQuote?.line_items[0].type === "uniform" ? 
                                                                    this.state.activeQuote?.org_info[0]?.uniform_invoice_sub_text : 
                                                                    this.state.activeQuote?.org_info[0]?.league_invoice_sub_text
                                                                )).map((line,i) =>
                                                                    <span key={i}>{line}</span>
                                                                )
                                                            }
                                                        </>
                                                    </div>
                                                </> :
                                                <>
                                                    <img src={logo} />
                                                    <div className="sender-details" />
                                                </>
                                            }                                                    
                                        </div>

                                        <div className='header-section'>
                                            <div className='sender-details'>
                                                <h1>Quote</h1>
                                                <span>Quote Number: {this.state.activeQuote.invoice_number}</span>

                                                {this.state.activeQuote.status === "COMPLETED" ?
                                                    <span className='quote-date'>{format(new Date(this.state.activeQuote.status_date),'MM/dd/yyyy')}</span> :
                                                    <span className='quote-date'>{format(new Date(this.state.activeQuote.creation_date),'MM/dd/yyyy')}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='invoice-body'>
                                        <div className='billing-info'>
                                            <h3>Bill To</h3>
                                            {this.state.activeQuote?.account_info.length > 0 &&
                                                <>
                                                    <span>{this.state.activeQuote.account_info[0].name}</span>
                                                    <span>{this.state.activeQuote.account_info[0].org_name}</span>
                                                </>
                                            }
                                            {this.state.activeQuote?.org_info.length > 0 &&
                                                <>
                                                    <span>{this.state.activeQuote.org_info[0].org_address}</span>
                                                    <span>{this.state.activeQuote.org_info[0].org_city}, {this.state.activeQuote.org_info[0].org_state} {this.state.activeQuote.org_info[0].org_zip}</span>
                                                </>
                                            }
                                        </div>

                                        <div className='invoice-data-container'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Item #</th>
                                                        <th className='item-cell'>Item</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.activeQuote.line_items.map((item,i) =>
                                                        <tr key={i}>
                                                            <td className='ctr'>{i+1}</td>
                                                            <td className='item-cell info left'>
                                                                <p>{item.title}</p>
                                                                <div className='item-details'>
                                                                    {item?.type === "league" ?
                                                                        <>
                                                                            <div className='item-line'>
                                                                                <span>Start Date:</span>
                                                                                {item?.start_dt}
                                                                                <span className='spacer'>End Date:</span>
                                                                                {item?.end_dt}
                                                                            </div>
                                                                        </>:
                                                                        <></>
                                                                    }
                                                                    {item?.jersey_info?.team_name ? 
                                                                        <div className='item-line'>
                                                                            <span>Team Name:</span>
                                                                            {item?.jersey_info?.team_name}
                                                                        </div> 
                                                                        : <></>
                                                                    }
                                                                    
                                                                    {item?.jersey_info?.jersey_description ? 
                                                                        <div className='item-line'>
                                                                            <span>Notes:</span>
                                                                            {item?.jersey_info?.jersey_description}
                                                                        </div> 
                                                                        : <></>
                                                                    }
                                                                    
                                                                    <div className='line-note'>
                                                                        See Personalization Notes Below
                                                                        <span>{item?.line_item}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='right'>{item.sub_item_count}</td>
                                                            <td className='right'>{currencyFormat(item.item_price)}</td>
                                                            <td className='right'>{currencyFormat(item.sub_total)}</td>
                                                        </tr>
                                                    )}
                                                    
                                                    <tr className='empty-row'><td colSpan={5} /></tr>
                                                    <tr className='total-row'>
                                                        <td colSpan={3} />
                                                        <td className='right'>Total</td>
                                                        <td className='right'>{currencyFormat(this.state.activeQuote.total_price)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className='invoice-details'>
                                        <div className='section-title'>
                                            <h2>Item Details</h2>
                                            <p>The following details are assumed to be correct for spelling, size, details, and quantity.  Please contact us immediatly if anything is incorrect.</p>
                                        </div>

                                        <div className='detail-container'>
                                            {this.state.activeQuote?.line_items.filter((value,idx) => this.state.activeQuote?.line_items.map((li)=> li.line_item).indexOf(value.line_item) === idx).map((item,i) =>
                                                <div className='detail-line-container' key={i}>
                                                    <div className='detail-line' key={i}>
                                                        <div className='item-num'>{item?.line_item}</div>
                                                        <div className='img-container'>
                                                            {item?.jersey_info?.jersey_img ?
                                                                <img src={item.jersey_info.jersey_img} /> :
                                                                <span>No Image</span>
                                                            }
                                                        </div>
                                                        <div className='item-details'>
                                                            {item?.jersey_info?.team_name ? 
                                                                <div className='item-line'>
                                                                    <span>Team Name:</span>
                                                                    {item?.jersey_info?.team_name}
                                                                </div> 
                                                                : <></>
                                                            }

                                                            {item?.jersey_info?.jersey_description ? 
                                                                <div className='item-line'>
                                                                    <span>Notes:</span>
                                                                    {item?.jersey_info?.jersey_description}
                                                                </div> 
                                                                : <></>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='personalization-list'>
                                                        <table>
                                                            <thead>
                                                                <tr className='table-header'>
                                                                    {item?.detail_items?.map((di, j) =>
                                                                        <th key={j}>{di.title}</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item?.item_details?.map((detail,k) =>
                                                                    <tr key={k}>
                                                                        {item?.detail_items?.map((di, l) =>
                                                                            <td key={l}>{detail[di.title]}</td>
                                                                        )}
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='invoice-footer'>
                            <div className='form-item sz-4'>
                                <span className='title'>Purchase Order #</span>
                                <input type="text" name="purchase_order_number" value={this.state.purchase_order_number} onChange={this.handleFormChange}/>
                            </div>

                            <div className='form-item sz-4'>
                                <span className='title'>Upload Purchase Order</span>
                                <input className='file_selector' type="file" name="purchase_order_file" onChange={this.handleFileChange} multiple/>
                            </div>
                            <div className='submit-btn-container' onClick={()=> this.submitPO(this.state.activeQuote._id)}>
                                <div className='submit-btn'>Submit</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default Quotes;