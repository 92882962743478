import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import LoadSpinner from '../../shared/loadSpinner';
import { rootPath, parseToken } from '../../shared/utils';

const default_org = { 
    org_parent_name:"", org_address:"", org_city:"", org_state:"", org_zip:"", 
    uniform_invoice_title:"Lee Lee Kiddz",  league_invoice_title:"Lee Lee Kiddz",
    uniform_invoice_sub_text:`Kevin Ricks
Founder/Board Member
385 Jaybea Ct.
Glen Burnie, MD 21061`, 
    league_invoice_sub_text:`Kevin Ricks
Founder/Board Member
385 Jaybea Ct.
Glen Burnie, MD 21061`,
    uniform_invoice_default_logo: true, league_invoice_default_logo: true
};
const default_state = { 
    loading:false, form_type:"signin", admin_token:null, admin_key: "",
    purchase_org_list:[], sel_org_info:"", org_info: undefined,
    billing_default:"", purchase_user:{ email:"", name:"", org_name:"" },
    billing_areas: []  
}

const register_form_fields = [
    { "title":"Name", "name":"name", "sz":4 }, { "title":"School Name", "name":"org_name", "sz":6 }
];

const register_org_fields = [
    { "title":"Billing Organization", "name":"org_parent_name", "sz":4 }, 
    { "title":"Billing Address", "name":"org_address", "sz":6 },  
    { "title":"City", "name":"org_city", "sz":5 },
    { "title":"State", "name":"org_state", "sz":2 }, 
    { "title":"Zip", "name":"org_zip", "sz":3 },
];

class StoreSignin extends Component{
    constructor(props) { 
        super(props);
        this.state = {...default_state}

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.signIn = this.signIn.bind(this);
        this.register = this.register.bind(this);
        this.adminLogin = this.adminLogin.bind(this);
        this.getOrgList = this.getOrgList.bind(this);
        this.isAdminLogin = this.isAdminLogin.bind(this);
        this.getToggleList = this.getToggleList.bind(this);
    }

    handleFormChange(e, type){
        try {
            let tmpData = {...this.state[type] }, name = e.target.name, toggleToken = false;

            if(name in tmpData) {
                if(e.target.type === "number"){
                    tmpData[name] = e.target.value.split('.').join("");
                }
                else {
                    tmpData[name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
                }

                if(name === "email" && this.isAdminLogin()){
                    toggleToken = true;
                }

                this.setState({ [type]: tmpData, ...(toggleToken ? { admin_token: null } : {}) });
            }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    handleChange(e){
        try {
            let name = e.target.name;
            
            let update_form = {[name]: e.target.value };

            if(name === "sel_org_info") {
                let idx = parseInt(e.target.value);
                update_form["org_info"] = (idx == this.state.purchase_org_list.length - 1 ? 
                        {...default_org } : this.state.purchase_org_list[idx].value
                );
            }

            this.setState({ ...update_form });
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    signIn(){
        try{
            let form_validation = validateForm("signin", this.state.purchase_user), self = this;

            if(form_validation.length > 0){
                toast.warning("Please Enter In Valid Email Address", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark", });
            }
            else if(this.isAdminLogin()){
                toast.warning("Please Enter Your Password Token & Then Use The Lock Button", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                    draggable: true, progress: undefined, theme: "dark", });
            }
            else {
                this.setState({ loading: true}, ()=>{
                    let postData = { email: this.state.purchase_user.email };
                    axios.post(`${rootPath}/v2/api/auth/login`, postData, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] League Store Sign-in (E1): ", response.data.error);
                                toast.error(`${response.data.error}`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                            }
                            else {
                                if(response.data.results?.type === "user"){
                                    self.props.userHandler(response.data.results?.user, function(){ 
                                        self.setState({ ...default_state }); 
                                    });
                                }
                                else {
                                    self.setState({ admin_token: response.data.results?.user}, ()=> {
                                        toast.info(`Complete Admin Login`, { position: "top-right",
                                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                            draggable: true, progress: undefined, theme: "dark", });
                                    });                                                                        
                                }
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`Error with Signin: ${ex}`);
            toast.error("Sorry, There was an issue attempting to sign-in [Please Contact Site Admin]", { position: "top-right",
                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                draggable: true, progress: undefined, theme: "dark", });
        }
    }

    register(){
        try{
            let form_validation = validateForm("register", this.state.purchase_user), 
                org_form_validation = validateForm("org_info", this.state.org_info),self = this;

            if(form_validation.length > 0 || org_form_validation.length > 0){
                toast.warning(`Please Check The Following Fields ${form_validation.join(",")} ${org_form_validation.join(",")}`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark", });
            }
            else {
                this.setState({ loading: true}, ()=>{
                    let postData = { 
                        ...self.state.purchase_user, 
                        org_info: self.state.org_info, 
                        billing_default: self.state.billing_default 
                    };
                    
                    axios.post(`${rootPath}/v2/api/auth/signup`, postData, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] League Store Sign-up (E1): ", response.data.error);
                                toast.error(`${response.data.error}`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                            }
                            else {
                                toast.success(`Successfully Registered Email Address, Sign-in To Proceed`, { position: "top-right",
                                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                    draggable: true, progress: undefined, theme: "dark", });
                                self.setState({ form_type:"signin" });
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`Error with Sign-up: ${ex}`);
            toast.error("Sorry, There was an issue attempting to sign-up [Please Contact Site Admin]", { position: "top-right",
                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                draggable: true, progress: undefined, theme: "dark", });
        }
    }

    adminLogin(){
        let self = this;
        try {
            let { localUser } = parseToken(self.state.admin_token);

            this.setState({ loading: true}, ()=>{ 
                let postData = { loginType: "base", aUser: { userId: localUser.userId, basePin: self.state.admin_key } };
                axios.post(rootPath + "/api/auth/login", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Logging In: ", response.data.error);
                            alert(response.data.error);
                        }
                        else if(response.data.results){
                            self.props.userHandler(response.data.results, function(){ 
                                self.setState({ ...default_state }); 
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] With Login (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`Error with admin login: ${ex}`);
        }
    }

    getOrgList(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                axios.get(`${rootPath}/v2/api/purchase/organization`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting config data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving organizations [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            let tmp_org_list = [{ "name": "Add New Billing Address", "value": default_org }]
                            response.data.results.forEach((item)=>{
                                tmp_org_list.push({ "name":item.org_parent_name, "value": item });
                            });

                            // Set State
                            self.setState({ purchase_org_list: tmp_org_list });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Organizations (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Organizations: ${ex}`);
        }
    }

    isAdminLogin(){
        return (this.state.form_type === "signin" && this.state.admin_token != null);
    }

    getToggleList() { 
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                axios.get(`${rootPath}/v2/api/merchant`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(`[Error] Getting Toggles: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving toggle list [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            let tmp_billing_area = [
                                { "name": "N/A", "key":""},
                                ...response.data.results
                            ]
                            self.setState({ billing_areas: tmp_billing_area });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Toggles (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Toggles: ${ex}`);
        }
    }

    componentDidMount(){ 
        this.getOrgList();
        this.getToggleList();
    }

    render(){  
        return(
            <div className="store-sign-in">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className='signin-container'>
                    <div className='container-img styled-background' />
                    <div className='container-content'>
                        <h1>League Store <span>{this.state.form_type === "signin" ? "Sign-in" : "Register"}</span></h1>
                        <div className='content-form'>
                            <div className='form-item sz-10'>
                                <span className='title'>Email</span>
                                <input type="text" name="email" value={this.state.purchase_user.email} onChange={(e)=> this.handleFormChange(e,"purchase_user")}/>
                            </div>

                            {this.state.form_type === "register" &&
                                <>
                                    {register_form_fields.map((item,i) =>
                                        <div className={`form-item sz-${item.sz}`} key={i}>
                                            <span className='title'>{item.title}</span>
                                            <input type="text" name={`${item.name}`} value={this.state.purchase_user[item.name]} onChange={(e)=> this.handleFormChange(e,"purchase_user")}/>
                                        </div>
                                    )}
                                    <div className={`form-item sz-10 spacer`}>
                                        <span className='title'>Select Organization Address</span>
                                        <select name="sel_org_info" value={this.state.sel_org_info} onChange={this.handleChange}>
                                            <option hidden>Select a billing address</option>
                                            {this.state?.purchase_org_list?.map((op, l) =>
                                                <option value={l} key={l}>{op.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    {this.state.org_info &&
                                        <>
                                            {register_org_fields.map((item,i) =>
                                                <div className={`form-item sz-${item.sz}`} key={i}>
                                                    <span className='title'>{item.title}</span>
                                                    <input type="text" name={`${item.name}`} value={this.state.org_info?.[item.name]} onChange={(e)=> this.handleFormChange(e,"org_info")} disabled={(this.state?.org_info?._id ? "disabled" : "")}/>
                                                </div>
                                            )}

                                            <div className={`form-item sz-10`}>
                                                <span className='title ctr'>Is your billing address within the following?</span>

                                                <div className='address-toggles'>
                                                    {this.state.billing_areas.map((ba,i)=>
                                                        <div className={`address-toggle ${ba.key === this.state.billing_default ? 'active' : ''}`} 
                                                            key={i} onClick={()=> { this.setState({ billing_default: ba.key }); }}
                                                        >
                                                            <div className='sel' />
                                                            <span>{ba.name}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }                                   
                                </>
                            }

                            {(this.isAdminLogin()) &&
                                <>
                                     <div className='form-item sz-9'>
                                        <span className='title'>Token</span>
                                        <input type="password" name="admin_key" value={this.state.admin_key} onChange={this.handleChange}/>
                                    </div>
                                    <div className='form-item sz-1'>
                                        <div className='sign-in-btn' onClick={this.adminLogin}><i className="fas fa-unlock-alt" /></div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className='button-container'>
                            {this.state.form_type === "signin" ? 
                                <div className={`sign-btn c2 ${this.isAdminLogin() ? 'disable' : ''}`} onClick={this.signIn}>Sign-in</div> : 
                                <div className='link-text'>Already have an account? <span className='c2' onClick={()=> this.setState({ form_type: "signin" })}>Lets Sign-in</span></div>
                            }

                            <div className='default-txt'>Or</div>

                            {this.state.form_type === "signin" ? 
                                <div className='link-text'>New To Our League Store? <span className='c4' onClick={()=> this.setState({ form_type: "register" })}>Register New Account</span></div> : 
                                <div className='sign-btn c4' onClick={this.register}>Register</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default StoreSignin;

function validateForm(type, form) {
    let ret = [];
    try {
        let fields = { 
            "signin": [{ "name":"email", "type": "email" }],
            "register":[
                { "name":"email", "type": "email" }, { "name":"name", "type": "text" }, { "name":"org_name", "type": "text" }
            ],
            "org_info":[
                { "name":"org_parent_name", "type": "text" }, { "name":"org_address", "type": "text" },  
                { "name":"org_city", "type": "text" }, { "name":"org_state", "type": "text" }, { "name":"org_zip", "type": "text" }
            ]
        }

        if(type in fields){
            for(let i =0; i < fields[type].length; i++){
                let field_item = fields[type][i];
                switch(field_item.type){
                    case "text":
                        if(form[field_item.name]?.length <= 0){ ret.push(field_item.name);}
                        break;
                    case "email":
                        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                        if(form[field_item.name]?.length <= 0 || !form[field_item.name].match(validRegex)){
                            ret.push(field_item.name);
                        }
                        break;
                    default:
                        break;

                }
            }
        }
        else { ret.push("error"); }
    }
    catch(ex){
        console.log(`[Error] Validating Form: ${ex}`);
        ret.push("error");
    }
    return ret;
}