import React, { Component } from 'react';
import axios from 'axios';

import LoadSpinner from '../../shared/loadSpinner';

const statsFormData = [
    { section: "Quarterbacks", dataFields:[
        { title:"Passing TD", fieldKey:"qTd", default: 0 },
        { title:"Interceptions", fieldKey:"qInt", default: 0 }
    ]},
    { section: "Receiver/Backs", dataFields:[
        { title:"Receiving TD", fieldKey:"recTd", default: 0 },
        { title:"Rushing TD", fieldKey:"rushTd", default: 0 },
        { title:"1PT Conversion", fieldKey:"conv1", default: 0 },
        { title:"2PT Conversion", fieldKey:"conv2", default: 0 }
    ]},
    { section: "Defense", dataFields:[
        { title:"Defensive INT", fieldKey:"dInt", default: 0 },
        { title:"Sacks", fieldKey:"dSack", default: 0 },
        { title:"Safety", fieldKey:"dSafety", default: 0 },
        { title: "Defensive TD", fieldKey:"dTd", default: 0 }
    ]}
];

const saveStatus = { 
    0: { "title": "up to date", "icon":"fas fa-check"}, 
    1: { "title": "saving", "icon":"fas fa-sync-alt"}, 
    2: { "title": "pending changes", "icon":"fas fa-exclamation-circle"} 
};

var saverInt = null, updateFields = {};

class StatCollectorTracker extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false, selectedTeam: null,
            selectedRoster: [], selectedPlayer: null,
            saveStatus:0, statData:{}
        }

        this.emptyForm = this.emptyForm.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.dateText = this.dateText.bind(this);
        this.getTeamRoster = this.getTeamRoster.bind(this);
        this.arrayBuilder = this.arrayBuilder.bind(this);
        this.activateSave = this.activateSave.bind(this);
        this.saveStats = this.saveStats.bind(this);
        this.getUserStats = this.getUserStats.bind(this);

        /* References */
        this.statFormRef = React.createRef();
    }

    emptyForm(){
        var self = this;
        try {
            var tmpForm = {};
            for(var i=0; i < statsFormData.length; i++){
                for(var j=0; j < statsFormData[i].dataFields.length; j++){
                    tmpForm[statsFormData[i].dataFields[j].fieldKey] = statsFormData[i].dataFields[j].default;
                }
            }

            this.setState({ statData: tmpForm }, ()=>{
                if(self.statFormRef) {self.statFormRef.current.scrollTop = 0;}
            });
        }
        catch(ex){
            console.log(`[Error] Creating Stat Form: ${ex}`);
        }
    }

    updateForm(field, val) {
        var self = this;
        try {
            var tmpDisplay = this.state.statData;
            if(val > 0 || (val < 0 && tmpDisplay[field] > 0)){
                tmpDisplay[field]+= val;

                if(field in updateFields) {
                    updateFields[field] += val;
                }
                else {
                    updateFields[field] = val;
                }

                if( updateFields[field] === 0) { delete updateFields[field]; }

                this.setState({ statData: tmpDisplay, saveStatus: 2  }, (_=> {
                    self.activateSave();
                }))
            }
        }
        catch(ex){
            console.log(`[Error] Updating Form: ${ex}`);
        }
    }

    dateText(text){
        var ret = "";
        try {
            var d = new Date(parseInt(text));
            
            const dayList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            monthList = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            
            var h = d.getHours(), m = d.getMinutes(), 
            time = (h > 12 ? h-12 : h) + ":" + (m < 10 ? "0"+m : m) + (h > 12 ? " PM" : " AM");

            ret = `${dayList[d.getDay()]}, ${monthList[d.getMonth()]}. ${d.getDate()} : ${time}`;
        }
        catch(ex){
            console.log("[Error] Minimizing Text: ", ex);
        }

        return ret;
    }

    getTeamRoster(teamId){
        var self = this;
        try {
            if(this.state.saveStatus === 0){
                this.setState({ selectedPlayer: null, selectedRoster:[], selectedTeam: teamId, loading: true }, ()=>{
                    axios.get(self.props.rootPath + `/api/league/teams/${self.props.selLeague.id}/roster/${teamId}`, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error){
                                console.log(" [Error] Getting Team Roster (E1): ", response.data.error);
                            }
                            else {
                                self.setState({ selectedRoster: response.data.results });
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`[Error] Getting Team Roster: ${ex}`);
        }
    }

    arrayBuilder(sz){
        var ret = [];
        try {
            for(var i=0; i < sz; i++){ ret.push(1); }
        }
        catch(ex){
            console.log(`[Error] Building Array: ${ex}`);
        }

        return ret;
    }

    activateSave(){
        var self = this;
        try {
            if(saverInt != null) {
                clearTimeout(saverInt); saverInt = null;
            }

            if(Object.keys(updateFields).length > 0){
                saverInt = setTimeout(function(){ 
                    self.saveStats();
                }, 1000);
            }
            else {
                self.setState({ loading: false, saveStatus: 0 });
            }
        }
        catch(ex){
            console.log(`[Error] Activating Save: ${ex}`);
        }
    }

    saveStats(){ 
        var self = this;
        try {
            this.setState({loading: true, saveStatus: 1}, () => {
                var saveList = Object.keys(updateFields);
                var token = localStorage.getItem(self.props.mySessKey);

                if(!token){
                    self.setState({ loading: false, saveStatus: 2 });
                }
                else if(saveList.length === 0){
                    self.setState({ loading: false, saveStatus: 0 });
                }
                else {
                    var teamName = (self.props.selectedGame.team1Id === self.state.selectedTeam ? self.props.selectedGame.team1 : self.props.selectedGame.team2),
                        oppName = (self.props.selectedGame.team1Id === self.state.selectedTeam ? self.props.selectedGame.team2 : self.props.selectedGame.team1);
                    
                    var postData = {
                            "_id": (self.state.statData._id ? self.state.statData._id : null), 
                            "playerId": self.state.selectedPlayer.userId, "playerName": `${self.state.selectedPlayer.firstName} ${self.state.selectedPlayer.lastName}`, 
                            "leagueId": self.props.selLeague.id, "leagueName":self.props.selLeague.name, "leagueDate":self.props.selLeague.startDate, 
                            "gameId": self.props.selectedGame.gameId, "gameTime":self.props.selectedGame.gameTime, "type":self.props.selectedGame.type,
                            "teamName": teamName, "oppName": oppName, "updateFields":updateFields
                        };
                        
                    axios.post(self.props.rootPath + "/api/stats/player/update", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){
                                console.log("[Error] Updating Player Stats: ", response.data.error);
                                alert(`Error updating player stats: ${response.data.error}`);
                            }
                            else { updateFields = {}; }
                        }).then(function(){
                            self.getUserStats(self.state.selectedPlayer);                                    
                        });             
                }
            });
        }
        catch(ex){
            console.log(`[Error] Saving Stats: ${ex}`); 
        }
    }

    getUserStats(user){
        var self = this;
        try {
            var newUser = (this.state.selectedPlayer == null || user._id != this.state.selectedPlayer._id);
            this.setState({ loading: true, saveStatus: 0, selectedPlayer: user }, ()=>{
                var postData = {"playerId": user.userId , "leagueId":self.props.selLeague.id, "gameId": self.props.selectedGame.gameId};
                updateFields = {};
                axios.post(self.props.rootPath + "/api/stats/player/game", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error === "no stats") {  
                            self.emptyForm();
                        }
                        else if(response.data.error){
                            console.log("[Error] Getting Player Stats: ", response.data.error);
                        }
                        else {
                            self.setState({ statData: response.data.results },() => {
                                if(self.statFormRef && newUser) {self.statFormRef.current.scrollTop = 0;}
                            });
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting User Stats: ${ex}`); 
        }
    }

    componentDidMount(){  
        window.scrollTo(0, 0);
    }

    render(){  
        return(
            <div className="stat-tracker-content stat-collector">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className="nav-container team-selection-container">
                    <div className={"team-btn" + (this.state.selectedTeam === this.props.selectedGame.team1Id ? " sel": "")} onClick={()=> this.getTeamRoster(this.props.selectedGame.team1Id)}>{this.props.selectedGame.team1}</div>
                    <div className={"team-btn" + (this.state.selectedTeam === this.props.selectedGame.team2Id ? " sel": "")} onClick={()=> this.getTeamRoster(this.props.selectedGame.team2Id)}>{this.props.selectedGame.team2}</div>
                </div>
                <div className="nav-container date">
                    {this.dateText(this.props.selectedGame.gameTime)}
                    <div className="save-status-text">
                        <i className={saveStatus[this.state.saveStatus].icon} />
                        <span>{saveStatus[this.state.saveStatus].title}</span>
                    </div>
                </div>
                <div className="nav-container players">
                    {this.state.selectedPlayer != null &&
                        <div className="league-player sel-display">
                            <i className="far fa-user-circle" />
                            <span>{this.state.selectedPlayer.firstName} {this.state.selectedPlayer.lastName}</span>
                        </div>
                    }

                    {this.state.selectedRoster.length === 0 ? 
                        <>
                            {this.arrayBuilder(12).map((item,i) => 
                                <div className="empty-player" key={i}/>
                            )}
                        </> :
                        <div className={"player-container" + (this.state.selectedPlayer != null ? " sel-player-container" :"")}>
                            {this.state.selectedRoster.map((item,i) => 
                                <div className={"league-player" + (this.state.selectedPlayer?.userId === item.userId ? " sel" : "")} key={i} onClick={()=> this.getUserStats(item)}>
                                    <i className="far fa-user-circle" />
                                    <span>{item.firstName} {item.lastName}</span>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div className="stat-form" ref={this.statFormRef}>
                    {this.state.selectedPlayer === null && <div className="no-message">Please select a <span>team</span> and a <span>player</span> to start editing player <span>game stats</span></div>}
                    {this.state.selectedPlayer != null && (Object.keys(this.state.statData).length > 0) && statsFormData.map((statSection, i) =>
                        <div className="stat-section" key={i}>
                            <h3>{statSection.section}</h3>
                            {statSection.dataFields.map((fields, j) =>
                                <div className="stat-field" key={j}>
                                    <div className="field-title">
                                        <div className={"field-ctrl" + (this.state.statData[fields.fieldKey] === 0 ? " disabled" : "")} onClick={()=> this.updateForm(fields.fieldKey,-1)}><i className="fas fa-minus"/></div>
                                        <span>{fields.title}</span>
                                        <div className="field-ctrl" onClick={()=> this.updateForm(fields.fieldKey,1)}><i className="fas fa-plus"/></div>
                                    </div>

                                    <div className="field-list">
                                        {this.arrayBuilder(this.state.statData[fields.fieldKey]).map((datapt,k) =>
                                            <div className="data-chip" key={k} />
                                        )}
                                        <div className="data-val">{this.state.statData[fields.fieldKey]}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default StatCollectorTracker;