import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';

import LoadSpinner from './loadSpinner';

class PlayerSearch extends Component{
    constructor(props) {
        super(props);
        this.state = {
            minSearchLength: 0, newPlayerModal: false,
            search:"", resultsMax:20, loading: false,
            searchResults:[], spinner: false,
            posList:["QB","WR","CB","LB","FS","RS"],
            playerForm:{ "name":"", "email":"", "gender":"M", "positions":"", "positionList":[], "number":"0"}
        }

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.addNewPlayer = this.addNewPlayer.bind(this);
        this.toggleSpinner = this.toggleSpinner.bind(this);
        this.setPlayer = this.setPlayer.bind(this);
    }

    handleSelectChange(e){
        try {
            var tmpData = this.state.playerForm;
            tmpData.positionList = e.target.value;
            this.setState({ playerForm: tmpData });
        }
        catch(ex){
            console.log("[Error] Handling Select Change: ",ex);
        }
    }

    handleFormChange(e){
        try {
            var tmpData = this.state.playerForm;
            var name = e.target.name;

            if(name in tmpData) {
                if(e.target.type === "number"){
                    tmpData[name] = e.target.value.split('.').join("");
                }
                else {
                    tmpData[name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
                }
                this.setState({ playerForm: tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on form change: ",ex);
        }
    }

    handleChange(e){
        var self = this;
        try {
            var query = e.target.value;
            this.setState({[e.target.name]: e.target.value }, ()=> {
                if(self.state.search.length >= self.state.minSearchLength){
                    self.toggleSpinner(true);
                    var token = localStorage.getItem(self.props.mySessKey);
                    var postData = { "query":query, "maxSize": self.state.resultsMax };
                    axios.post(self.props.rootPath + "/api/league/player/search", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){ 
                            console.log(response.data.error);
                            self.setState({ error: response.data.error, searchResults: [] });
                        }
                        else {
                            self.setState({ searchResults: response.data.results });
                        }
                        self.toggleSpinner(false);
                    });  
                }
            });
        }
        catch(ex){
            console.log("[Error] handling change: ",ex);
        }
    }

    validateForm(){
        var ret = false;

        try{
            var tmpForm = this.state.playerForm;
            var missingData = [];

            if(tmpForm.name == null || tmpForm.name.length <= 0) { missingData.push("Name");}
            if(tmpForm.email == null || tmpForm.email.length <= 0) { missingData.push("Email");}
            if(tmpForm.positionList == null) { missingData.push("Positions");}
            if(tmpForm.gender == null || tmpForm.gender.length <= 0) { missingData.push("Gender");}
            
            if(missingData.length <= 0) { ret = true;}
            else {
                alert("Unable to add this player please check: " + missingData.join(", "));
            }
        }
        catch(ex){
            console.log("[Error] Validating Form: ",ex);
        }

        return ret;
    }

    addNewPlayer(){
        var self = this;
        try {
            if(this.validateForm()){
                this.setState({loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    var postData = { "player": self.state.playerForm };
                    postData.player.positions = postData.player.positionList.join(",");

                    axios.post(self.props.rootPath + "/api/league/player/add", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){ 
                                alert("Error Adding Player: ", response.data.error);
                            }
                            else {
                                var newName = self.state.playerForm.name;
                                self.setState({ newPlayerModal: false, playerForm:{ "name":"", "email":"", "number":"0", "gender":"M", "positions":"", "positionList":[]} }, ()=>{
                                    self.handleChange({ target: { name: "search", value: newName }});
                                    if(self.props.addCB != null) { self.props.addCB();}
                                });
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        });  
                });
            }
        }
        catch(ex){
            console.log("[Error] adding new player: ",ex);
        }
    }

    toggleSpinner(status){
        this.setState({spinner: status });
    }

    setPlayer(player){
        var self = this;     
        this.setState({ search: "" }, ()=>{
            try {
                self.props.selectAction(player);
            }
            catch(ex){
                console.log("Error setting player user: ",ex);
            }
        });          
    }

    componentDidMount(){ }
    
    render(){  
        return(
            <div className="search-container">
                <i className="fas fa-search" />
                <input name="search" type="text" className="search-bar" autoComplete="off" value={this.state.search} onChange={this.handleChange} />

                {this.state.search.length > this.state.minSearchLength && 
                    <div className={"results-container" + (this.state.spinner ? " loading":"")}>
                        <div className="result-item add" onClick={() => this.setState({ newPlayerModal: true })}>
                            <i className="fas fa-user-plus" />
                            <span>Add Player</span>
                        </div>
                        {this.state.searchResults.map((item,i) => 
                            <div className="result-item" key={i} onClick={()=> this.setPlayer(item) }><span>{item.name}</span></div>
                        )}
                        {this.state.spinner && <div className="loader-container"><div className="hm-spinner mini" /></div> }
                    </div>
                }

                <Modal open={this.state.newPlayerModal} onClose={()=>this.setState({ newPlayerModal: false })}>
                    <div className="modal-body c1">
                        {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                        <i className="close-modal fas fa-times" onClick={()=>this.setState({ newPlayerModal: false })}/>
                        <h1>Add New League Player</h1>

                        <div className="editor-form">
                            <div className="form-element sz-5 cm">
                                <div className="element-title">Name</div>
                                <input type="text" name="name" value={this.state.playerForm.name} onChange={this.handleFormChange}/>                                                    
                            </div>

                            <div className="form-element sz-5 cm">
                                <div className="element-title">Email</div> 
                                <input type="text" name="email" value={this.state.playerForm.email} onChange={this.handleFormChange}/>                                                             
                            </div>

                            <div className="form-element sz-3 cm">
                                <div className="element-title">Gender</div>  
                                <select name="gender" value={this.state.playerForm.gender} onChange={this.handleFormChange}>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>                                                  
                            </div>

                            <div className="form-element sz-3 cm">
                                <div className="element-title">Jersey #</div> 
                                <input type="text" name="number" value={this.state.playerForm.number} onChange={this.handleFormChange}/>                                                             
                            </div>

                            <div className="form-element sz-4 cm">
                                <div className="element-title">Positions</div>
                                <FormControl className="form-element-control">
                                    <Select multiple name="positions" value={this.state.playerForm.positionList}
                                        onChange={this.handleSelectChange} input={<Input />} 
                                        renderValue={(selected) => selected.join(', ')}>
                                        {this.state.posList.map((name,j) => (
                                            <MenuItem key={j} value={name}>
                                                <Checkbox checked={this.state.playerForm.positionList.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="btn-container">
                            <div className="link-btn cg4" onClick={this.addNewPlayer}><i className="fas fa-user-plus" />Add Player</div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default PlayerSearch;