export const rootPath = ( window.location.href.indexOf("localhost") > -1  ? "http://localhost:3714" : "");
export const mySessKey = "leeleeKidzDraftTourney_.";
export const storeKey = "leeleeKidz|league|store";

export const colourStyles = {
    control: (styles) => ({ 
        ...styles, backgroundColor: '#000', borderRadius: '50px', boxShadow: 'none !important', 
        '&:hover':{ borderColor: '#ee246e' },
        '&:focus':{ boxShadow: 'none', borderColor: '#ee246e' } 
    }),
    option: (styles, { isFocused }) => ({ 
        ...styles, backgroundColor: isFocused ? 'rgba(238,36,110,0.4)' : 'none' ,
        color: '#000', textTransform: 'capitalize'
    }),
    input: (styles) => ({ ...styles, backgroundColor: '#000', color:'#fff' }),
    singleValue: (styles) => ({ ...styles, color:'#fff', zIndex:1 }),
  
};

export function currencyFormat(price) {
    let ret = "";
    try {
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency', currency: 'USD',
        });

        ret = USDollar.format(price);
    }
    catch(ex){
        console.log(`Error Formatting Value: ${ex}`);
    }
    return ret;
}

export function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}