import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';

class PlayerBoard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, draftPlayerModal: false, selectedPlayer: null
        }

        this.filterPlayers = this.filterPlayers.bind(this);
        this.draftPlayer = this.draftPlayer.bind(this);
        this.setPlayer = this.setPlayer.bind(this);
    }

    filterPlayers(list){
        var ret = [], self = this;

        try {
            ret = list.filter(function(player){
                var genderFilter = (self.props.genderFilter && (self.props.genderFilter == "all" || player.gender.toLowerCase() === self.props.genderFilter.toLowerCase()));
                var positionFilter = (self.props.positionFilter && (self.props.positionFilter == "all" || player.basePosition.toLowerCase() === self.props.positionFilter.toLowerCase()));

                return (!("draftedPos" in player && player.draftedPos >= 0) && genderFilter && positionFilter);
            });
        }
        catch(ex){
            console.log("[Error] Filtering List: ",ex);
        }

        return ret;
    }

    draftPlayer(){
        var self = this;
        this.setState({ draftPlayerModal: false }, ()=>{
            self.props.draftPlayer(self.state.selectedPlayer);
        });
    }

    setPlayer(player){     
        this.setState({ selectedPlayer: player, draftPlayerModal: true });          
    }
    
    componentDidMount(){ }
    
    render(){  
        return(
            <div className="player-board-container">
                <div className="board-title">Avaliable Players</div>
                <div className="player-filter-container">
                    {this.filterPlayers(this.props.playerList).map((player, i) =>
                        <div className={"player-pick pos-"+ player.basePosition} key={i} onClick={()=> this.setPlayer(player) }>
                            <h2>{player.displayFirst}</h2>
                            <h1>{player.displayLast}</h1>
                            <div className="basePos">{player.basePosition} <span>{player.gender}</span></div>
                        </div>
                    )}
                </div>

                <Modal open={this.state.draftPlayerModal} onClose={()=>this.setState({ draftPlayerModal: false })}>
                    <div className="modal-body c1">
                        {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                        {this.state.selectedPlayer != null &&
                            <div className="player-card">
                                <i className="close-modal fas fa-times" onClick={()=>this.setState({ draftPlayerModal: false })}/>
                                <div className="player-info">
                                    <div className="info-section">
                                        <h2>{this.state.selectedPlayer.displayFirst}</h2>
                                        <h1>{this.state.selectedPlayer.displayLast}</h1>
                                        <div className="pos-list">{this.state.selectedPlayer.positions}</div>

                                        {this.state.selectedPlayer.highlights && <a href={this.state.selectedPlayer.highlights} target="_blank" className="player-highlights"><i className="fas fa-football-ball"/> Player Highlights</a>}
                                    </div>
                                    <div className="icon-section">
                                        {this.state.selectedPlayer.photo ? 
                                            <img src={this.state.selectedPlayer.photo} /> :
                                            <i className="far fa-user" />
                                        }
                                    </div>
                                </div>
                                {this.props.draftAdmin &&
                                    <div className="player-draft">
                                        <div className="team-info">
                                            <div className="logo-container"><img src={this.props.curPick.logo} /></div>
                                            <div className="pick-info">
                                                <div className="team-name" style={{ color: "rgba("+this.props.curPick.color.r+","+this.props.curPick.color.g+","+this.props.curPick.color.b+",1)" }}>{this.props.curPick.name}</div>
                                                <div className="team-pick">
                                                    <span>RND {this.props.curPick.round}</span>
                                                    <span>PK {this.props.curPick.pick}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="draft-btn-container">
                                            <div className="link-btn cg3" onClick={this.draftPlayer}><i className="fas fa-user-plus" />Draft Player</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}
export default PlayerBoard;