import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';

import LoadSpinner from './loadSpinner';

class ImageUploader extends Component{
    constructor(props) {
        super(props);
        this.state = {
            imgModal: false,  loading: false, scrollPos: 0,
            uploadImg: null, displayImg: null, photoList:[] ,
            responsivePhoto: { 0: { items: 1 }, 600: { items: 2 }, 1024: { items: 3 }},        
        }

        this.selectImg = this.selectImg.bind(this);
        this.selectUpload = this.selectUpload.bind(this);
        this.moveCtrl = this.moveCtrl.bind(this);
        this.displayScroll = this.displayScroll.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.getPhotos = this.getPhotos.bind(this);

        // References
        this.fileUpload = React.createRef();
        this.logoCarousel = React.createRef();
    }

    selectImg(img){
        var self = this;
        try {
            this.setState({ imgModal: false }, ()=> {
                self.props.selectAction(img);
            });
        }
        catch(ex){
            console.log("[Error] Selecting Photo: ",ex);
        }
    }
    selectUpload(){
        //this.fileUpload.current.click();
        document.getElementById("imgFile").click();
    }

    moveCtrl(dir){
        var scrollInt = 450;
        try {  
            if(this.logoCarousel && this.logoCarousel.current) {
                var tmpPos = this.logoCarousel.current.scrollLeft + (scrollInt * dir);
                this.logoCarousel.current.scrollLeft = tmpPos;
                this.setState({ scrollPos: tmpPos });     
            }           
        }
        catch(ex){
            console.log("[Error] Moving Direction: ",ex);
        }
    }

    displayScroll(dir){
        var ret = false;
        try {  
            if(this.logoCarousel && this.logoCarousel.current) {
                if(dir == -1) {
                    ret = (this.state.scrollPos > 0);
                }                  
                else {
                    ret = (this.logoCarousel.current.clientWidth + this.state.scrollPos < this.logoCarousel.current.scrollWidth);
                }
            }
        }
        catch(ex){
            console.log("[Error] Moving Direction: ",ex);
        }

        return ret;
    }

    handleInputChange(e){
        var self = this;
        try {
            var file = e.target.files[0], reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => { 
                self.setState({ uploadImg: file }, () =>{ self.uploadFile(); }); 
            };
        }
        catch(ex){
            console.log("[Error] changing image: ",ex);
        }
    }

    uploadFile(){
        var self = this;
        try {
            if(this.state.uploadImg != null){
                let imageFormObj = new FormData();
                imageFormObj.append("imageName", "multer-image-" + Date.now());
                imageFormObj.append("imageData", this.state.uploadImg);

                this.setState({loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    axios.post(self.props.rootPath + "/api/image/upload", imageFormObj, {headers:{'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){ 
                                alert("Error Uploading Image: ", response.data.error);
                            }
                            else {
                                self.getPhotos();
                            }
                        }).then(function(){
                            self.setState({ loading: false });
                        });  
                });
            }
        }
        catch(ex){
            console.log("[Error] uploading file: ",ex);
        }
    }

    getPhotos(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                axios.get(self.props.rootPath + "/api/image/getAll", {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(!response.data.error){
                       self.setState({ photoList: response.data.results });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] getting images: ",ex);
        }
    }

    componentDidMount(){ this.getPhotos(); }

    render(){  
        return(
            <div className="img-uploader-container">
                <div className="img-btn" onClick={() => this.setState({ imgModal: true })}><i className="far fa-images" /></div>
            
                <Modal open={this.state.imgModal} onClose={()=>this.setState({ imgModal: false })}>
                    <div className="modal-body">
                        {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                        <h1>Image Selector</h1>
                        <p>Select a previously uploaded logo or upload a new logo</p>
                        <div className="img-modal-body">
                            <div className="img-upload" onClick={this.selectUpload}>
                                <div><i className="fas fa-cloud-upload-alt" /> Upload A Logo</div>
                                <input type="file" className="img-input" hidden name="imgFile" id="imgFile" accept="image/*" onChange={this.handleInputChange} ref={this.fileUpload} />
                            </div>

                            <div className="img-list">
                                {this.state.photoList.length > 0 && <div className={"ctrl-item" + (this.displayScroll(-1) ? "" : " disable")} onClick={() => this.moveCtrl(-1)}><i className="fas fa-chevron-left"/></div> }
                                <div className="item-container" ref={this.logoCarousel}>
                                    {this.state.photoList.map((image,i) => 
                                        <div className="photo-item" key={i} onClick={() => this.selectImg(image)}>
                                            <img src={image} alt={"LeeLee Logo Photo "+i}/> 
                                        </div>
                                    )}
                                </div>  
                                {this.state.photoList.length > 0 && <div className={"ctrl-item" + (this.displayScroll(1) ? "" : " disable")} onClick={() => this.moveCtrl(1)}><i className="fas fa-chevron-right"/></div> }
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default ImageUploader;