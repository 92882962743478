import React, { Component } from 'react';
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import { toast } from 'react-toastify';
import { format, parseISO } from "date-fns";

import { currencyFormat, rootPath } from '../../shared/utils';
import size_chart from '../../../assets/imgs/size_chart.png';
import LoadSpinner from '../../shared/loadSpinner';

const PAGE_SZ = 10, store_config = {
    "league":{
        "title":"Leagues", itemList:[]

    },
    "uniform":{
        "title":"Uniforms", itemList:[]
    }
}

class Store extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            page: 0, max_page: 1, open_chart: false,
            title:null, itemList:[], loading: false
        }

        this.listPaging = this.listPaging.bind(this);
        this.togglePage = this.togglePage.bind(this);
        this.getStoreItems = this.getStoreItems.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }

    listPaging(list) {
        let ret = [];
        try {
            let start = (this.state.page * PAGE_SZ); 
            let fin = start + PAGE_SZ;

            ret = list.slice(start, fin);
        }
        catch(ex){
            console.log(`Error Paging List: ${ex}`);
        }
        return ret;
    }

    togglePage(loc){
        try {
            if((loc < 0 && this.state.page > 0) || (loc > 0 && this.state.page + 1 < this.state.max_page)){
                this.setState({ page: this.state.page + loc });
            }
        }
        catch(ex){
            console.log(`Error Toggling Page: ${ex}`);
        }
    }

    getStoreItems(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                let config_name = this.props.type.toLowerCase();
                axios.get(`${rootPath}/v2/api/purchase/content?type=${config_name}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(`[Error] Getting config data: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting retrieving store items [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            let maxPage = Math.ceil(response.data.results?.length / PAGE_SZ);
                            // Set State
                            self.setState({ 
                                page: 0, max_page: maxPage ?? 1,
                                itemList: response.data.results 
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Store Items (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Store Items: ${ex}`);
        }
    }

    formatDate(date){
        let ret = "N/A";
        try {
            if(date && !isNaN((new Date(date)).getTime())){              
                ret = format(parseISO(date),'MMM. dd, yyyy');
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    componentDidMount(){ 
        if(this.props.type in store_config){
            let self = this;
            this.setState({ title: store_config[this.props.type].title}, ()=>{
                self.getStoreItems();
            });
        }
    }

    render(){  
        return(
            <div className="store-component">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                {/* Store Header */}
                <div className='store-title'>
                    <span>{this.state.title}</span>
                    {this.props?.type === "uniform" &&
                        <span className='sub-link' onClick={()=> this.setState({ open_chart: true })}>
                            <i className="fas fa-tshirt" />
                            Size Chart
                        </span>
                    }
                </div>

                {/* Store Body */}
                <div className='store-component-body'>
                    {this.state.itemList?.length >= PAGE_SZ &&
                        <div className='list-paging-container'>
                            <div className={`paging-btn ${this.state.page === 0 ? 'disabled' : ''}`} onClick={()=> this.togglePage(-1)}>
                                <i className="fas fa-chevron-left"/>
                                <span>Previous Page</span>
                            </div>

                            <div className={`paging-btn ${(this.state.page+1) >= this.state.max_page ? 'disabled' : ''}`} onClick={()=> this.togglePage(1)}>
                                <span>Next Page</span>
                                <i className="fas fa-chevron-right"/>
                            </div>
                        </div>
                    }
                    <div className='item-list-container'>
                        {this.listPaging(this.state.itemList).map((item,i)=>
                            <div className={`store-item ${item.type}`} key={i} onClick={()=> this.props.setActiveItem(item)}>
                                <div className='item-img'><img src={item.img_list[0]}/></div>
                                <div className='item-details'>
                                    <div className='item-title'>{item.title}</div>
                                    {item.type === "league" ?
                                        <> 
                                            <div className='item-price'>
                                                <span>{currencyFormat(item.item_price)} each for first {item.min_amount} kids</span>
                                                <span>{currencyFormat(item.add_player_price)} for each additional kid</span>
                                            </div> 

                                            <div className='item-date'>
                                                <span>{this.formatDate(item.start_dt)}</span>
                                                <span>-</span>
                                                <span>{this.formatDate(item.end_dt)}</span>
                                            </div>
                                        </>:
                                        <div className='item-price'>{currencyFormat(item.item_price)}</div>
                                    }
                                </div>
                            </div>
                        )}

                        {!this.state.loading && this.state.itemList?.length <= 0 && <h1 className='empty-store'>Sorry Currently This League Store Is Empty Please Check Back Later.</h1>}
                    </div>
                </div>

                {/* Store Footer */}
                <div className='cart-container'>
                    <div className={`cart-btn ${this.props.invoiceList.length > 0 ? '' : 'disabled'}`} onClick={this.props.viewInvoice}>
                        <i className="fas fa-shopping-cart" />
                        <span>View Invoice</span>
                    </div>
                </div>

                {/* Size Chart */}
                <Modal open={this.state.open_chart} onClose={()=>this.setState({ open_chart: false })}>
                    <div className='chart-container'>
                        <i className="fas fa-times" onClick={()=>this.setState({ open_chart: false })}/>
                        <div className='img-container'><img src={size_chart} /></div>
                        <div className='suggestions'>
                            <p>Size Suggestions</p>
                            <div><span>Elementary School:</span> YXS, YS, YM, YL, YXL, S</div>
                            <div><span>Middle School:</span> YL, YXL, S, M, L</div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default Store;