import React, { Component } from 'react';
import ReactGA from 'react-ga';
import marked from 'marked';
import axios from 'axios';

/* Images */
import back from '../../assets/imgs/refs.jpg'

class Rules extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ruleList: [],
            selectedSection: null
        }

        this.markText = this.markText.bind(this);
        this.loadPageData = this.loadPageData.bind(this);
    }

    markText(text) {
        var rawMarkup = "";
        try {
            if(text) {
                rawMarkup = marked(text);
            }        
        }
        catch(ex){
            console.log("[Error] marking body Text: ",ex);
        }
        return { __html:  rawMarkup};
    }

    loadPageData(){
        var self = this;
        try {
            var postData = { url:"stories/rules" };
            axios.post(self.props.rootPath + "/api/getPageData", postData, {'Content-Type': 'application/json'})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Getting Home Page Data (E1): ", response.data.error);
                    }
                    else if(response.data.results){
                        self.setState({ ruleList: response.data.results });
                    }
                }); 
        }
        catch(ex){
            console.log("Error Loading Page Data: ", ex);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0);
        document.title = "League Rules";
        this.loadPageData();

        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/rules');
    }

    render(){  
        return(
            <div className="page-body rules">
                <section className="sub-landing">                   
                    <div className="sub-landing-text lrgTitle cw" data-text="Rules">Rules & Policies</div>
                    <div className="sub-landing-img"><img src={back} /></div>                        
                </section>
                {this.state.selectedSection === null &&
                    <section className="rulesList">
                        <h1 className="lrgTitle ctr c0" data-text="Styles">Tournament Styles</h1>
                        <div className="rule-container">
                            {this.state.ruleList.map((item, i) =>
                                <div className="rule-item" key={i} onClick={() => this.setState({ selectedSection: item })}>{item.title}</div>
                            )}
                        </div>
                    </section>
                }

                {this.state.selectedSection != null &&
                    <section className="rulesSelection">
                        <div className="back-link" onClick={() => this.setState({ selectedSection: null })}><i className="fas fa-angle-double-left" /><span>Back To Tournament Styles</span></div>
                        <h1 className="lrgTitle ctr c0" data-text="Tournament">{this.state.selectedSection.title}</h1>
                        {this.state.selectedSection.ruleList.map((item,i)=>
                            <div className="section-container" key={i}>
                                <h1>{item.sectionTitle}</h1>
                                <div className="section-text" dangerouslySetInnerHTML={this.markText(item.text)} />
                            </div>
                        )}

                        <div className="scroll-auto" onClick={()=> window.scrollTo(0, 0)}><i className="fas fa-chevron-up"/></div>
                    </section>
                }
            </div>
        );
    }
}
export default Rules;