import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { format } from "date-fns";

import { rootPath, currencyFormat } from '../../shared/utils';
import LoadSpinner from '../../shared/loadSpinner';

const PAGE_SIZE = 10;

const table_rows  = [
    {"displayName":"Submission Date", "name":"creation_date", "type":"date", "nested":false},
    {"displayName":"Org Name", "name":"org_name", "type":"input", "nested":true},
    {"displayName":"Email", "name":"email", "type":"input", "nested":true},
    {"displayName":"Invoice #", "name":"invoice_number", "type":"input", "nested":false},
    {"displayName":"Total Price", "name":"total_price", "type":"currency", "nested":false},
    {"displayName":"Status", "name":"status", "type":"status", "nested":false}
]

class AdminInvoiceTool extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            loading: false, invoice_list:[], 
            pageTotal: { "incomplete":0, "complete":0 }, 
            curPage: { "incomplete":0, "complete":0 },
            viewData: { "incomplete":true, "complete":false },
            fullData: { "incomplete":[], "complete":[] },
            displayData: { "incomplete":[], "complete":[] }
        }

        this.formatDate = this.formatDate.bind(this);
        this.cleanData = this.cleanData.bind(this);
        this.toggleViewData = this.toggleViewData.bind(this);
        this.updateDisplayData = this.updateDisplayData.bind(this);
        this.getQuotes = this.getQuotes.bind(this);
        this.updateQuote = this.updateQuote.bind(this);
        this.getInvoiceType = this.getInvoiceType.bind(this);
    }

    formatDate(date){
        let ret = "N/A";
        try {
            if(date && !isNaN((new Date(date)).getTime())){
                let d = new Date(), fdt = new Date(date);
                
                if(`${d.getDate()} ${d.getMonth()} ${d.getFullYear()}` === `${fdt.getDate()} ${fdt.getMonth()} ${fdt.getFullYear()}`) {
                    ret = format(date,'h:mm aaaa');
                }
                else if(d.getFullYear() === fdt.getFullYear()){
                    ret = format(date,'MMM. dd');
                }
                else {
                    ret = format(date,'MMM. dd, yyyy');
                }
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    cleanData(data, row) {
        try {
            let tmpData = "";
            if(row.nested){
                tmpData = data.account_info.length > 0 ? data.account_info[0][row.name] : "";           
            }
            else {
                tmpData = data[row.name];
            }

            switch(row.type){
                case "date":
                    return <>{this.formatDate(tmpData)}</>;
                case "status":
                    let status_class = tmpData.toLowerCase().split(" ").join("_");
                    return <span className={`status-txt ${status_class}`}>{tmpData}</span>
                case "currency":
                    return <>{currencyFormat(tmpData)}</>
                default:
                    return <>{tmpData}</>;
            }
        }
        catch(ex){
            console.log(`[Error] Cleaning Data: ${ex}`);
            return <></>;
        }
    }

    toggleViewData(page){
        try {
            let incomplete = this.state.viewData.incomplete,
                complete = this.state.viewData.complete;

            if(page === "complete"){ complete = !complete; }
            else { incomplete = !incomplete; }

            this.setState({ "viewData": { "incomplete":incomplete, "complete":complete }});
        }
        catch(ex){
            console.log(`Error: toggling view data: ${ex}`);
        }
    }

    updateDisplayData(dir, listName){
        try {
            if(listName in { "incomplete":true, "complete": true}){
                let newPage = this.state.curPage[listName] + dir;
                if(newPage >= 0 && newPage < this.state.pageTotal[listName]){
                    let start = newPage * PAGE_SIZE,
                        fin = (newPage * PAGE_SIZE) + PAGE_SIZE;

                    let newList = this.state.fullData[listName].slice(start, fin);
                    this.setState({ 
                        displayData: { ...this.state.displayData, [listName]:newList }, 
                        curPage: { ...this.state.curPage, [listName]:newPage } 
                    });
                }
            }
        }
        catch(ex){
            console.log(`[Error] Updating Display Data: ${ex}`);
        }
    }

    getQuotes(){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                axios.get(`${rootPath}/v2/api/purchase/quote`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(`[Error] Getting Quotes List: ${response.data.error}`);
                            toast.error("Sorry, There was an issue attempting quotes [Please Contact Site Admin]", { position: "top-right",
                                autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                                draggable: true, progress: undefined, theme: "dark" });
                        }
                        else {
                            // Set State
                            let incomplete = response.data?.results.filter((item) => { return item.status != 'COMPLETED'; }),
                                complete = response.data?.results.filter((item) => { return item.status=== 'COMPLETED'; });
                            
                            let tmpIncompleteTotalPages = Math.ceil(incomplete.length / PAGE_SIZE),
                                tmpCompleteTotalPages = Math.ceil(complete.length / PAGE_SIZE);

                            self.setState({ 
                                fullData: { "incomplete":incomplete, "complete":complete },
                                displayData: { "incomplete":incomplete.slice(0, PAGE_SIZE), "complete":complete.slice(0, PAGE_SIZE) }, 
                                pageTotal: { "incomplete": tmpIncompleteTotalPages, "complete":tmpCompleteTotalPages}, 
                                curPage: { "incomplete":0, "complete":0 }
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Getting Quotes List (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log(`[Error] Getting Quotes List: ${ex}`);
        }
    }
    
    updateQuote(id, account_id, status, purchase_order_number){
        let self = this;
        try {
            this.setState({ loading: true}, ()=>{ 
                const postData = new FormData();

                let new_status = (status != 'COMPLETED' ? 'COMPLETED' : (purchase_order_number?.length > 0 ? 'PO SUBMITTED' : 'NEW'))

                postData.append('account_id',account_id);
                postData.append('status',new_status);
                postData.append('purchase_order_number', purchase_order_number ?? 'null');

                fetch(`${rootPath}/v2/api/purchase/quote/${id}`, {
                    method: "POST", body: postData,
                })
                .then((response) => response.json())
                .then((res)=> {
                    if(res.error || !res.results){
                        console.log(`[Error] updating quote: ${res.error}`);
                        toast.error("Sorry, There was an issue updating this quote [Please Contact Site Admin]", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
                    }
                    else { 
                        self.getQuotes();
                        toast.success("Successfully updated quote", { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
                    }
                }).catch((err) =>{
                    console.log("[Error] Updating Quote (E2)",err);
                }).finally(()=> { self.setState({ loading: false }); });
            });
        }
        catch(ex){
            console.log(`Error: Submitting PO: ${ex}`);
        }
    }

    getInvoiceType(item){
        let ret = "";
        try {
            if(item?.line_items?.length > 0){
                ret = item.line_items[0].type;
            }
        }
        catch(ex){
            console.log(`[Error] Getting Invoice Type: ${ex}`);
        }
        return ret.toLowerCase();
    }

    componentDidMount(){
        this.getQuotes();
    }

    render(){  
        return(
            <div className="admin-tool-container invoices">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                <div className='store-config-container'>
                    <div className='title'>
                        <i className="fas fa-file-invoice" />
                        <span>Invoices</span>
                    </div>
                </div>

                <div className='invoice-content-container'>
                    {/* Unread */}
                    <div className="sub-table-container unread">
                        <div className="sub-table-header">
                            <div className="title" onClick={()=> { this.toggleViewData("incomplete") }}>
                                <i className={`icon fas ${this.state.viewData.incomplete ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                <span>In-Progress</span>
                            </div>

                            {(!this.state.viewData.incomplete || this.state.pageTotal.incomplete <= 0) ? <></> :
                                <div className="full-table-header">
                                    <div className="table-count">
                                        <div className="table-nav">
                                            <i className="fas fa-chevron-left icon" onClick={()=> this.updateDisplayData(-1, "incomplete")}/>
                                            <i className="fas fa-chevron-right icon" onClick={()=> this.updateDisplayData(1, "incomplete")}/>
                                        </div>
                                        <span>{(this.state.curPage.incomplete+1)} of {this.state.pageTotal.incomplete}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        {(this.state.viewData.incomplete && this.state.displayData.incomplete.length > 0) &&
                            <div className="sub-table-data-container">
                                <table className="report-table">
                                    <thead>
                                        <tr className="table-header">
                                            <th/>
                                            <th>Invoice Type</th>
                                            {table_rows.map((row,i) => 
                                                <th key={i}>{row.displayName}</th>
                                            )}
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.displayData.incomplete.map((data,h)=>
                                            <tr key={h}>
                                                <td>
                                                    <div className={`row-status ${data.status === "COMPLETED" ? 'sel' : ""}`} onClick={()=> this.updateQuote(data._id, data.account_id, data.status, data.purchase_order_number)}/>
                                                </td>
                                                <td className='league-type-cell'>
                                                    {this.getInvoiceType(data) === "uniform" ? 
                                                        <>
                                                            <i className="fas fa-tshirt"/>
                                                            <span>Uniform</span> 
                                                        </>: 
                                                        <>
                                                            <i className="fas fa-users"/>
                                                            <span>League</span> 
                                                        </>
                                                    }
                                                </td>
                                                {table_rows.map((row,i) => 
                                                    <td key={i}>{this.cleanData(data, row)}</td>
                                                )}
                                                <td>
                                                    <a href={`${rootPath}/v2/api/download_invoice/${data._id}?is_invoice=1`} target='_blank'><i className="download-icon fas fa-download" /></a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>

                    {/* Read */}
                    <div className="sub-table-container read">
                        <div className="sub-table-header">
                            <div className="title" onClick={()=> { this.toggleViewData("complete") }}>
                                <i className={`icon fas ${this.state.viewData.complete ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                <span>Completed</span>
                            </div>

                            {(!this.state.viewData.complete || this.state.pageTotal.complete <= 0) ? <></> :
                                <div className="full-table-header">
                                    <div className="table-count">
                                        <div className="table-nav">
                                            <i className="fas fa-chevron-left icon" onClick={()=> this.updateDisplayData(-1, "complete")}/>
                                            <i className="fas fa-chevron-right icon" onClick={()=> this.updateDisplayData(1, "complete")}/>
                                        </div>
                                        <span>{(this.state.curPage.complete+1)} of {this.state.pageTotal.complete}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        {(this.state.viewData.complete && this.state.displayData.complete.length > 0) &&
                            <div className="sub-table-data-container">
                                <table className="report-table">
                                    <thead>
                                        <tr className="table-header">
                                            <th/>
                                            {table_rows.map((row,i) => 
                                                <th key={i}>{row.displayName}</th>
                                            )}
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.displayData.complete.map((data,h)=>
                                            <tr key={h}>
                                                <td>
                                                    <div className={`row-status ${data.status === "COMPLETED" ? 'sel' : ""}`} onClick={()=> this.updateQuote(data._id, data.account_id, data.status, data.purchase_order_number)}/>
                                                </td>
                                                {table_rows.map((row,i) => 
                                                    <td key={i}>{this.cleanData(data, row)}</td>
                                                )}
                                                <td>
                                                    <a href={`${rootPath}/v2/api/download_invoice/${data._id}?is_invoice=1`} target='_blank'><i className="download-icon fas fa-download" /></a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default AdminInvoiceTool;