import React, { Component } from 'react';
import axios from 'axios';

import LoadSpinner from '../loadSpinner';

class SignIn extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, page: "login", 
            formData: { userId: "", basePin:"" }
        }

        this.adminLogin = this.adminLogin.bind(this);
        this.pinReset = this.pinReset.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
    }

    adminLogin(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { loginType: "base", aUser: this.state.formData };
                axios.post(self.props.rootPath + "/api/auth/login", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Logging In: ", response.data.error);
                            alert(response.data.error);
                        }
                        else if(response.data.results){
                            self.props.userHandler(response.data.results, function(){ 
                                self.props.setTabSection("draft");
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] With Login (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] With Admin Login: ",ex);
        }
    }

    pinReset(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var postData = { ...this.state.formData };
                axios.post(self.props.rootPath + "/api/auth/resetLink", postData, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(" [Error] Resetting Pin: ", response.data.error);
                            alert("Error Reseting Pin, please contact system admin is problems continue.");
                        }
                        else {
                            self.setState({ page: 'login' }, () =>{
                                alert("An email has been sent to the your email address on file, please check your inbox on steps to reset your pin.");
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] With Login (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] With Admin Login: ",ex);
        }
    }

    onElementChange(e){
        try {
            var tmpData = this.state.formData;
            var name = e.target.name;

            if(name in tmpData) {               
                tmpData[name] = e.target.value;                
                this.setState({ formData:tmpData });
            }
        }
        catch(ex){
            console.log("[Error] on element change: ",ex);
        }
    }

    componentDidMount(){ }

    render(){  
        return(
            <div className="tab-content">
                {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
                <div className="sign-in-body">
                    <div className="sign-in-panel login">
                        <h1>Login To Admin Account</h1>
                        <div className="login-element">
                            <input type="text" name="userId" placeholder="Username" value={this.state.formData.userId} onChange={this.onElementChange}/>
                            <i className="far fa-user" />
                        </div>

                        {this.state.page === "login" && 
                            <>
                                <div className="login-element">
                                    <input type="password" name="basePin" placeholder="8 Digit Pin" value={this.state.formData.basePin} onChange={this.onElementChange}/>
                                    <i className="fas fa-passport" />
                                </div>

                                <div className="login-btn" onClick={this.adminLogin}>
                                    <span>Admin Login</span>
                                    <i className="fas fa-arrow-right" />
                                </div>

                                <div className="reset-link">
                                    <div className="forgot-link" onClick={()=> this.setState({ page: "reset" })}>Reset Pin</div>
                                </div>
                            </>
                        }

                        {this.state.page === "reset" &&
                            <>
                                <div className="login-btn reset" onClick={this.pinReset}>
                                    <span>Reset Pin</span>
                                    <i className="fas fa-redo" />
                                </div>

                                <div className="reset-link">
                                    <div className="forgot-link" onClick={()=> this.setState({ page: "login" })}>
                                        <i className="fas fa-arrow-left" />
                                        <span>Return To Login</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default SignIn;