import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import ReactGA from 'react-ga';
import axios from 'axios';

import LoadSpinner from '../shared/loadSpinner';
import DraftBoard from './liveDraft/draftBoard';
import SignIn from '../shared/tabs/signIn';

const mySessKey = "leeleeDraftTourney_.", draftSessKey = "leeleeActiveDraft_.";

/* Images */
import logo from '../../assets/imgs/logo0.png';
import predraft from '../../assets/imgs/predraft-mini.jpg';
import backVid from '../../assets/imgs/football-warm.mp4';


class LiveDraftContainer extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading:false, draftList:[], selIndex:0, signin: false,
            preDraftView:null, selectedDraftId:null, userName: null
        }

        this.checkParams = this.checkParams.bind(this);
        this.signOut = this.signOut.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.refreshLogin = this.refreshLogin.bind(this);
        this.setNextDraft = this.setNextDraft.bind(this);
        this.getLiveDrafts = this.getLiveDrafts.bind(this);
        this.selectDraft = this.selectDraft.bind(this);
        this.startDraft = this.startDraft.bind(this);
    }
    
    checkParams(){
        try {            
            var search = this.props.location.search;
            const draftId = new URLSearchParams(search).get('draftId');
            
            if(draftId){
                localStorage.setItem(draftSessKey, draftId);
            }
        }
        catch(ex){
            console.log("[Error] Checking Params: ", ex);
        }
    }

    signOut(){
        try {
            this.userHandler(null, function(){ });
        }
        catch(ex){
            console.log("[Error] Signing Out: ", ex);
        }
    }

    userHandler(newToken, cb) {
        var self = this;
        try {   
            var usr = null;
            if(newToken){
                var { localUser, isExpired } = parseToken(newToken);                

                if(!isExpired) { usr = localUser.name; }
            }   

            self.setState({ userName: usr },() => {
                if(!self.state.userName){
                    localStorage.removeItem(mySessKey); cb();
                } 
                else {
                    localStorage.setItem(mySessKey, newToken); cb();
                }
            });          
        }
        catch(ex){
            console.log("Error with user Handler: ", ex);
        }
    }

    refreshLogin(callback){
        var self = this;
        try {
            var token = localStorage.getItem(mySessKey);
            if(token){
                axios.get(self.props.rootPath + "/api/auth/refreshLogin", {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){
                        console.log(" [Error] Refreshing Login: ", response.data.error);
                    }
                    else if(response.data.results){
                        self.userHandler(response.data.results, function(){ });
                    }
                    callback();
                })
                .catch(function (error) {
                    console.log("[Error] With Refreshing Login (E2)",error);
                }); 
            }
            else {
                callback();
            }            
        }
        catch(ex){
            console.log("[Error] Refreshing Login: ",ex);
        }
    }
    
    setNextDraft(dir){
        try {
            var tmpLoc = 0;
             
            if((this.state.selIndex + dir) < 0) { 
                tmpLoc = (this.state.draftList.length - 1);
            } 
            else if((this.state.selIndex + dir) >= this.state.draftList.length) { 
                tmpLoc=0;
            } 
            else {
                tmpLoc = this.state.selIndex + dir;
            }              

            this.setState({ preDraftView: this.state.draftList[tmpLoc], selIndex: tmpLoc });
        }
        catch(ex){
            console.log("[Error] Setting Next Draft: ",ex);
        }
    }

    getLiveDrafts(){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {                
                axios.get(self.props.rootPath + "/api/livedraft", {headers:{'Content-Type': 'application/json'}})
                .then(function(response) {
                    if(response.data.error){
                        console.log("[Error] Getting Live Drafts: ",response.data.error);
                    }
                    else {
                        var draftId = localStorage.getItem(draftSessKey), tmpSelId = null;
                            
                        if(draftId){
                            var isActive = response.data.results.filter(function(item){ return item._id == draftId; }).length > 0;
                            if(isActive) { tmpSelId = draftId; }
                            else { localStorage.removeItem(draftSessKey); }
                        }                            

                        self.setState({ draftList: response.data.results, selectedDraftId: tmpSelId, selIndex:0, preDraftView: (response.data.results.length > 0 ? response.data.results[0] : null) });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                });                
            });
        }
        catch(ex){
            console.log("[Error] Getting Live Draft List: ", ex);
        }
    }

    selectDraft(id) {
        try {
            this.setState({ selectedDraftId: id });
        }
        catch(ex){
            console.log("[Error] Selecting Draft: ",ex);
        }
    }
    
    startDraft(id){
        var self = this;
        try {
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(mySessKey);
                if(!token){
                    self.setState({ loading: false }, ()=>{
                        localStorage.setItem(draftSessKey, id);
                        self.selectDraft(id);
                    });
                }
                else {
                    var postData = { draftId: id, draftStatus:"active" };
                    axios.post(self.props.rootPath + "/api/livedraft/updateStatus", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log("[Error] Starting Draft: ", response.data.error);
                            if(response.data.userError) { alert(response.data.error) };
                        }
                        else {
                            localStorage.setItem(draftSessKey, id);
                            self.selectDraft(id);
                        }
                    }).then(function(){
                        self.setState({ loading: false });
                    }); 
                }
            });
        }
        catch(ex){
            console.log("[Error] Staring Draft: ",ex);
        }
    }

    componentDidMount(){ 
        document.title = "Lee Lee Live Draft";
        window.scrollTo(0, 0);
        var self = this;
        this.checkParams();
        this.refreshLogin(function(){
            self.getLiveDrafts();
        }); 
        
        // Google Analytics
        ReactGA.initialize('UA-206329520-1'); ReactGA.pageview('/livedraft');
    }

    render(){  
        return(
            <div className="livedraft-container-body">
                {this.state.loading && <LoadSpinner colorClass={"c4"}/> }
                
                {(this.state.selectedDraftId != null) ? 
                    <DraftBoard rootPath={this.props.rootPath} selectedDraftId={this.state.selectedDraftId} mySessKey={mySessKey} draftSessKey={draftSessKey} selectDraft={this.selectDraft} /> :
                    <div className="predraft-container">
                        <div className="admin-panel">
                            <div className="btn-container">
                                {(this.state.userName == null ? 
                                    <div className="admin-btn" onClick={() => this.setState({ signin: true })}><i className="fas fa-user" /></div> 
                                    : <div className="admin-text">Welcome {this.state.userName} <i className="far fa-times-circle" onClick={this.signOut}></i></div> 
                                )}
                            </div>
                        </div>

                        <Link to="/" alt="Return Home" className="logo-btn">
                            <div className="logo-container"><img src={logo} /></div>
                            <div className="btn-txt">
                                <span className="top">Lee Lee</span>
                                <span className="bottom">Draft League</span>
                            </div>
                        </Link>

                        <video className="back-vid" autoPlay muted loop>
                            <source src={backVid} type="video/mp4"/>
                        </video>

                        {(this.state.preDraftView != null && this.state.draftList.length > 1) &&
                            <div className="ctrl-container">
                                <div className="ctrl left" onClick={() => this.setNextDraft(-1)}><i className="fas fa-chevron-left"/></div>
                                <div className="ctrl right" onClick={() => this.setNextDraft(1)}><i className="fas fa-chevron-right"/></div>
                            </div>
                        }
                        {this.state.preDraftView != null &&
                            <div className="predraft-view">
                                <div className="view-info">
                                    <div className="img-container"><img src={predraft}/></div>
                                    <div className="text-container">
                                        <h1>{this.state.preDraftView.title}</h1>
                                        <div className="txt-btn-container">
                                            <span>Rounds: {this.state.preDraftView.draftRounds}</span>
                                            <div className="draft-btn" onClick={()=> this.startDraft(this.state.preDraftView._id)}><i className="fas fa-arrow-left"/> Enter Draft Room</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-container">
                                    {this.state.preDraftView.teams.map((team,i)=>
                                        <div className="team-item" key={i}>
                                            <div className="team-logo"><img src={(team.logo ? team.logo : logo)} /></div>
                                            <h3>{team.name}</h3>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                        {this.state.draftList.length == 0 && <div className="empty-list">No Current Live Drafts</div>}
                    </div>
                }

                <Modal open={this.state.signin} onClose={()=>this.setState({ signin: false })}>
                    <div className="modal-body c0">
                        <i className="close-modal fas fa-times" onClick={()=>this.setState({ signin: false })}/>
                        <SignIn rootPath={this.props.rootPath} setTabSection={()=> this.setState({ signin: false })} userHandler={this.userHandler} />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default LiveDraftContainer;

function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}