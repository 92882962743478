import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import axios from 'axios';

import LoadSpinner from '../loadSpinner';
import PlayerSearch from '../playerSearch';
import ImageUploader from '../imageUploader';

const saveStatus = { 
    0: { "title": "up to date", "icon":"fas fa-check"}, 
    1: { "title": "saving", "icon":"fas fa-sync-alt"}, 
    2: { "title": "pending changes", "icon":"fas fa-exclamation-circle"} 
};

var saverInt = null;

class DraftEditorTeams extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, expanded: null, saveStatus:0,
            toggleColorList:[], formList:[], draftName: null
        }
        
        this.togglePanel = this.togglePanel.bind(this);
        this.displayOrderBtn = this.displayOrderBtn.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
        this.toggleColorPopover = this.toggleColorPopover.bind(this);
        this.addTeam = this.addTeam.bind(this);
        this.removeTeam = this.removeTeam.bind(this);
        this.getDraftInfo = this.getDraftInfo.bind(this);
        this.getDraftTeams = this.getDraftTeams.bind(this);
        this.activateSave = this.activateSave.bind(this);
        this.saveTeamList = this.saveTeamList.bind(this);
    }

    togglePanel(id){
        this.setState({ expanded: (this.state.expanded === id ? null : id) });
    }

    displayOrderBtn(id, dir) {
        try {
            if((id == 0 && dir < 0) || (dir > 0 && (id+1 >= this.state.formList.length))){
               return false;
            }

            return true;
        }
        catch(ex){
            console.log("[Error] Changing Order: ",ex);
        }
    }

    changeOrder(id, dir){
        var self = this;
        try {
            if(!((id == 0 && dir < 0) || (dir > 0 && (id+1 >= this.state.formList.length)))){
                var tmpList = this.state.formList;
                var curTmp = { ...tmpList[id]};
                var tmpColorList = [];

                tmpList[id] = {...tmpList[(id+dir)]};
                tmpList[(id+dir)] = curTmp;
                
                tmpList.forEach(function(item){ tmpColorList.push(false); });

                this.setState({ formList: tmpList, toggleColorList: tmpColorList, expanded: (id+dir), saveStatus: 2 }, () => { self.activateSave(); });
            }
        }
        catch(ex){
            console.log("[Error] Changing Order: ",ex);
        }
    }
    
    onElementChange(e, name, loc){
        var self = this;
        try {
            var tmpList = this.state.formList;

            if(name === "captain"){
                tmpList[loc].captainInfo = (e != null ? e : null);
                tmpList[loc].captainName = (e != null ? e.name : null);
            }
            else if(name === "logo") {
                tmpList[loc].logo = e;
            }
            else if(loc < tmpList.length && name in tmpList[loc]) {                
                tmpList[loc][name] = (name === "color" ? e.rgb : e.target.value);
            }

            this.setState({ formList:tmpList, saveStatus: 2 }, () => { self.activateSave(); });
        }
        catch(ex){
            console.log("[Error] on element change: ",ex);
        }
    }

    toggleColorPopover(idx, close){
        try {
            var tmpList = this.state.toggleColorList;
            tmpList[idx] = (close ? false :!tmpList[idx]);

            this.setState({ toggleColorList:tmpList });
        }
        catch(ex){
            console.log("[Error] on toggle color poppover: ",ex);
        }
    }

    addTeam(){
        var self = this;
        try {
            var defaultTeam = {
                "captainInfo": null, "captainName": null, "logo":null,
                "name":"", "color": {r:'0',g:'0',b:'0',a:'1'}
            };

            var tmpColorList = this.state.toggleColorList;
            tmpColorList.unshift(false);

            var tmpList = this.state.formList;            
            tmpList.unshift({...defaultTeam});

            this.setState({ toggleColorList: tmpColorList, formList: tmpList}, ()=> { 
                self.saveTeamList();
            });
        }
        catch(ex){
            console.log("[Error] adding team: ",ex);
        }
    }

    removeTeam(idx){
        var self = this;
        try {
            if(confirm("Are you sure you want to remove this team?")){
                var tmpColorList = this.state.toggleColorList;
                tmpColorList.splice(idx,1);

                var tmpList = this.state.formList;            
                tmpList.splice(idx,1);

                this.setState({ toggleColorList: tmpColorList, formList: tmpList}, ()=> { 
                    self.saveTeamList();
                });
            }
        }
        catch(ex){
            console.log("[Error] Removing Team: ",ex);
        }
    }

    getDraftInfo(){
        var self = this;
        try {
            var token = localStorage.getItem(self.props.mySessKey);
            var draftId = this.props.selectedDraftId;
            axios.get(self.props.rootPath + "/api/draft/"+draftId, {headers: {'Content-Type': 'application/json', 'Authorization': token}})
            .then(function(response) {
                if(response.data.error){
                    console.log(" [Error] Getting Draft Info (E1): ", response.data.error);
                }
                else{
                    self.setState({ draftName: response.data.results.title });
                }
            }); 
        }
        catch(ex){
            console.log("[Error] Getting Draft", ex);
        }
    }

    getDraftTeams(){
        var self = this;
        try {
            var draftId = this.props.selectedDraftId;
            this.setState({ loading: true }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                axios.get(self.props.rootPath + "/api/draft/teams/"+draftId, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                .then(function(response) {
                    if(response.data.error){
                        alert("Error Getting Draft Teams");
                        console.log("[Error] Getting Draft Teams: ",response.data.error);
                    }
                    else {
                        var tmpColorList = [];
                        response.data.results.forEach(function(item){ tmpColorList.push(false); });

                        self.setState({ formList: response.data.results, toggleColorList:tmpColorList });
                    }
                }).then(function(){
                    self.setState({ loading: false });
                }); 
            });  
        }
        catch(ex){
            console.log("[Error] Getting Draft", ex);
        }
    }

    activateSave(){
        var self = this;
        try {
            if(saverInt != null) {
                clearTimeout(saverInt); saverInt = null;
            }

            saverInt = setTimeout(function(){ self.saveTeamList(); }, 2500);
        }
        catch(ex){
            console.log("[Error] Activiting Save: ",ex);
        }
    }

    saveTeamList() {
        var self = this;
        try {
            this.setState({ loading: true, saveStatus: 1 }, ()=> {
                var token = localStorage.getItem(self.props.mySessKey);
                var postData = { draftId: this.props.selectedDraftId, teams: this.state.formList };
                axios.post(self.props.rootPath + "/api/draft/teams/update", postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Saving Draft Team List (E1): ", response.data.error);
                        }
                        else if(response.data.results){
                            self.setState({ formList: response.data.results, saveStatus: 0 });
                        }
                    })
                    .catch(function (error) {
                        console.log("[Error] Saving Draft Team List (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    }); 
            });
        }
        catch(ex){
            console.log("[Error] saving team list: ",ex);
        }
    }

    componentDidMount(){ this.getDraftTeams(); this.getDraftInfo(); }

    render(){  
        return(
            <div className="tab-content">
               {this.state.loading && <LoadSpinner colorClass={"c2"}/> }
               <div className="editor-body">
                   <div className="save-status-text">
                       <i className={saveStatus[this.state.saveStatus].icon} />
                       <span>{saveStatus[this.state.saveStatus].title}</span>
                   </div>
                    <div className="title-btn-container">
                        <h1>Draft Editor Teams</h1>

                        <div className="btn-container">
                            <div className="link-btn cgadd" onClick={this.addTeam}><i className="fas fa-users" />Add Team</div>
                        </div>
                    </div>

                    <div className="team-panels">
                        {this.state.draftName != null && <div className="draft-name">{this.state.draftName}</div> }
                        <p>Build the draft teams for this draft including team name, logo, color, & captain.</p>
                    </div>

                    <div className="team-panels">
                        {this.state.formList.map((team,i)=>
                            <Accordion key={i} expanded={this.state.expanded === i} onChange={()=> this.togglePanel(i)}>
                                <AccordionSummary expandIcon={<i className="fas fa-chevron-down"/>} aria-controls={"panel" +i +"-content"} id={"panel" + i + "-header"}>
                                    <div className="team-title">
                                        <div className="title-color" style={{ background: "rgba("+team.color.r+","+team.color.g+","+team.color.b+",1)" }} />
                                        <span>{team.name}</span>                                        
                                    </div>                                 
                                </AccordionSummary>
                                <AccordionDetails>                                    
                                    <div className="team-editor">
                                        <div className="editor-panel img">
                                            {team.logo === null ? <span>No Logo Selected</span>
                                                : <div className="img-logo-container"><img src={team.logo} /></div>
                                            }
                                            <ImageUploader rootPath={this.props.rootPath} mySessKey={this.props.mySessKey} selectAction={(img)=> this.onElementChange(img, "logo", i)}/>
                                        </div>
                                        <div className="editor-panel info">
                                            <div className="editor-form">
                                                <div className="form-element sz-5 cb">
                                                    <div className="element-title">Team Name</div>
                                                    <input type="text" name="name" value={team.name} onChange={(e) => this.onElementChange(e, "name", i)}/>
                                                </div>

                                                <div className="form-element sz-3 cb">
                                                    <div className="element-title">Team Color</div>
                                                    <div className="color-input">
                                                        <div className="color-toggle" onClick={() => this.toggleColorPopover(i, false) } style={{ background: "rgba("+team.color.r+","+team.color.g+","+team.color.b+",1)" }} />
                                                            { this.state.toggleColorList[i] ? 
                                                                <div className="color-popover">
                                                                <div className="color-cover" onClick={() => this.toggleColorPopover(i, true)}/>
                                                                <SketchPicker  color={team.color} onChange={(color) => this.onElementChange(color, "color", i)} />
                                                            </div> : null }
                                                    </div>
                                                </div>
                                                <div className="form-element sz-2 cb">
                                                    <div className="element-title">Remove Team</div>
                                                    
                                                    <div className="order-btn-container">
                                                        <div className="order-btn single-btn" onClick={()=> this.removeTeam(i)}><i className="far fa-trash-alt" /></div>
                                                    </div>
                                                </div>


                                                <div className="form-element sz-8 cb">
                                                    <div className="element-title">Team Captain</div>
                                                    <div className="ind-player-section">
                                                        <PlayerSearch rootPath={this.props.rootPath} mySessKey={this.props.mySessKey} selectAction={(player)=> this.onElementChange(player, "captain", i)}/>
                                                        <div className="player-view">
                                                            {team.captainInfo != null && <span>{team.captainName}</span>}
                                                            {team.captainInfo != null && <div onClick={()=> this.onElementChange(null, "captain", i)}><i className="fas fa-times" /></div>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-element sz-2 cb">
                                                    <div className="element-title">Draft Order</div>
                                                    <div className="order-btn-container">
                                                        <div className={"order-btn" + (this.displayOrderBtn(i,-1) ? "" : " no-display")} onClick={()=> this.changeOrder(i, -1)}><i className="fas fa-sort-amount-up-alt" /></div>
                                                        <div className={"order-btn" + (this.displayOrderBtn(i,1) ? "" : " no-display")} onClick={()=> this.changeOrder(i, 1)}><i className="fas fa-sort-amount-down-alt" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </div>
               </div>
               <div className="editor-controls">
                    <div className="btn-container">
                        {this.props.selectedDraftId.length >= 1 && <div className="back-link next" onClick={()=> this.props.setTabSection("draftplayers")}><span>Edit Draft Players</span><i className="fas fa-angle-double-right" /></div> }
                    </div>
                </div>
            </div>
        );
    }
}
export default DraftEditorTeams;