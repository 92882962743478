import React, { Component } from 'react';
import { currencyFormat } from '../../shared/utils';
import Resizer from "react-image-file-resizer";
import { format, parseISO } from "date-fns";
import Modal from '@material-ui/core/Modal';
import { toast } from 'react-toastify';

import size_chart from '../../../assets/imgs/size_chart.png';

const store_config = {
    "league":{
        "title":"League", detail_items: [], default_detail_sz: 8
    },
    "uniform":{
        "title":"Uniform", detail_items: [], default_detail_sz: 8
    }
}

class StoreItem extends Component{
    constructor(props) { 
        super(props);
        this.state = { 
            title:null, detail_items:[], personalized_items:[], open_chart: false,
            jersey_info: { jersey_description: "", jersey_img:"", team_name: "" }
        }

        this.photoRef = React.createRef();

        this.addPersonalizeItem = this.addPersonalizeItem.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.setImageFile = this.setImageFile.bind(this);
        this.deleteIdx = this.deleteIdx.bind(this);
        this.isMoreThanMin = this.isMoreThanMin.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.addToInvoice = this.addToInvoice.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }

    addPersonalizeItem() {
        try {
            let tmp_item = {};
            for(let i =0; i < this.state.detail_items.length; i++) {
                tmp_item[this.state.detail_items[i].title] = this.state.detail_items[i].default;
            }
            let tmp_list = [...this.state.personalized_items, tmp_item];

            this.setState({ personalized_items: tmp_list });
        }
        catch(ex){
            console.log(`Error adding personalized item: ${ex}`);
        }
    }

    handleFormChange(e, idx){
        try {
            let tmpList = [...this.state.personalized_items ], name = e.target.name;

            if(idx <= tmpList.length && name in tmpList[idx]) {
                tmpList[idx][name] = e.target.value;
                
                this.setState({ personalized_items: tmpList });
            }
        }
        catch(ex){
            console.log(`Error with form change: ${ex}`);
        }
    }

    setImageFile(e){
        let self = this;
        try {
            if (e.target.files[0]) {
                Resizer.imageFileResizer(e.target.files[0],
                    400, 400, "JPEG", 50, 0,
                    (uri) => { 
                        self.setState({  jersey_info: { ...this.state.jersey_info, jersey_img: uri } });
                        // Clear Target Value
                        e.target.value = null;
                    },
                    "base64", 200, 200
                );
            }
        }
        catch(ex){
            console.log(`Error setting image file: ${ex}`);
        }
    }

    deleteIdx(idx){
        try {
            if(idx > 0){
                let tmpList = [...this.state.personalized_items ];
                tmpList.splice(idx,1);

                this.setState({ personalized_items: tmpList });
            }
        }
        catch(ex){
            console.log(`Error Deleting Idx: ${ex}`);
        }
    }

    isMoreThanMin(val1, val2){
        let ret = false;
        try {
            ret = (!isNaN(val1) && !isNaN(val2)) && Number(val1) >= Number(val2);
        }
        catch(ex){
            console.log(`[Error] More Than Min Check: ${ex}`);
        }

        return ret;
    }

    validateForm(alert = false){
        let ret = true;
        try {
            for(let i=0; i < this.state.personalized_items.length; i++){
                for(let j=0; j < this.state.detail_items.length; j++){
                    let detail = this.state.detail_items[j];
                    let item = this.state.personalized_items[i][detail.title];
                                        
                    if(detail.required && item.length < 1){
                        ret = false; break;
                    }
                    else if(detail.required && detail.type === "number" && alert && !this.isMoreThanMin(item, detail?.min_amount)){
                        toast.warning(`Please make sure total meets mininum number (${detail.min_amount})`, { position: "top-right",
                            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false,
                            draggable: true, progress: undefined, theme: "dark" });
                        ret = false; break;
                    }
                }
            }
        }
        catch(ex){
            console.log(`Error Validating Form: ${ex}`);
        }
        return ret;
    }

    addToInvoice(){
        try {
            if(this.validateForm(true)){
                this.props.addToInvoice(this.state.personalized_items, this.state.detail_items, this.state.jersey_info);
            }
        }
        catch(ex){
            console.log(`Error Adding To Invoice: ${ex}`);
        }
    }

    formatDate(date){
        let ret = "N/A";
        try {
            if(date && !isNaN((new Date(date)).getTime())){                
                ret = format(parseISO(date),'MMM. dd, yyyy');
            }
        }
        catch(ex){
            console.log(`[Error] formatting data: ${ex}`);
        }

        return ret;
    }

    componentDidMount(){ 
        if(this.props.type in store_config){
            let self = this;
            let tmp_detail_items = [
                ...store_config[this.props.type].detail_items,
                {"title":"qty", "type":"number", "default":this.props.item?.min_amount ?? 1, "required": true, sz: 2, "min_amount": this.props.item?.min_amount ?? 1 },
                {
                    "title":"details", "type":"textarea", "default":"", 
                    "placeholder":(this.props?.type === "uniform"? "Please enter in jersey numbers & sizes for all items":""), 
                    "required": (this.props?.type === "uniform"), sz: 10 
                }
            ];

            if(this.props.item?.list_title && this.props?.type != "uniform") {
                tmp_detail_items.unshift({
                    "title":this.props.item?.list_title, "type":"select", 
                    "default":"", "required": false, "options":this.props.item?.option_list, sz: store_config[this.props.type].default_detail_sz
                });
            }

            this.setState({ 
                title: store_config[this.props.type].title,
                detail_items: tmp_detail_items,
                personalized_items: this.props.item?.item_details ? this.props.item?.item_details : [],
                jersey_info:  this.props.item?.jersey_info ? this.props.item.jersey_info : { jersey_description: "", jersey_img:"", team_name: ""}
            }, ()=> { 
                if(self.state.personalized_items.length == 0){
                    self.addPersonalizeItem(); 
                }
            });
        }
    }

    render(){  
        return(
            <div className="store-item-component">
                {/* Store Header */}
                <div className='store-title'>
                    <div className='title'>
                        <i className="fas fa-chevron-left" onClick={()=> this.props.setActiveItem(null)}/>
                        <span>{this.state.title}s</span>
                    </div>

                    {this.props?.type === "uniform" &&
                        <span className='sub-link' onClick={()=> this.setState({ open_chart: true })}>
                            <i className="fas fa-tshirt" />
                            Size Chart
                        </span>
                    }
                </div>

                <div className='store-item-body'>
                    <div className='img-container'>
                        {this.props?.item?.img_list.length > 0 &&
                            <img src={this.props?.item?.img_list[0]} alt={`${this.props?.item?.title} preview images`}/>
                        }
                        {/* TODO: Multiple img selection tool*/}
                    </div>

                    <div className='item-details'>
                        <h1>{this.props.item.title}</h1>
                        {this.props.type === "league" ?
                            <>
                                <div className='item-date'>
                                    <span>{this.formatDate(this.props?.item.start_dt)}</span>
                                    <span>-</span>
                                    <span>{this.formatDate(this.props?.item.end_dt)}</span>
                                </div>

                                <div className='price'>
                                    <div className='price-line'>
                                        {currencyFormat(this.props.item.item_price)} x {this.props.item.min_amount} kids
                                    </div>
                                    <div className='price-line'>
                                        {currencyFormat(this.props.item.add_player_price)} <span>for each additional kid</span>
                                    </div>
                                </div>
                            </> :
                            <><div className='price'>{currencyFormat(this.props.item.item_price)} <span>each</span></div></>
                        }
                        <div>{this.props.item.description}</div>

                        {this.props.item?.can_design != true ? <></> :
                            <div className='custom-section personalize'>
                                <div className='section-title'>Personalize Uniforms</div>

                                <div className='jersey-details-container'>
                                    <div className='item-img-container'>
                                        {this.state?.jersey_info?.jersey_img?.length > 0 ? <img src={this.state?.jersey_info?.jersey_img} /> : <></>}
                                        <div className='edit-tool' onClick={()=> this.photoRef.current.click() }><i className="far fa-edit" /></div>
                                        <input className='hiddenInput' type="file" accept="image/*" name="image" ref={this.photoRef} onChange={this.setImageFile} />
                                    </div>

                                    <div className='form-container'>
                                        <div className='form-item sz-1'>
                                            <span className='title'>Team Name</span>
                                            <input type="text" name="team_name" value={this.state.jersey_info.team_name} onChange={(e) => this.setState({ jersey_info: { ...this.state.jersey_info, team_name: e.target.value } })}/>
                                        </div> 

                                        <div className='form-item sz-2'>
                                            <span className='title'>Design Notes</span>
                                            <textarea name="jersey_description" placeholder='List 1st, 2nd, & 3rd color choices' value={this.state.jersey_info.jersey_description} onChange={(e) => this.setState({  jersey_info: { ...this.state.jersey_info, jersey_description: e.target.value } })}/>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='custom-section'>
                            <div className='section-title'>{this.state.title} Order Details</div>
                            <div className='personalize-list-container'>
                                {this.state.personalized_items.map((item,i) =>
                                    <div className='personalize-item' key={i}>
                                        {this.state.detail_items.map((detail,k) =>
                                            <div className={`form-item sz-${detail.sz}`} key={k}>
                                                <span className='title'>{detail.title}</span>
                                                {detail.type === "select" && 
                                                    <select name={detail.title} value={item[detail.title]} onChange={(e) => this.handleFormChange(e,i)}>
                                                        <option hidden>Select {detail.title}</option>
                                                        {detail?.options?.map((op, l) =>
                                                            <option value={op} key={l}>{op}</option>
                                                        )}
                                                    </select>
                                                }
                                                {(detail.type === "input" || detail.type === "number") && <input type={detail.type} name={detail.title} value={item[detail.title]} min={0} onChange={(e) => this.handleFormChange(e,i)}/>}
                                                {detail.type === "textarea" && <textarea type={detail.type} name={detail.title} value={item[detail.title]} placeholder={detail?.placeholder ?? ""} onChange={(e) => this.handleFormChange(e,i)}/>}
                                            </div> 
                                        )}
                                        <div className='remove-item' >
                                            {i > 0 && <i className="fas fa-backspace" onClick={()=> this.deleteIdx(i)} /> }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='detail-btn-container'>
                            <div className={`detail-btn ${(!this.validateForm() ? 'disabled' : '')}`} onClick={this.addToInvoice}>
                                <i className="fas fa-cart-plus" />
                                <span>Add To Invoice</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Size Chart */}
                <Modal open={this.state.open_chart} onClose={()=>this.setState({ open_chart: false })}>
                    <div className='chart-container'>
                        <i className="fas fa-times" onClick={()=>this.setState({ open_chart: false })} />
                        <div className='img-container'><img src={size_chart} /></div>
                        <div className='suggestions'>
                            <p>Size Suggestions</p>
                            <div><span>Elementary School:</span> YXS, YS, YM, YL, YXL, S</div>
                            <div><span>Middle School:</span> YL, YXL, S, M, L</div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default StoreItem;